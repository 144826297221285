import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { Text, withTheme, TouchableRipple } from 'react-native-paper'
import { connect } from 'react-redux'
import Container from '../components/Container'
import { getTranslationValue } from '../helpers/language'

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  innerContainer: {
    flexDirection: 'row',
  },
  tab: {
    padding: 12,
    paddingLeft: 24,
    paddingRight: 24,
    minHeight: 46,

    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  tabLabel: {
    fontSize: 14,
    color: 'rgba(0,0,0,0.6)',
  },
  currentTab: {
    backgroundColor: '#f6f4f9',
    borderTopStartRadius: 5,
    borderTopEndRadius: 5,
  },
  currentTabLabel: {
    fontWeight: '600',
    textDecorationLine: 'underline',
    color: '#000',
  },
  indicator: {
    width: 5,
    height: 5,
    borderRadius: 3,
    opacity: 0.3,
    marginTop: 4,
  },
  activeIndicator: {
    backgroundColor: '#000',
  },
})

const supportedLanguages = process.env.REACT_APP_SUPPORTED_LANGUAGES
const languages = supportedLanguages ? supportedLanguages.split(',') : []

class LanguageSwitcher extends Component {
  render() {
    const { value, theme, languagesWithIndicator, onChange } = this.props
    if (languages.length === 0) {
      return null
    }
    return (
      <View style={styles.root}>
        <Container>
          <View style={styles.innerContainer}>
            {languages.map((lang) => {
              const selected = lang === value
              const hasIndicator = languagesWithIndicator.some(
                (l) => l === lang
              )
              //   console.log('languagesWithIndicator', languagesWithIndicator)
              return (
                <TouchableRipple onPress={onChange(lang)} key={lang} testID={'switch'+lang}>
                  <View style={[styles.tab, selected && styles.currentTab]}>
                    <Text
                      style={[
                        styles.tabLabel,
                        selected && styles.currentTabLabel,
                        selected && {
                          textDecorationLineColor: theme.colors.primary,
                        },
                        { cursor: 'pointer' },
                      ]}
                    >
                      {lang.toUpperCase()}
                    </Text>

                    <View
                      style={[
                        styles.indicator,
                        hasIndicator && styles.activeIndicator,
                      ]}
                    />
                  </View>
                </TouchableRipple>
              )
            })}
          </View>
        </Container>
      </View>
    )
  }
}

const showIndicator = (mail, language) => {
  const rootKeysToCheck = ['subject']
  const showIndicator = rootKeysToCheck.some(
    (key) => !getTranslationValue(mail, key, language)
  )

  if (showIndicator) {
    return true
  } else {
    return false
  }

  // // also check widgets
  // // TODO title->??? 'title',
  // const widgetKeysToCheck = ['title', 'content']
  //
  // // check if widgets has missing fields
  // const showWidgetIndicator = (mail.widgets || [])
  //   .filter((w) => w.type === 'text')
  //   .some((w) =>
  //     widgetKeysToCheck.some((key) => !getTranslationValue(w, key, language)),
  //   )
  // //   if (showWidgetIndicator) {
  // //     console.log('WIDGET KEY TRUE', language)
  // //   }
  //
  // return showWidgetIndicator
}

const mapStateToProps = (state) => ({
  languagesWithIndicator: languages
    .map((l) => (showIndicator(state.mail.crud.object || {}, l) ? l : null))
    .filter((n) => n),
})
export default connect(mapStateToProps)(withTheme(LanguageSwitcher))
