import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'

class PinkView extends Component {
  render() {
    const { showFish } = this.props
    return (
      <View style={styles.pinkBackgroundContainer}>
        {showFish &&
        <img
          src={require('../../img/login_background.png')}
          style={{
            objectFit: 'contain',

            width: '70%',
            maxWidth: 600,
            marginTop: -150,
            objectPosition: '0 0',
          }}
          alt="fish in hand"
        />}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  pinkBackgroundContainer: {
    backgroundColor: '#EED5D8',
    width: '100%',
    flex: '1 1 auto',

    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 200,
    paddingBottom: 50,
    position: 'relative',
  },
})

export default PinkView
