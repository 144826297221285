import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchListener } from 'react-auth/lib/sagas/authentication'
import * as a from './actions'

function* sendMail(payload) {
  try {
    let response = yield call(fetchListener, {
      path: `v1/newsletter/${payload.payload.id}/send`,
      method: 'POST',
      body: {
        organizationIds: payload.payload.organizationIds,
        userIds: payload.payload.userIds,
      },
    })
    yield put(a.sendedMail(response))
  } catch (e) {
    console.log(e)
    yield put(a.sendedMail(null, null, true))
  }
}

export default function* directSend() {
  yield takeLatest(a.SEND_MAIL, sendMail)
}
