import React, { Component } from 'react'
import { Alert, Platform, View } from 'react-native'
import { connect } from 'react-redux'
import Navigation from '../Navigation'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import KeyboardAvoidingScrollView from '../components/KeyboardAvoidingScrollView'
import { set } from 'lodash'

export default (
  WrappedComponent,
  mapStateToProps,
  actions,
  config = {
    enableDelete: true,
    optinEdit: false,
    reloadOnMount: true,
    cacheFromList: true,
  }
) => {
  class HOC extends Component {
    state = {
      optinEdit: !!config.optinEdit,
      editing: !config.optinEdit,
    }
    componentWillMount() {
      const { id } = this.props
      const object = this.props.crud.object || {}

      let reload = config.reloadOnMount

      // Only reload when going on another object or first time
      if (!config.reloadOnMount) {
        reload = false
        const keys = Object.keys(object)
        // new object
        if (!id) {
          // if there are no keys in this object
          // or there is an object with an ID in the store
          if (keys.length === 0 || object.id) {
            reload = true
          }
        } else {
          // existing object
          // only reload when id is something else
          reload = Number(id) !== object.id
        }
      }

      if (reload) {
        this.props.load(
          { id: Number(id) },
          { cacheFromList: config.cacheFromList }
        )
      }
    }
    componentDidUpdate(prevProps) {
      const {
        componentId,
        popToRootOnRemove,
        popToRootOnCreate,
        popToRootOnUpdate,
      } = this.props

      const {
        creating,
        creatingError,
        removing,
        removingError,
        updating,
        updatingError,
      } = this.props.crud

      if (
        popToRootOnUpdate &&
        prevProps.crud.updating &&
        !updating &&
        !updatingError
      ) {
        Navigation.popToRoot(componentId)
      }
      if (
        popToRootOnCreate &&
        prevProps.crud.creating &&
        !creating &&
        !creatingError
      ) {
        Navigation.popToRoot(componentId)
      }
      if (
        popToRootOnRemove &&
        prevProps.crud.removing &&
        !removing &&
        !removingError
      ) {
        Navigation.popToRoot(componentId)
      }
    }

    _change = (name, value) => {
      if (name.includes('.')) {
        let object = this.props.crud.object || {}
        set(object, name, value)
        this.props.setData(object)
      } else {
        this.props.setData({ [name]: value })
      }
    }
    _set = (v) => {
      this.props.setData(v)
    }
    _edit = () => {
      this.setState((prevState) => ({
        editing: !prevState.editing,
      }))
    }
    _submit = ({ payload, meta }) => {
      const { id } = this.props
      if (id) {
        this.props.update(payload, meta)
      } else {
        this.props.create(payload, meta)
      }

      if (config.optinEdit) {
        this.setState({
          editing: false,
        })
      }
    }
    _removeConfirmed = () => {
      const { id } = this.props
      this.props.remove({ id: Number(id) })
    }
    _remove = () => {
      const alertTitle = 'Bevestiging'
      const alertText = 'Weet u zeker dat u dit object wilt verwijderen?'

      if (Platform.OS === 'web') {
        //eslint-disable-next-line
        if (confirm(alertText)) {
          this._removeConfirmed()
        }
      } else {
        Alert.alert(
          alertTitle,
          alertText,
          [
            {
              text: 'Annuleer',
              style: 'cancel',
            },
            {
              text: 'OK',
              onPress: () => {
                this._removeConfirmed()
              },
            },
          ],
          { cancelable: true }
        )
      }
    }
    render() {
      const { safePadding, disableSafe } = this.props
      return (
        <View style={{ flex: 1 }}>
          <KeyboardAvoidingScrollView
            contentContainerStyle={
              disableSafe
                ? { flexGrow: 1 }
                : [safePadding, { flexGrow: 1, paddingTop: 0 }]
            }
          >
            <WrappedComponent
              {...this.props}
              {...this.state}
              onSet={this._set}
              onChange={this._change}
              onSubmit={this._submit}
              onRemove={this._remove}
            />
          </KeyboardAvoidingScrollView>
        </View>
      )
    }
  }
  HOC.defaultProps = {
    popToRootOnRemove: true,
    popToRootOnCreate: true,
    popToRootOnUpdate: true,
  }
  return safeAreaHOC(connect(mapStateToProps, actions)(HOC))
}
