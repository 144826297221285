import React, { useEffect, useRef, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { ActivityIndicator, Title, FAB } from 'react-native-paper'
import actions from './actions'
// @ts-ignore
import { push } from '../Navigation'

import KeyboardAvoidingScrollView from '../components/KeyboardAvoidingScrollView'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import detailHOC from '../GeneralListFunctions/detailHOC'
import { MAILING_DETAIL_TWO_ROUTE } from '../Routes.config'
import MailHeader from './MailHeader'
import DatePicker from '../components/DatePicker'
import { useSelector } from 'react-redux'
import paramsAsPropsHOC from '../GeneralListFunctions/paramsAsPropsHOC'
import Container from '../components/Container'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'

const styles = StyleSheet.create({
  nextFab: {
    position: 'absolute',
  },
})
const fields = [
  {
    key: 'text',
    name: 'internalName',
    type: 'text',
    label: 'Interne naam',
    fieldProps: {
      mode: 'flat',
      style: { marginTop: 5, marginBottom: 16 },
      testID: 'internalName',
    },
  },
  {
    key: 'design',
    name: 'design',
    type: 'mailDesign',
    label: 'Design',
    fieldProps: { description: 'Selecteer een design', testID: 'designButton' },
  },
  {
    key: 'campaign',
    name: 'campaign',
    type: 'mailCampaign',
    label: 'Campagne',
    fieldProps: {
      description: 'Selecteer een campagne',
      testID: 'campaignButton',
    },
  },
  {
    key: 'organizationGroups',
    name: 'organizationGroups',
    type: 'organizationGroups',
    label: 'Ontvangers',
    fieldProps: { testID: 'organizationGroupButton' },
  },
  {
    key: 'active',
    name: 'active',
    type: 'checkbox',
    label: 'Mailing is actief',
    fieldProps: { testID: 'activeCheckbox' },
  },
  {
    key: 'repeatmail',
    name: 'repeatMail',
    type: 'repeatMail',
    label: 'Herhaal mailing',
    fieldProps: { testID: 'repeatCheckbox' },
  },
]

const secondFields = [
  {
    key: 'label',
    name: 'nextDeliveryDate',
    type: 'label',
    label: 'Eerstvolgend ingepland verzendmoment',
    fieldProps: {
      style: { marginTop: 15, marginLeft: 4, marginBottom: 16 },
      testID: 'nextDeliveryDate',
    },
  },
]

type StepMailingPropsType = {
  id: string
  componentId: number
  onChange: (key: string, value: any) => any
  onRemove: () => any
  onSubmit: (item: any) => any
  safe: { bottom: number; right: number }
}
type Error = {
  name: string
  message: string
}[]

function StepMailing({
  componentId,
  id,
  onChange,
  onRemove,
  onSubmit,
  safe,
}: StepMailingPropsType) {
  const crud = useSelector((state: any) => state.mail.crud)
  const [errors, setErrors] = useState<Error>([])
  const [date, setDate] = useState<Date>()
  const dateRef = useRef<any>()

  useEffect(() => {
    if (
      crud.object &&
      crud.object.repeatMail &&
      crud.object.repeatMail.time > 0 &&
      crud.object.repeatMail.active
    ) {
      const testDate = new Date(crud.object.repeatMail.time * 1000)
      const fullDate = new Date(
        testDate.getUTCFullYear(),
        testDate.getUTCMonth(),
        testDate.getUTCDate(),
        testDate.getUTCHours(),
        testDate.getUTCMinutes(),
        0
      )
      setDate(fullDate)
      dateRef.current = new Date(fullDate)
    } else if (crud.object && crud.object.deliveryDate > 0) {
      const testDate = new Date(crud.object.deliveryDate * 1000)
      const fullDate = new Date(
        testDate.getUTCFullYear(),
        testDate.getUTCMonth(),
        testDate.getUTCDate(),
        testDate.getUTCHours(),
        testDate.getUTCMinutes(),
        0
      )
      setDate(fullDate)
      dateRef.current = new Date(fullDate)
    } else {
      setDate(new Date())
      dateRef.current = new Date()
    }
  }, [crud])

  const next = () => {
    let dontNext = false
    let allErrors: Error = []
    if (crud.object && crud.object.active) {
      if (
        !crud.object.organizationGroups ||
        (crud.object.organizationGroups &&
          crud.object.organizationGroups.length === 0)
      ) {
        allErrors = [
          ...allErrors,
          {
            name: 'organizationGroups',
            message:
              'Als de mailing actief is moeten er ontvangers geselecteerd worden',
          },
        ]
        dontNext = true
      }
      if (!crud.object.design) {
        allErrors = [
          ...allErrors,
          {
            name: 'design',
            message:
              'Als de mailing actief is moet er een design geselecteerd worden',
          },
        ]
        dontNext = true
      }
    }
    if (!crud.object || (crud.object && !crud.object.internalName)) {
      allErrors = [
        ...allErrors,
        { name: 'internalName', message: 'Interne naam is verplicht' },
      ]
      dontNext = true
    }
    if (allErrors.length > 0) {
      setErrors(allErrors)
    }
    if (!dontNext) {
      push(componentId, {
        component: {
          name: MAILING_DETAIL_TWO_ROUTE,
          passProps: {
            id,
          },
        },
      })
    }
  }

  const changeDate = (key: string) => (value: any) => {
    const newDate = dateRef.current ? dateRef.current : new Date()
    if (value.hours != null && value.minutes != null) {
      newDate.setHours(value.hours)
      newDate.setMinutes(value.minutes)
    }
    if (value.date) {
      const resultDate = value.date
      newDate.setDate(resultDate.getDate())
      newDate.setFullYear(resultDate.getFullYear())
      newDate.setMonth(resultDate.getMonth())
    }
    setDate(newDate)
    setErrors([])

    const now_utc = Date.UTC(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newDate.getHours(),
      newDate.getMinutes(),
      0
    )
    onChange(key, now_utc / 1000)
  }

  const removeItem = () => {
    onRemove()
  }
  const submit = (item: any) => {
    console.log(item)
    onSubmit(item)
  }
  const { loading, loaded } = crud

  const mail = crud.object || {}

  return [
    <MailHeader
      key="header"
      // @ts-ignore
      componentId={componentId}
      active={'mailing'}
      onRemove={removeItem}
      onSubmit={submit}
    />,
    loading ? (
      <Container>
        <ActivityIndicator size={'small'} />
      </Container>
    ) : (
      <KeyboardAvoidingScrollView style={{ padding: 5 }} key="scroll">
        <Container>
          <FormContainer key="form-container" onSubmit={onSubmit}>
            {/* @ts-ignore*/}
            <Fields
              key={'Form fields'}
              fields={fields}
              values={mail}
              onChange={onChange}
              onSubmit={onSubmit}
              errors={errors}
            />

            <Title style={{ marginTop: 24, marginBottom: 12, marginLeft: 6 }}>
              Verzendtijd
            </Title>
            {mail.repeatMail && mail.repeatMail.active ? (
              <View
                style={[
                  {
                    flexDirection: 'column',
                    maxWidth: 250,
                    marginLeft: 0,
                    margin: 6,
                    position: 'relative',
                    zIndex: 2,
                    justifyContent: 'flex-start',
                  },
                ]}
              >
                {loaded && (
                  <DatePicker
                    mode={'time'}
                    onChange={changeDate('repeatMail.time')}
                    date={date}
                  />
                )}
              </View>
            ) : (
              <View>
                <View>
                  {loaded && (
                    <>
                      <DatePicker
                        mode={'date'}
                        onChange={changeDate('deliveryDate')}
                        date={date}
                      />
                      <DatePicker
                        mode={'time'}
                        onChange={changeDate('deliveryDate')}
                        date={date}
                      />
                    </>
                  )}
                </View>
              </View>
            )}
            {/* @ts-ignore*/}
            <Fields
              key={'Second form fields'}
              fields={secondFields}
              values={mail}
              onChange={onChange}
              onSubmit={onSubmit}
              errors={errors}
            />
          </FormContainer>
        </Container>
      </KeyboardAvoidingScrollView>
    ),
    <FAB
      key="next"
      icon={'chevron-right'}
      label={'Volgende'}
      testID={'nextButton'}
      onPress={next}
      style={[
        styles.nextFab,
        { bottom: 16 + safe.bottom, right: 16 + safe.right },
      ]}
    />,
  ]
}
const mapStateToProps = (state: any) => ({
  crud: state.mail.crud,
})
export default safeAreaHOC(
  paramsAsPropsHOC(
    detailHOC(StepMailing, mapStateToProps, actions, {
      optinEdit: false,
      enableDelete: true,
      reloadOnMount: false,
      cacheFromList: false,
    })
  )
)
