import * as a from './actions'

const initialState = {
  messages: [],
}

const reducer = (state = initialState, { type, error, payload }) => {
  switch (type) {
    case a.SET_MESSAGES: {
      return {
        ...state,
        messages: payload || [],
      }
    }
    default:
      return state
  }
}

export default reducer
