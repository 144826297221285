import React from 'react';
import PT from 'prop-types'
import Settings from '../../components/Settings'
import { useDispatch } from 'react-redux';
import * as a from './actions'

const inputTypeMap = {
  'boolean': 'checkbox',
  'integer': 'number',
  'decimal': 'number',
  'string': 'text',
}

const determineType = (type, _value) => inputTypeMap[type]

function Attributes({ attributes, onChange, onSubmit }) {
  const dispatch = useDispatch();

  const onAddSumbit = (payload) => {
    dispatch(a.addAttribute(payload));
  }

  const onDelete = (index) => {
    dispatch(a.removeAttribute(index))
  }

  return <Settings
    settings={attributes}
    onChange={onChange}
    onSubmit={onSubmit}
    determineType={determineType}
    onAddSubmit={onAddSumbit}
    onDelete={onDelete}
    closeOnSubmit={true}
  />
}

Attributes.propTypes = {
  attributes: PT.array,
  onChange: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
}

Attributes.defaultProps = {
  attributes: []
}

export default Attributes
