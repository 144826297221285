import React, { Component } from 'react'
import { Platform } from 'react-native'
import PT from 'prop-types'
import Field, { isTextInput } from './Field'

class Fields extends Component {
  refs = {}
  _ref = (name) => (input) => {
    this.refs = {
      ...this.refs,
      [name]: input,
    }
  }
  _focus = (name) => () => {
    if (this.refs[name]) {
      this.refs[name].focus()
    }
  }
  _blur = (name) => () => {
    if (this.refs[name]) {
      this.refs[name].blur()
    }
  }

  render() {
    const { fields, values, onChange, errors = [] } = this.props

    return fields.map((field, i) => {
      let enhancedFieldProps = { ...field.fieldProps } || {}

      field.error =
        errors.find((error) => field.name === error.name) || undefined

      const isLastAndTextInput =
        fields.length - 1 === i && isTextInput(field.type)

      // Remember to set blurOnSubmit to false, to prevent keyboard flickering.
      // blurOnSubmit={false}
      enhancedFieldProps.blurOnSubmit = false

      // Worth to mention, that onSubmitEditing callback is called after blur event.
      // So the keyboard may go crazy if focused on next element immediately.
      // So it might be helpful to set blurOnSubmit={false} to all elements in form but
      // leave at true on last element, to allow Done button to blur the last input.
      if (isLastAndTextInput) {
        enhancedFieldProps.blurOnSubmit = false //?????
        enhancedFieldProps.onSubmitEditing = this.props.onSubmit
        enhancedFieldProps.returnKeyType = 'send'
      }

      if (Platform.OS !== 'web') {
        // Adding a Ref to second TextInput
        // ref={(input) => { this.secondTextInput = input; }}
        enhancedFieldProps.ref = this._ref(field.name)

        // Bind focus function to first TextInput's onSubmitEditing event.
        // onSubmitEditing={() => { this.secondTextInput.focus(); }}
        const nextField = fields[i + 1]
        if (nextField && isTextInput(nextField.type)) {
          enhancedFieldProps.returnKeyType = 'next'
          enhancedFieldProps.onSubmitEditing = this._focus(nextField.name)
        } else if (nextField) {
          enhancedFieldProps.onSubmitEditing = this._blur(field.name)
          enhancedFieldProps.returnKeyType = 'next'
        }
      }

      return (
        <Field
          key={field.name + '_' + i}
          name={field.name}
          type={field.type}
          label={field.label}
          options={field.options}
          onChange={onChange}
          value={values[field.name]}
          fieldProps={enhancedFieldProps}
          error={field.error}
        />
      )
    })
  }
}

Fields.defaultProps = {
  fields: [],
}
Fields.propTypes = {
  onChange: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
  fields: PT.arrayOf(
    PT.shape({
      name: PT.string.isRequired,
      type: PT.string.isRequired,
      label: PT.string,
    })
  ),
}
export default Fields
