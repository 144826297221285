import { call, all } from 'redux-saga/effects'

import auth from 'react-auth/lib/sagas/authentication'
import forgotPassword from 'react-auth/lib/sagas/forgotPassword'

import settings from './Settings/saga'

// USER
import user from './User/saga'
import userOrganization from './User/Organization/saga'
import userRegistration from './User/Registration/saga'
import organizationPriceList from './Organization/PriceList/saga'
import userAttributes from './User/Attributes/saga'

// MAIL
import mail from './Mail/saga'
import mailDesign from './Mail/Design/saga'
import mailCampaign from './Mail/Campaign/saga'
import mailOrganizationGroup from './Mail/OrganizationGroup/saga'
import mailProduct from './Mail/Product/saga'
import mailCategory from './Mail/Category/saga'
import mailPreview from './Mail/Preview/saga'
import mailUser from './Mail/User/saga'
import mailOrganization from './Mail/User/Organization/saga'
import organization from './Organization/saga'
import directSend from './Mail/DirectSend/saga'
import mailManageRegistrations from './Mail/ManageRegistrations/saga'
import { keyPagedListCategoryRow, categorySaga } from './Home/saga'
import addUserRole from './User/Role/saga'
import addUserRoleAll from './User/Role/All/saga'

// ORGANIZATION_GROUP
import selectedOrganization from './Organization/SelectedOrganization/saga'
import organizationGroup from './OrganizationGroup/saga'
import organizationGroupOrganization from './OrganizationGroup/Organization/saga'

// EVENT
import event from './Event/saga'

export default function* rootSaga() {
  yield all([
    call(auth),
    call(userRegistration),
    call(organizationPriceList),
    call(mailManageRegistrations),
    call(forgotPassword),
    call(settings),
    call(user),
    call(mailCampaign),
    call(userOrganization),
    call(mail),
    call(mailDesign),
    call(mailOrganizationGroup),
    call(keyPagedListCategoryRow),
    call(categorySaga),
    call(mailProduct),
    call(mailCategory),
    call(mailPreview),
    call(directSend),
    call(mailUser),
    call(addUserRole),
    call(addUserRoleAll),
    call(mailOrganization),
    call(organization),
    call(organizationGroup),
    call(organizationGroupOrganization),
    call(selectedOrganization),
    call(userAttributes),
    call(event)
  ])
}
