import React from 'react'
import { View, StyleSheet } from 'react-native'
import { getTranslation } from '../../language'
import SafeListItem from '../../components/SafeListItem'

const styles = StyleSheet.create({
  root: { flexDirection: 'row', alignItems: 'center' },
  item: { flex: 1 },
})

const productItemSettings = { left: true, right: false }

function ProductItem({ product, style, onPress, left }) {
  return (
    <View style={styles.root}>
      <SafeListItem
        titleNumberOfLines={2}
        testID={'productItem'}
        title={getTranslation(product, 'name')}
        description={[
          product.sku,
          getTranslation(product.salesUnit, 'singularName'),
        ]
          .filter((n) => n)
          .join(' - ')}
        onPress={onPress}
        titleEllipsizeMode={'tail'}
        descriptionEllipsizeMode={'tail'}
        style={[styles.item, style]}
        left={left}
        safeSettings={productItemSettings}
      />
    </View>
  )
}

export default ProductItem
