import React from "react";
import { DataTable } from "react-native-paper";
import PT from 'prop-types'

const TableRowWithTwoColumns = ({ first, second }) => (
  <DataTable.Row>
    <DataTable.Cell>{first}</DataTable.Cell>
    <DataTable.Cell>{second}</DataTable.Cell>
  </DataTable.Row>
)

TableRowWithTwoColumns.propTypes = {
  first: PT.oneOfType(PT.string.isRequired, PT.elementType.isRequired).isRequired,
  second: PT.oneOfType(PT.string.isRequired, PT.elementType.isRequired).isRequired
}

export default TableRowWithTwoColumns;
