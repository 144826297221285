import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { Checkbox, List } from 'react-native-paper'
import { Hoverable } from 'react-native-web-hover'

export const weekDays = [
  {
    title: 'Maandag',
    value: 'monday',
  },
  {
    title: 'Dinsdag',
    value: 'tuesday',
  },
  {
    title: 'Woensdag',
    value: 'wednesday',
  },
  {
    title: 'Donderdag',
    value: 'thursday',
  },
  {
    title: 'Vrijdag',
    value: 'friday',
  },
  {
    title: 'Zaterdag',
    value: 'saturday',
  },
  {
    title: 'Zondag',
    value: 'sunday',
  },
]

const styles = StyleSheet.create({})

const daysAsString = (days) =>
  weekDays
    .filter((wd) => days.some((d) => d.day === wd.value))
    .map((wd, i) => (i > 0 ? wd.title.toLowerCase() : wd.title))
    .join(', ')

class RepeatMail extends Component {
  state = {
    open: false,
    expanded: false,
  }
  _toggleMenu = () => this.setState((prevState) => ({ open: !prevState.open }))
  _closeMenu = () => this.setState({ open: false })

  _toggleDay = (weekDay, isSelected) => () => {
    const repeatMail = this.props.value
    const days = repeatMail && repeatMail.days ? repeatMail.days : []
    const newObj = {
      ...repeatMail,
      days: isSelected
        ? days.filter((d) => d.day !== weekDay.value)
        : [...days, { day: weekDay.value }],
    }

    this.props.onChange(newObj)
  }
  _handleCollapse = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }
  _toggleActive = () => {
    const { value } = this.props
    const oldActive = !!(value && value.active)
    const active = !oldActive
    if (active) {
      this.setState({
        expanded: active,
      })
    }
    this.props.onChange({
      ...this.props.value,
      active,
    })
  }
  render() {
    const { expanded } = this.state
    const { value, label } = this.props
    const days = value && value.days ? value.days : []
    const active = !!(value && value.active)

    return (
      <View style={styles.groups}>
        <Hoverable key={`repeatmail_${Math.random()}`}>
          {({ hovered }) => (
            <List.Item
              title={label}
              style={
                hovered ? { cursor: 'pointer' } : { backgroundColor: '#FFF' }
              }
              description={!active || expanded ? '' : daysAsString(days)}
              onPress={active ? this._handleCollapse : this._toggleActive}
              expanded={expanded}
              left={() => (
                <View style={{ justifyContent: 'center' }}>
                  <Checkbox
                    onPress={this._toggleActive}
                    status={value && value.active ? 'checked' : 'unchecked'}
                  />
                </View>
              )}
              right={
                active
                  ? (iProps) => (
                      <List.Icon
                        {...iProps}
                        icon={expanded ? 'chevron-up' : 'chevron-down'}
                      />
                    )
                  : null
              }
            />
          )}
        </Hoverable>
        {active && expanded && (
          <View
            style={{
              paddingLeft: 36,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {weekDays.map((weekDay) => {
              const isSelected = days.some((d) => weekDay.value === d.day)
              return (
                <Hoverable
                  key={`repeatmail_field_${weekDay.value}`}
                  style={{ minWidth: '33%' }}
                >
                  {({ hovered }) => (
                    <List.Item
                      key={weekDay.value}
                      style={hovered && { cursor: 'pointer' }}
                      title={weekDay.title}
                      onPress={this._toggleDay(weekDay, isSelected)}
                      left={() => (
                        <View style={{ justifyContent: 'center' }}>
                          <Checkbox
                            onPress={this._toggleDay(weekDay, isSelected)}
                            status={isSelected ? 'checked' : 'unchecked'}
                          />
                        </View>
                      )}
                    />
                  )}
                </Hoverable>
              )
            })}
          </View>
        )}
      </View>
    )
  }
}

export default RepeatMail
