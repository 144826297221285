const initialState = {}
const initialKeyState = {
  params: {},
  data: [],
  pagination: {},
  loaded: false,
  loading: false,
  loadingMore: false,
  canLoadMore: false,
  error: false,
  errorMore: false,
}

export const getPagination = payload => {
  let pagination = {}
  // if (payload && payload.data && payload.data.pagination) {
  //   return payload.data.pagination
  // }
  if (payload && payload.pagination) {
    return payload.pagination
  }
  return pagination
}

export const getData = payload => {
  let data = []
  // if (
  //   payload &&
  //   payload.data &&
  //   payload.data.data &&
  //   Array.isArray(payload.data.data)
  // ) {
  //   data = payload.data.data
  // } else
  if (payload && payload.data && Array.isArray(payload.data)) {
    data = payload.data
  } else if (payload && Array.isArray(payload)) {
    data = payload
  }
  return data
}

const stateForKey = (state, key, newKeyState) => ({
  ...state,
  [key]: newKeyState(state[key] || initialKeyState),
})

const listReducer = actionTypes => (
  state = initialState,
  { type, payload, meta, error }
) => {
  switch (type) {
    case 'RESET': {
      return initialState
    }
    case actionTypes.load: {
      const params =
        payload && payload.params
          ? payload.params
          : state[meta.key] && state[meta.key].params
      const data = state[meta.key] && state[meta.key].data
      const pagination = state[meta.key] && state[meta.key].pagination
      const loaded = state[meta.key] && state[meta.key].loaded

      return stateForKey(state, meta.key, state => ({
        params,
        data: data || [],
        pagination: pagination || {},
        error: false,
        loaded,
        loading: !!data ? false : true,
        loadingMore: false,
      }))
    }
    case actionTypes.loaded:
      return error
        ? stateForKey(state, meta.key, state => ({
            data: [],
            pagination: {},
            loading: false,
            loaded: true,
            error: true,
            canLoadMore: false,
          }))
        : stateForKey(state, meta.key, state => ({
            data: getData(payload),
            pagination: getPagination(payload),
            loading: false,
            loaded: true,
            error: false,
            canLoadMore: payload.pagination
              ? payload.pagination.offset + payload.pagination.limit <
                payload.pagination.total
              : false,
          }))
    case actionTypes.loadMore:
      const params = payload && payload.params ? payload.params : state.params
      return stateForKey(state, meta.key, state => ({
        params,
        loadingMore: true,
      }))

    case actionTypes.loadedMore:
      const pagination = getPagination(payload)

      return error
        ? stateForKey(state, meta.key, state => ({
            loadingMore: false,
            errorMore: true,
            canLoadMore: false,
          }))
        : stateForKey(state, meta.key, state => ({
            data: [...state.data, ...getData(payload)],
            pagination: {
              ...state.pagination,
              ...getPagination(payload),
            },
            loadingMore: false,
            errorMore: false,
            canLoadMore:
              pagination &&
              pagination.offset + pagination.limit < pagination.total,
          }))

    default:
      return state
  }
}

export default listReducer
