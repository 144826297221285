import React, { Component } from 'react'
import { StyleSheet, Platform, Image } from 'react-native'

class Logo extends Component {
  render() {
    return <Image
      source={process.env.REACT_APP_STATIC_ASSETS_ENDPOINT ? `${process.env.REACT_APP_STATIC_ASSETS_ENDPOINT}/media/template/logo.png` : require('../img/logo.png')}
      style={styles.logo}/>
  }
}

const styles = StyleSheet.create({
  logo: {
    width: 90,
    height: 50,
    resizeMode: 'contain',
    alignSelf: Platform.OS === 'web' ? null : 'center',
  },
})

export default Logo
