import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { Menu, List } from 'react-native-paper'

const styles = StyleSheet.create({
  root: {
    // flexDirection: 'row',
    // height: 56,
    // alignItems: 'center',
    // flex: 1,
  },
  labelButton: {
    flex: 1,
  },
  label: {
    padding: 8,
    marginLeft: 6,
  },
})

const rightFunc = (p) => <List.Icon {...p} icon="chevron-down" />

class Options extends Component {
  state = {
    visible: false,
  }
  _openMenu = () => this.setState({ visible: true })
  _closeMenu = () => this.setState({ visible: false })
  _select = (option) => () => {
    this._closeMenu()
    this.props.onChange(option.value)
  }

  render() {
    const { value, label, style, editable } = this.props
    const options = this.props.options || []
    const selectedOption = options.find((option) => option.value === value)

    return (
      <View style={[styles.root, style]}>
        <Menu
          visible={this.state.visible}
          onDismiss={this._closeMenu}
          style={{ flex: 1 }}
          anchor={
            <List.Item
              onPress={this._openMenu}
              title={selectedOption ? selectedOption.title : label}
              description={selectedOption ? label : ''}
              right={editable ? rightFunc : null}
              disabled={!editable}
            />
          }
        >

          {options.map((option, index) => (
            <Menu.Item
              key={`${index}_${options.title}`}
              onPress={this._select(option)}
              title={option.title}
              description={option.description}
            />
          ))}

        </Menu>
      </View >
    )
  }
}

Options.defaultProps = {
  editable: true,
}
export default Options
