import React, { Component } from 'react'

export default WrappedComponent => {
  class HOC extends Component {
    render() {
      const params = this.props.match && this.props.match.params
      // const id = params.id && params.id.length > 0 ? Number(id) : undefined

      return <WrappedComponent {...this.props} {...params} />
    }
  }
  return HOC
}
