import { View } from 'react-native'
import React, { Component } from 'react'

class ShimmerPlaceHolder extends Component {
  render() {
    const { style } = this.props
    return <View style={style} className="shimmer" />
  }
}
ShimmerPlaceHolder.defaultProps = {}
ShimmerPlaceHolder.propTypes = {}

export default ShimmerPlaceHolder
