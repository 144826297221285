import React, { Component } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { Appbar, List } from 'react-native-paper'
import SafeAreaView from '../components/SafeAreaView'
import { connect } from 'react-redux'
import { logout } from 'react-auth/lib/actions/authentication'
import { push } from '../Navigation'
import {
  ACCOUNT_DETAIL_SCREEN_ROUTE,
  LOGOUT_SCREEN,
  ORGANIZATION_DETAIL_SCREEN_ROUTE,
} from '../Routes.config'
import Container from '../components/Container'

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
  enter: {
    height: 12,
  },
  container: { padding: 16, flex: 1 },
  chooser: { paddingTop: 10 },
  label: { color: '#383838' },
})

class Home extends Component {
  state = {
    expanded: true,
  }

  _accountDetails = () => {
    push(this.props.componentId, {
      component: {
        name: ACCOUNT_DETAIL_SCREEN_ROUTE,
      },
    })
  }
  _organizationDetails = () => {
    push(this.props.componentId, {
      component: {
        name: ORGANIZATION_DETAIL_SCREEN_ROUTE,
      },
    })
  }
  _logout = () => {
    push(this.props.componentId, {
      component: {
        name: LOGOUT_SCREEN,
      },
    })
  }
  _handlePress = () =>
    this.setState({
      expanded: !this.state.expanded,
    })
  _openMenu = () => this.setState({ visible: true })

  _closeMenu = () => this.setState({ visible: false })

  render() {
    const { user } = this.props
    console.log(user)
    return (
      <Container>
        <SafeAreaView>
          <Appbar style={styles.header}>
            <Appbar.Content title={'Mijn account'} />
          </Appbar>

          <ScrollView
            keyboardShouldPersistTaps={'always'}
            keyboardDismissMode={'on-drag'}
          >
            {/* <List.Item
            title={
              user.user.firstName !== ''
                ? `${user.user.firstName} ${user.user.preposition} ${
                    user.user.lastName
                  }`
                : 'Account'
            }
            description="Bewerk je gegevens"
            onPress={this._accountDetails}
          />

          <List.Item
            title={
              user.organization.name !== ''
                ? `${user.organization.name}`
                : 'Organisatie'
            }
            description={'Bewerk je organisatie'}
            onPress={this._organizationDetails}
          /> */}
            <List.Item title="Uitloggen" onPress={this._logout} />
          </ScrollView>
        </SafeAreaView>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.user,
})

export default connect(mapStateToProps, { logout })(Home)
