import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { List } from 'react-native-paper'
import ClosableModal from '../../components/ClosableModal'
import L from './List'
import { Hoverable } from 'react-native-web-hover'

const styles = StyleSheet.create({
  root: { width: '100%' },
})

class SingleSelect extends Component {
  state = {
    modalVisible: false,
  }
  _hideModal = () => {
    this.setState({ modalVisible: false })
  }
  _showModal = () => {
    this.setState({ modalVisible: true })
  }
  _isSelected = (item) => {
    return (this.props.value || []).some((s) => s.id === item.id)
  }
  _select = (item) => {
    this.props.onSelect(item)
  }

  render() {
    const { modalVisible } = this.state
    const value = this.props.value

    return (
      <View style={styles.root}>
        <Hoverable key={`organization_${Math.random()}`}>
          {({ hovered }) => (
            <List.Item
              onPress={this._showModal}
              style={
                hovered ? { cursor: 'pointer' } : { backgroundColor: '#FFF' }
              }
              testID={this.props.testID}
              title={
                value
                  ? value.map((v) => v.name).join(', ')
                  : 'Geen organisaties geselecteerd'
              }
              description={value ? (this.props.customDescriptionFilled ? this.props.customDescriptionFilled : 'Organisaties') :(this.props.customDescription ? this.props.customDescription :  'Selecteer organisaties')}
              right={(p) => <List.Icon {...p} icon="chevron-down" />}
            />
          )}
        </Hoverable>

        <ClosableModal
          isVisible={modalVisible}
          onBackButtonPress={this._hideModal}
          onBackdropPress={this._hideModal}
          onRequestClose={this._hideModal}
          hideHeader={true}
          // showClose={true}
        >
          <L
            {...this.props}
            multiSelect
            isSelected={this._isSelected}
            searchIcon={'close'}
            onSearchIconPress={this._hideModal}
            onSelect={this._select}
          />
        </ClosableModal>
      </View>
    )
  }
}

export default SingleSelect
