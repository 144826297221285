import React, { Component } from 'react'
import { StyleSheet, Dimensions, View } from 'react-native'

import widthAndHeightHOC from './widthAndHeightHOC'
const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
})

class WidthAndHeight extends Component {
  _onLayout = () => {
    const { width, height } = Dimensions.get('window')
    this.props.update({ width, height })
  }

  render() {
    const { listenToChanges } = this.props
    return (
      <View onLayout={listenToChanges && this._onLayout} style={styles.root}>
        {this.props.render(this.props)}
      </View>
    )
  }
}

export default widthAndHeightHOC(WidthAndHeight)
