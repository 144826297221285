import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Widget from './Widgets/Widget'
import Container from '../components/Container'
import { HelperText, TextInput } from 'react-native-paper'
import { getTranslationValue } from '../helpers/language'
import { StyleSheet } from 'react-native'

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging

  // styles we need to apply on draggables
  ...draggableStyle,
})

const styles = StyleSheet.create({
  textInput: { backgroundColor: 'transparent' },
})
function SwipeableList(props) {
  // const scrollY = useScrollPosition(60 /*fps*/)
  // console.log({ scrollY })
  return (
    <div style={{ overflowY: 'scroll', height: 700 }}>
      <DragDropContext onDragEnd={props.onMoveEnd}>
        <Droppable droppableId="droppable" style={props.style}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Container>
                <TextInput
                  style={styles.textInput}
                  mode={'flat'}
                  label={'Onderwerp'}
                  testID={'subject'+props.language}
                  value={getTranslationValue(
                    props.mail,
                    'subject',
                    props.language
                  )}
                  onChangeText={props.changeWithLanguage('subject')}
                />
                <HelperText
                  type="error"
                  visible={props.hasNoSubject.length > 0}
                >
                  Vul een onderwerp in voor de volgende talen:{' '}
                  {props.hasNoSubject.map((it) => `${it.toUpperCase()} `)}
                </HelperText>
              </Container>
              {props.data.map((item, index) => {
                if (props.dragDropMode) {
                  return (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        if (snapshot.isDragging) {
                          // provided.draggableProps.style.top = 800
                        }
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Widget
                              item={item}
                              dragDropMode={true}
                              isActive={props.isActive}
                              onChange={props.changeWidget(item)}
                              onDelete={props.deleteWidget(item)}
                              language={props.language}
                            />
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                }
                return (
                  <Widget
                    item={item}
                    dragDropMode={false}
                    isActive={props.isActive}
                    onChange={props.changeWidget(item)}
                    onDelete={props.deleteWidget(item)}
                    language={props.language}
                  />
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default SwipeableList
