import { call, put, select, takeLatest } from 'redux-saga/effects'
import { fetchListener } from 'react-auth/lib/sagas/authentication'
import * as a from './actions'

function* previewMail() {
  try {
    const mail = yield select(state => state.mail.crud.object)
    const user = yield select(state => state.preview.user)
    let response = yield call(fetchListener, {
      path: `v1/newsletter/preview`,
      method: 'POST',
      body: mail,
      params: {
        userId: (user || {}).id,
      },
      type: 'text',
    })
    yield put(a.previewedMail(response))
  } catch (e) {
    console.log(e)
    yield put(a.previewedMail(null, null, true))
  }
}

export default function* preview() {
  yield takeLatest([a.PREVIEW_MAIL, a.SELECT_USER], previewMail)
}
