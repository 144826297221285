import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { withTheme, RadioButton, Checkbox, List } from 'react-native-paper'
import actions from './actions'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import { Hoverable } from 'react-native-web-hover'

class L extends Component {
  _getParams = (ignore, inputSearch) => {
    const { list } = this.props
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }
    return {
      ...list.params,
      order: 'name',
      search,
      name: search,
      'search[name]': search,
      'search[language]': 'nl',
      sort: 'name',
    }
  }
  _renderLeft = (item, isSelected) => () => {
    let SelectionElement

    if (this.props.singleSelect) {
      SelectionElement = RadioButton.Android
    } else if (this.props.multiSelect) {
      SelectionElement = Checkbox.Android
    }

    if (SelectionElement) {
      return (
        <View style={styles.leftContainer}>
          <SelectionElement
            status={isSelected ? 'checked' : 'unchecked'}
            onPress={this._select(item)}
          />
        </View>
      )
    }
    return null
  }
  _press = (item) => () => {
    this.props.onSelect(item)
  }
  _select = (item) => () => {
    this.props.onSelect(item)
  }

  _renderItem = ({ item }) => {
    return (
      <Hoverable key={`list_item_user_organization_${item.id}`}>
        {({ hovered }) => (
          <List.Item
            key={item.id}
            style={
              hovered ? { cursor: 'pointer' } : { backgroundColor: '#FFF' }
            }
            title={item.name}
            onPress={this._press(item)}
            left={this._renderLeft(item, this.props.isSelected(item))}
          />
        )}
      </Hoverable>
    )
  }

  render() {
    const { loadList, reloadList, loadMoreList, componentId, list } = this.props

    return (
      <GeneralList
        {...this.props}
        searchPlaceHolder={'Doorzoek organisaties'}
        reloadList={reloadList}
        loadList={loadList}
        loadMoreList={loadMoreList}
        renderAboveHeader={this._renderAboveHeader}
        list={list}
        componentId={componentId}
        renderItem={this._renderItem}
        icon
        getParams={this._getParams}
      />
    )
  }
}

// eslint-disable-next-line
const styles = StyleSheet.create({})

L.propTypes = {}
L.defaultProps = {}

const mapStateToProps = (state) => ({
  list: state.userOrganization.list,
})

export default connect(mapStateToProps, actions)(withTheme(L))
