import React, { Component } from 'react'
import { StyleSheet } from 'react-native'
import { Appbar } from 'react-native-paper'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
})

class AppbarHeader extends Component {
  render() {
    const { safeMargin, style } = this.props
    return (
      <Appbar style={[styles.header, safeMargin, { marginBottom: 0 }, style]}>
        {this.props.children}
      </Appbar>
    )
  }
}
export default safeAreaHOC(AppbarHeader)
