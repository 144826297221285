import { call, put, select, take, takeLatest } from 'redux-saga/effects'
import { setMessages } from '../Notifications/actions'
import { fetchListener } from 'react-auth/lib/sagas/authentication'
import dayjs from '../dayjs'
import { weekDays } from '../Mail/RepeatMailField'
import { unix } from 'dayjs'

const numberPerRequest = 30

function* load(actions, getPath, getState, action) {
  try {
    const state = yield select((state) => state)
    const path = getPath(state)
    const { crud } = yield select((state) => getState(state))
    const { object } = crud
    if (!object || !object.id) {
      yield put(actions.loaded(object))
      return
    }
    const { params, cachedFromList } = action.meta || {}
    if (cachedFromList) {
      yield put(actions.loaded(object))
      return
    }
    const newParams = {
      ...params,
      'embed[]': 'organization',
      preloadManagedByOrganizations: true,
    }
    const response = yield call(fetchListener, {
      path: `${path}/${object.id}`,
      params: newParams,
    })

    yield put(actions.loaded(response))
  } catch (e) {
    yield put(actions.loaded(e, null, true))
  }
}

const deliveryDateIsInBuffer = (repeatMail) => {
  const dayNameOfToday =
    weekDays[dayjs(new Date()).subtract(1, 'day').get('day')]
  const timeNowHour = dayjs(new Date()).format('H')
  const timeNowMinute = dayjs(new Date()).format('mm')
  const testDate = new Date(repeatMail.time * 1000)
  const fullDate = new Date(
    testDate.getUTCFullYear(),
    testDate.getUTCMonth(),
    testDate.getUTCDate(),
    testDate.getUTCHours(),
    testDate.getUTCMinutes(),
    0
  )
  const repeatMailTimeHour = dayjs(fullDate).format('H')
  const repeatMailTimeMinute = dayjs(fullDate).format('mm')
  if (
    timeNowHour === repeatMailTimeHour &&
    Number(timeNowMinute) === Number(repeatMailTimeMinute) - 1 &&
    repeatMail.days.some((d) => d.day === dayNameOfToday.value)
  ) {
    return true
  }
  return false
}

function* update(actions, getPath, getState, action) {
  try {
    const state = yield select((state) => state)
    const path = getPath(state)
    const { crud } = yield select((state) => getState(state))
    const { object } = crud
    const { params, updateManagingOrganizations } = action.meta || {}

    if (
      (!object.deliveryDate || object.deliveryDate === 0) &&
      !object.repeatMail?.active
    ) {
      const date = new Date()
      const now_utc = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        0
      )
      object.deliveryDate = dayjs(now_utc).unix()
    }
    if (
      object.repeatMail &&
      (!object.repeatMail.time || object.repeatMail.time === 0) &&
      object.repeatMail.active
    ) {
      let date = new Date()
      const now_utc = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        0
      )
      object.repeatMail.time = dayjs(now_utc).unix()
    }
    if (
      object.repeatMail &&
      object.repeatMail.active &&
      deliveryDateIsInBuffer(object.repeatMail)
    ) {
      yield put(
        setMessages([
          'Omdat het verzendmoment binnen 1 minuut ligt, hebben we de mailing met 1 minuut opgeschoven.',
        ])
      )
      object.repeatMail.time = unix(object.repeatMail.time)
        .add(1, 'minutes')
        .unix()
    }
    if (!object.timezone) {
      object.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    let fullObject = object
    if (action.payload) {
      fullObject = action.payload
    }

    const response = yield call(fetchListener, {
      path: `${path}/${action.payload ? action.payload.user.id : object.id}`,
      method: 'PUT',
      body: fullObject,
      params,
    })

    if (params?.sendInvite) {
      yield call(fetchListener, {
        path: `v1/user/${
          action.payload ? action.payload.user.id : object.id
        }/invite`,
        method: 'POST',
        body: {
          id: action.payload ? action.payload.user.id : object.id,
        },
      })
    }
    if (updateManagingOrganizations) {
      yield call(fetchListener, {
        path: `v1/auth/organization/${object.id}/managing/relations`,
        method: 'PUT',
        body: fullObject?.managedByOrganizations,
      })
    }

    yield put(actions.updated(response))
  } catch (e) {
    yield put(setMessages([e.message || 'Kon item niet bijwerken']))
    yield put(actions.updated(e, null, true))
  }
}

function* remove(actions, getPath, getState, action) {
  try {
    const state = yield select((state) => state)
    const path = getPath(state)
    const { crud } = yield select((state) => getState(state))
    const { object } = crud
    const { params } = action.meta || {}
    // const response =
    if (object && object.id) {
      yield call(fetchListener, {
        path: `${path}/${object.id}`,
        method: 'DELETE',
        params,
      })
    } else {
      yield call(fetchListener, {
        path: `${path}`,
        method: 'DELETE',
        params,
        body: action.payload,
      })
    }
    if (object && object.id) {
      yield put(actions.removed(object))
    } else {
      yield put(actions.removed(action.payload))
    }
  } catch (e) {
    yield put(setMessages([e.message || 'Kon item niet verwijderen']))
    yield put(actions.removed(e, null, true))
  }
}

function* create(actions, getPath, getState, action) {
  try {
    const state = yield select((state) => state)
    const url = getPath(state)
    const { crud } = yield select((state) => getState(state))
    let { object } = crud
    const { params, routeAddition } = action.meta || {}
    if (!object.deliveryDate || object.deliveryDate === 0) {
      const date = new Date()
      const now_utc = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        0
      )
      object.deliveryDate = dayjs(now_utc).unix()
    }
    if (
      object.repeatMail &&
      (!object.repeatMail.time || object.repeatMail.time === 0)
    ) {
      const date = new Date()
      const now_utc = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        0
      )
      object.repeatMail = {
        ...object.repeatMail,
        time: dayjs(now_utc).unix(),
      }
    }
    if (!object.timezone) {
      object.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    const response = yield call(fetchListener, {
      path: url + (routeAddition ? routeAddition : ''),
      method: 'POST',
      body: object,
      params,
    })

    yield put(actions.created(response))
  } catch (e) {
    yield put(setMessages([e.message || 'Kon item niet aanmaken']))

    yield put(actions.created(e, null, true))
  }
}

function* loadList(actions, getPath, getState, action, loadMore) {
  try {
    const state = yield select((state) => state)
    const url = getPath(state)
    const { list } = yield select((state) => getState(state))

    const meta = action.meta || {}
    const params = {
      ...list.params,
      ...meta.params,
      limit:
        list.params && list.params.limit ? list.params.limit : numberPerRequest,
    }

    if (loadMore) {
      const { offset, total } = list.pagination
      if (offset > total) {
        return
      }
      params.offset = offset + numberPerRequest
    }
    const response = yield call(fetchListener, {
      method: 'GET',
      path: url,
      params,
    })
    yield put(
      loadMore
        ? actions.loadedMoreList(response, meta)
        : actions.loadedList(response, meta)
    )
  } catch (e) {
    console.log(e)
    yield put(
      loadMore
        ? actions.loadedMoreList(e, null, true)
        : actions.loadedList(e, null, true)
    )
  }
  if (actions.LOAD_MORE_LIST) {
    yield take(actions.LOAD_MORE_LIST)
    yield call(loadList, actions, getPath, getState, action, true)
  }
}

export default function* pagedList(
  actions,
  getPath = (state) => '',
  getState = (state) => console.log('get state not implemented'),
  reloadConfig
) {
  let reloadActions = [actions.CREATED]
  if (reloadConfig && reloadConfig.removeOthers) {
    reloadActions = reloadConfig.reloadActions
  } else if (reloadConfig) {
    reloadActions = [...reloadConfig.reloadActions, reloadActions]
  }

  yield takeLatest(
    [actions.LOAD_LIST, actions.RELOAD_LIST, ...reloadActions],
    loadList,
    actions,
    getPath,
    getState
  )
  yield takeLatest(actions.LOAD, load, actions, getPath, getState)
  yield takeLatest(actions.UPDATE, update, actions, getPath, getState)
  yield takeLatest(actions.CREATE, create, actions, getPath, getState)
  yield takeLatest(actions.REMOVE, remove, actions, getPath, getState)
}
