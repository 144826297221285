import './index.css'
import 'core-js'

import 'raf/polyfill'

import 'url-search-params-polyfill'
import 'string.prototype.startswith'

import React from 'react'
import { AppRegistry } from 'react-native'
import iconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import App from './src/App'
import { name as appName } from './app.json'

// includes polyfill IE11
if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    if (typeof start !== 'number') {
      start = 0
    }

    if (start + search.length > this.length) {
      return false
    } else {
      return this.indexOf(search, start) !== -1 //push
    }
  }
}

AppRegistry.registerComponent(appName, () => App)

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
})

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: MaterialCommunityIcons;
}`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles
} else {
  style.appendChild(document.createTextNode(iconFontStyles))
}

// Inject stylesheet
document.head.appendChild(style)
