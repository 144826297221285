import { getErrorMessage } from '../api'
import { uniqBy } from 'lodash'
// CRUD
const initialCrudState = {
  object: undefined,

  loading: false,
  updating: false,
  removing: false,
  creating: false,
  loaded: false,

  loadingError: false,
  loadingErrorMessage: '',
  updatingError: false,
  updatingErrorMessage: '',
  removingError: false,
  removingErrorMessage: '',
  creatingError: false,
  creatingErrorMessage: '',
}
// PAGED LIST
const initialListState = {
  data: [],
  params: undefined,

  reloadingList: false,
  loadingList: false,
  loadingMoreList: false,
  loadedList: false,

  pagination: {},
  stopLoadingMore: false,

  loadingListError: false,
  loadingListErrorMessage: '',

  loadingMoreListError: false,
  loadingMoreListErrorMessage: '',
}

const defaultState = {
  crud: initialCrudState,
  list: initialListState,
}

const getDataFromResponse = (response, oldList, silentReloading) => {
  // {
  //   data: {
  //     success: true,
  //     data: {
  //       data: [],
  //       current_page: 1,
  //       last_page: 1,
  //       per_page: 15,
  //     },
  //     message: '....'
  //   },
  //   status: 200,
  //   statusText: 'OK',
  //   headers: {},
  // }

  // and sometimes without pagination ->
  // {
  //   {
  //     data: [],
  //     message: '....'
  //   },
  //   status: 200,
  //   statusText: 'OK',
  //   headers: {},
  // }

  const apiResponse = response || {}
  let data = Array.isArray(apiResponse)
    ? apiResponse
    : apiResponse.data && Array.isArray(apiResponse.data)
    ? apiResponse.data
    : []
  let apiPagination = apiResponse.pagination || {}

  // console.log(apiResponse.pagination, 'pagination')
  let pagination = {}
  pagination.offset = apiPagination.offset || 0

  if (silentReloading && oldList) {
    data = uniqBy(data.concat(oldList.data), 'id')
    pagination.offset = oldList.pagination.offset
  }
  pagination.total = apiPagination.total
  pagination.limit = apiPagination.limit

  return {
    data,
    pagination,
  }
}

export const getObjectFromResponse = (response) => {
  // if (response && response.data) {
  //   return response.data
  // }
  return response
}

export default (actionTypes, initialState = defaultState) => (
  state = initialState,
  { type, payload, meta, error }
) => {
  switch (type) {
    case 'RESET': {
      return initialState
    }
    case actionTypes.SET_DATA: {
      return {
        ...state,
        crud: {
          ...state.crud,
          object: {
            ...state.crud.object,
            ...payload,
          },
        },
      }
    }
    case actionTypes.LOAD: {
      let object = undefined
      let loading = true

      if (payload && payload.id) {
        object = payload
      } else {
        loading = false
      }
      // Let's give our users the fastest response possible without networking
      if (meta && meta.cacheFromList && object && object.id) {
        // console.log('cache from list!')
        // console.log(state.list.data)
        // console.log(object)
        const cachedObject = state.list.data.find((it) => it.id === object.id)
        // console.log('cached', cachedObject)
        // console.log(cachedObject)
        if (cachedObject) {
          object = cachedObject
          loading = false
          meta.cachedFromList = true
        }
      }

      return {
        ...state,
        crud: {
          ...initialCrudState,
          loaded: false,
          loading,
          object,
        },
      }
    }
    case actionTypes.LOADED: {
      if (error) {
        return {
          ...state,
          crud: {
            ...initialCrudState,
            loaded: true,
            loadingError: true,
            loadingErrorMessage: getErrorMessage(payload),
          },
        }
      }
      return {
        ...state,
        crud: {
          ...initialCrudState,
          loaded: true,
          object: getObjectFromResponse(payload),
        },
      }
    }
    case actionTypes.CREATE: {
      return {
        ...state,
        crud: {
          ...state.crud,
          object: payload || state.crud.object,
          creating: true,
          creatingError: false,
        },
      }
    }
    case actionTypes.CREATED: {
      if (error) {
        return {
          ...state,
          crud: {
            ...state.crud,
            creating: false,
            creatingError: true,
            creatingErrorMessage: getErrorMessage(payload),
          },
        }
      }
      const object = getObjectFromResponse(payload)
      return {
        ...state,
        list: {
          ...state.list,
          data: [...state.list.data, object],
        },
        crud: {
          ...state.crud,
          object,
          creating: false,
          creatingError: false,
        },
      }
    }
    case actionTypes.UPDATE: {
      return {
        ...state,
        crud: {
          ...state.crud,
          updating: true,
          updatingError: false,
        },
      }
    }
    case actionTypes.UPDATED: {
      if (error) {
        return {
          ...state,
          crud: {
            ...state.crud,
            updating: false,
            updatingError: true,
            updatingErrorMessage: getErrorMessage(payload),
          },
        }
      }
      const object = getObjectFromResponse(payload)

      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.map((it) =>
            it.id === object.id ? { ...it, ...object } : it
          ),
        },
        crud: {
          ...state.crud,
          object,
          updating: false,
          updatingError: false,
        },
      }
    }
    case actionTypes.REMOVE: {
      return {
        ...state,
        crud: {
          ...state.crud,
          removing: true,
          removingError: false,
        },
      }
    }
    case actionTypes.REMOVED: {
      if (error) {
        return {
          ...state,
          crud: {
            ...state.crud,
            removing: false,
            removingError: true,
            removingErrorMessage: getErrorMessage(payload),
          },
        }
      }
      // const object = getObjectFromResponse(payload)
      return {
        ...state,
        list: {
          ...state.list,
          data: payload.id
            ? state.list.data.filter((it) => {
                return it.id !== (payload && payload.id)
              })
            : state.list.data.filter((it) => {
                console.log(it.name, payload.name)
                return it.name !== (payload && payload.name)
              }),
        },
        crud: {
          ...state.crud,
          removing: false,
          removingError: false,
        },
      }
    }
    // case actionTypes.SET_LIST_PARAMS:
    case actionTypes.LOAD_LIST:
    case actionTypes.RELOAD_LIST: {
      const { silentReloading } = meta || {}
      const { params } = payload || {}
      return {
        ...state,
        list: {
          ...initialListState,
          pagination: {
            offset: silentReloading ? state.list.pagination.offset : 0,
            limit: silentReloading ? state.list.pagination.limit : 0,
            total: silentReloading ? state.list.pagination.total : 0,
          },

          loadedList: silentReloading
            ? state.list.loadedList
            : initialListState.loadedList,
          loadingList: true,
          reloadingList: actionTypes.RELOAD === type,
          params: params || state.list.params,
          data: silentReloading ? state.list.data : [],
        },
      }
    }
    case actionTypes.LOADED_LIST: {
      if (error) {
        return {
          ...state,
          list: {
            ...initialListState,
            params: state.list.params,
            loadingListError: true,
            loadingListErrorMessage: getErrorMessage(payload),
          },
        }
      }
      const { silentReloading } = meta || {}

      const data = getDataFromResponse(payload, state.list, silentReloading)
      const cleanData = data.data.map((it) =>
        it.id || !(it.user && it.user.id) ? it : { ...it, id: it.user.id }
      )

      const stopLoadingMore =
        data.pagination.limit + data.pagination.offset >= data.pagination.total

      return {
        ...state,
        list: {
          ...initialListState,
          stopLoadingMore,
          loadedList: true,
          params: state.list.params,
          ...data,
          data: cleanData,
        },
      }
    }
    case actionTypes.LOAD_MORE_LIST: {
      const { total, offset } = state.list.pagination
      if (offset >= total) {
        return state
      }
      return {
        ...state,
        list: {
          ...state.list,
          loadingMoreList: true,
          loadedList: false,
        },
      }
    }
    case actionTypes.LOADED_MORE_LIST: {
      if (error) {
        return {
          ...state,
          list: {
            ...state.list,
            loadingMoreList: false,
            loadingMoreListError: true,
            loadingMoreListErrorMessage: getErrorMessage(payload),
          },
        }
      }
      const { data, pagination } = getDataFromResponse(payload)
      const cleanData = data.map((it) =>
        it.id ? it : { ...it, id: it.user.id }
      )
      return {
        ...state,
        list: {
          ...initialListState,
          loadedList: true,
          params: state.list.params,
          data: uniqBy(state.list.data.concat(cleanData), 'id'),
          pagination,
        },
      }
    }

    default:
      return state
  }
}
