import * as a from './actions'

const initialState = {
  data: null,
  users: [],
  organizations: [],
  loaded: false,
  error: false,
  loading: false,
}
const reducer = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case a.SELECT_USER: {
      return {
        ...state,
        users: payload,
      }
    }
    case a.SELECT_ORGANIZATION: {
      return {
        ...state,
        organizations: payload,
      }
    }
    case a.SEND_MAIL: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    }
    case a.SENDED_MAIL: {
      return error
        ? {
            ...state,
            loading: false,
            loaded: true,
            error: false,
          }
        : {
            ...state,
            loading: false,
            loaded: true,
            error: true,
          }
    }
    default:
      return state
  }
}

export default reducer
