let language = 'nl'


export const getTranslation = (object, key) => {
    if (!object) {
        return ''
    }
    const translations = object[`${key}Translations`] || {}
    const value = translations[language]
    if (!value) {
        return object[key] || ''
    }
    return value
}
