import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { List, Checkbox } from 'react-native-paper'

import SingleSelect from '../Category/SingleSelect'
import { Hoverable } from 'react-native-web-hover'

const styles = StyleSheet.create({
  root: {
    padding: 6,
  },
})

class CategoryWidget extends Component {
  _toggleSub = () => {
    const widget = this.props.item || {}
    this.props.onChange({
      ...widget,
      showSubcategories: !widget.showSubcategories,
    })
  }
  _select = (category) => {
    const widget = this.props.item || {}
    this.props.onChange({
      ...widget,
      category,
    })
  }
  render() {
    const widget = this.props.item || {}
    const category = widget.category

    return (
      <View>
        <SingleSelect
          value={category}
          onSelect={this._select}
          key={widget.id}
        />
        <View style={styles.root}>
          <Hoverable key={`category_${widget.id}`}>
            {({ hovered }) => (
              <List.Item
                style={
                  hovered ? { cursor: 'pointer' } : { backgroundColor: '#FFF' }
                }
                onPress={this._toggleSub}
                title="Toon subcategorieën"
                left={() => (
                  <Checkbox
                    status={widget.showSubcategories ? 'checked' : 'unchecked'}
                    style={{ paddingLeft: 0 }}
                    onPress={this._toggleSub}
                  />
                )}
              />
            )}
          </Hoverable>
        </View>
      </View>
    )
  }
}

export default CategoryWidget
