import { call, put, takeLatest } from 'redux-saga/effects'
import { getErrorMessage } from '../api'
import { fetchListener } from 'react-auth/lib/sagas/authentication'

import { TOKEN_VALIDATION_SUCCESS } from 'react-auth/lib/actions/authentication'
import * as a from './actions'
import keyPagedList from '../helpers/keyPagedListSaga'

export const keyPagedListCategoryRow = () =>
  keyPagedList(
    (state) => 'v1/product',
    a,
    (state) => state.category.row
  )

const getUpTree = (category, allCategories, upTree = []) => {
  if (category.parentCategoryId === 0) {
    return upTree
  }

  const parent = allCategories.find((c) => c.id === category.parentCategoryId)
  if (parent) {
    upTree.push(parent.id)
    upTree = getUpTree(parent, allCategories, upTree)
  }
  return upTree
}

const getDownTree = (id, allCategories) => {
  const children = allCategories.filter((c) => c.parentCategoryId === id)
  return children.map((c) => c.id) || []
}

function* loadCategory({ payload }) {
  try {
    const response = yield call(fetchListener, {
      path: `v1/product/category`,
      params: {
        offset: 0,
        limit: 1000,
        hideZeroPrice: true,
      },
    })

    let data = []
    if (response && response.data && response.data) {
      data = response.data
    }

    // cache all categories in object where key is id
    let categoryById = {}
    data.forEach((c) => (categoryById[c.id] = c))

    let categoriesByParentId = {}
    data.forEach((c) => {
      const existingCategories = categoriesByParentId[c.parentCategoryId]
      categoriesByParentId[c.parentCategoryId] = [
        ...(existingCategories || []),
        c.id,
      ]
    })

    // Generate tree up for every category
    let upTree = {}
    data.forEach((c) => (upTree[c.id] = getUpTree(c, data)))
    upTree[0] = []

    // Generate tree for every category
    let downTree = {}
    data.forEach((c) => (downTree[c.id] = getDownTree(c.id, data)))
    downTree[0] = getDownTree(0, data)
    // Remove category and children if both category and all children have amountOfProducts == 0
    let categoryIdsToHide = []

    data.forEach((category) => {
      // console.log('CHECK', category.name)
      const allChildrenOfCategory = getAllChildren({
        categoryById,
        categoriesByParentId,
        categoryId: category.id,
      })
      // console.log(
      //   allChildrenOfCategory.filter(cat => cat.amountOfProducts !== 0)
      // )
      const hideCategory = allChildrenOfCategory.every((cat) => {
        return cat.amountOfProducts === 0
      })
      if (hideCategory) {
        // if ([7, 10, 19].some(idtje => idtje === category.id)) {
        //   console.log(category, ' HIDE THIS CATEGORY ', allChildrenOfCategory)
        // }
        // console.log('HIDE', category.name)

        delete categoriesByParentId[category.id]
        categoryIdsToHide.push(category.id)
      }
    })

    Object.keys(downTree).forEach((categoryId) => {
      downTree[categoryId] = (downTree[categoryId] || []).filter(
        (childId) => !categoryIdsToHide.includes(childId)
      )
    })
    console.log({ downTree })
    yield put(
      a.loadedCategories({
        categoriesByParentId,
        categoryById,
        downTree,
        upTree,
      })
    )
  } catch (e) {
    console.log(e)

    yield put(a.loadedCategories(getErrorMessage(e), null, true))
  }
}

function getAllChildren({ categoryById, categoriesByParentId, categoryId }) {
  // we have the head category
  const head = categoryById[categoryId]

  let children = (categoriesByParentId[categoryId] || [])
    .map((cId) => categoryById[cId])
    .filter((n) => n)

  if (children.length > 0) {
    children.forEach((child) => {
      children = [
        ...children,
        ...getAllChildren({
          categoryById,
          categoriesByParentId,
          categoryId: child.id,
        }),
      ]
    })
  }
  children.push(head)
  return children
}

function* putLoad() {
  yield put(a.loadCategories())
}

export function* categorySaga() {
  yield takeLatest(TOKEN_VALIDATION_SUCCESS, putLoad)
  yield takeLatest(a.LOAD_CATEGORIES, loadCategory)
}
