import { call, put, select, take, takeLatest } from 'redux-saga/effects'
import { fetchListener } from 'react-auth/lib/sagas/authentication'
import a from './actions'

function* create(payload) {
  try {
    let organizations = null
    let response = yield call(fetchListener, {
      path: `v1/auth/organization-group`,
      method: 'POST',
      body: {
        name: payload.payload.name,
      },
    })
    if (
      payload.payload.organizations &&
      payload.payload.organizations.length > 0
    ) {
      organizations = yield call(fetchListener, {
        path: `v1/auth/organization-group/${response.id}/member`,
        method: 'PUT',
        body: payload.payload.organizations,
      })
    }
    yield put(a.created({ ...response, organizations }))
  } catch (e) {
    console.log(e)
    yield put(a.created(null, null, true))
  }
}

function* load(payload) {
  try {
    const { crud } = yield select((state) => state.organizationGroup)
    const { object } = crud
    if (!object || !object.id) {
      yield put(a.loaded(object))
      return
    }
    const { params } = payload.meta || {}
    const newParams = { ...params, 'embed[]': 'organizationGroup' }
    let response = yield call(fetchListener, {
      path: `v1/auth/organization-group/${object.id}`,
      method: 'GET',
      params: newParams,
    })
    let responseMembers = yield call(fetchListener, {
      path: `v1/auth/organization-group/${object.id}/member`,
      method: 'GET',
    })
    yield put(a.loaded({ ...response, organizations: responseMembers.data }))
  } catch (e) {
    console.log(e)
    yield put(a.loaded(e))
  }
}

function* loadList(action, loadMore) {
  const numberPerRequest = 30
  try {
    const { list } = yield select((state) => state.organizationGroup)

    const meta = action.meta || {}
    const params = {
      ...list.params,
      ...meta.params,
      limit:
        list.params && list.params.limit ? list.params.limit : numberPerRequest,
    }

    if (loadMore) {
      const { offset, total } = list.pagination
      if (!offset) {
        params.offset = numberPerRequest
      } else {
        if (offset > total) {
          return
        }
        params.offset = offset + numberPerRequest
      }
    }
    const response = yield call(fetchListener, {
      method: 'GET',
      path: `v1/auth/organization-group`,
      params,
    })
    yield put(
      loadMore ? a.loadedMoreList(response, meta) : a.loadedList(response, meta)
    )
  } catch (e) {
    console.log(e)
    yield put(
      loadMore ? a.loadedMoreList(e, null, true) : a.loadedList(e, null, true)
    )
  }
  if (a.LOAD_MORE_LIST) {
    yield take(a.LOAD_MORE_LIST)
    yield call(loadList, action, true)
  }
}

function* update() {
  try {
    const crud = yield select((state) => state.organizationGroup.crud)
    const { object } = crud
    let organizations = null
    let response = yield call(fetchListener, {
      path: `v1/auth/organization-group/${object.id}`,
      method: 'PUT',
      body: {
        name: object.name,
      },
    })
    if (object.organizations && object.organizations.length > 0) {
      organizations = yield call(fetchListener, {
        path: `v1/auth/organization-group/${object.id}/member`,
        method: 'PUT',
        body: object.organizations,
      })
    }
    yield put(a.updated({ ...response, organizations }))
  } catch (e) {
    console.log(e)
    yield put(a.updated(null, null, true))
  }
}

function* remove(payload) {
  try {
    let response = yield call(fetchListener, {
      path: `v1/auth/organization-group/${payload.payload.id}`,
      method: 'DELETE',
    })
    yield put(a.removed(response))
  } catch (e) {
    console.log(e)
    yield put(a.removed(e))
  }
}

export default function* updateSaga() {
  yield takeLatest(a.CREATE, create)
  yield takeLatest(a.LOAD, load)
  yield takeLatest([a.LOAD_LIST, a.RELOAD_LIST, a.CREATED, a.UPDATED], loadList)
  yield takeLatest(a.UPDATE, update)
  yield takeLatest(a.REMOVE, remove)
}
