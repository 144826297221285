import React, { Component } from 'react'
import Fields from './Fields'
import { Button, Menu } from 'react-native-paper'
import { StyleSheet } from 'react-native'

const fieldProps = {
  style: { marginBottom: 16 },
}
const styles = StyleSheet.create({
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
  resizer: {
    // flex: 1,
    maxWidth: 900,
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  avatar: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  button: {
    width: 300,
    alignSelf: 'flex-start',
    marginBottom: 16,
  },
})
const fields = () =>
  [
    {
      name: 'street',
      label: 'Straat',
      type: 'text',
      fieldProps,
    },
    {
      name: 'houseNumber',
      label: 'Huisnummer',
      type: 'text',
      fieldProps,
    },
    {
      name: 'postalCode',
      label: 'Postcode',
      type: 'text',
      fieldProps,
    },
    {
      name: 'city',
      label: 'Woonplaats',
      type: 'text',
      fieldProps,
    },
  ].filter(n => n)
class AddressFields extends Component {
  state = {
    visible: false,
  };

  _openMenu = () => this.setState({ visible: true });

  _closeMenu = () => this.setState({ visible: false });

  _change = (name, value, resolve = true) => {
    const values = {
      ...this.props.values,
      [name]: value,
    }
    if (this.state.visible) {
      this._closeMenu()
    }
    this.props.onChange(values)

    if (resolve) {
      if (
        !values.street ||
        !values.houseNumber ||
        !values.city ||
        values.city.length < 3 ||
        values.street.length < 3
      ) {
        return
      }
    }
  }
  render() {

    return [
      <Menu
        visible={this.state.visible}
        onDismiss={this._closeMenu}
        anchor={
          <Button
            mode={'outlined'}
            uppercase={false}
            style={styles.button}
            onPress={this._openMenu}
          >
            {this.props.values && this.props.values.country ? this.props.values.country === 'nl' ? 'Nederland' : 'België' : 'Selecteer een land'}
          </Button>
        }
      >
        <Menu.Item onPress={() => this._change('country', 'nl')} title="Nederland" />
        <Menu.Item onPress={() => this._change('country', 'be')} title="België" />
      </Menu>,
      <Fields
        key="fields"
        fields={fields()}
        values={this.props.values || {}}
        onChange={this._change}
        onSubmit={this._resolve}
      />,
    ]
  }
}

AddressFields.defaultProps = {
  values: {},
}
export default AddressFields
