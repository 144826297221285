import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export function push(componentId, options) {
  if (options.component.notURLProps) {
    const name = options.component.name
    history.push(name, options.component.notURLProps)
    return
  }
  if (options.component.passProps) {
    let name = options.component.name
    if (!name.endsWith('/')) {
      name += '/'
    }
    name += Object.keys(options.component.passProps)
      .map((key) => options.component.passProps[key])
      .join('/')

    history.push(name)
    return
  }
  history.push(options.component.name)
}

const openScreen = (props, navigateTo, redirectUrl) => {
  if (navigateTo && props.location.pathname !== `/${navigateTo}`) {
    props.history.replace(`/${navigateTo}`)
    return
  }
  props.history.replace('/')
}

export function pushAuth(props) {
  const redirectUrl = props.location.pathname
  if (redirectUrl.includes('logout')) {
    openScreen(props, `auth`)
  } else {
    openScreen(props, `auth?redirectUrl=${redirectUrl}`)
  }
}
export function pushHome(props) {
  const enabledModules = process.env.REACT_APP_ENABLE_MODULES && process.env.REACT_APP_ENABLE_MODULES.split(',')
  const params = new URLSearchParams(props.location.search)
  const redirectUrl =
    params.get('redirectUrl') || '/' + enabledModules[0]
  // console.log('redirectURL!', redirectUrl)
  openScreen(props, redirectUrl.substr(1))
}

export default {
  pop: () => {
    history.goBack()
  },
  popTo: () => {},
  popToRoot: () => {
    const pathNameArray = window.location.pathname.split('/')
    pathNameArray.pop()

    if (pathNameArray.length > 1) {
      history.push('/' + pathNameArray[1])
      return
    }
    history.push('/')
  },
}
