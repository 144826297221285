import * as a from './actions'
import pagedList from '../../helpers/pagedListSaga'

export default () =>
  pagedList(
    state => 'v1/product/category',
    a,
    state => state.mailCategory,
    true
  )
