import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Title } from 'react-native-paper'
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates'

const styles = StyleSheet.create({
  listItem: {
    flex: 1,
    flexDirection: 'column',
  },
  button: {
    maxWidth: 250,
    marginBottom: 10,
  },
})

type DateTimePickerPropsType = {
  mode: 'time' | 'date'
  onChange: (value: any) => any
  date: Date | undefined
}
const hoursMap: { [key: number]: string } = {
  0: 'twelve',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
  13: 'one',
  14: 'two',
  15: 'three',
  16: 'four',
  17: 'five',
  18: 'six',
  19: 'seven',
  20: 'eight',
  21: 'nine',
  22: 'ten',
  23: 'eleven',
}

function DateTimePicker({
  mode,
  onChange,
  date = new Date(),
}: DateTimePickerPropsType) {
  const [visible, setVisible] = React.useState(false)
  const [timeVisible, setTimeVisible] = React.useState(false)
  const onDismiss = React.useCallback(() => {
    setVisible(false)
  }, [setVisible])
  const onDismissTime = React.useCallback(() => {
    setTimeVisible(false)
  }, [setTimeVisible])

  const onChangeFunc = React.useCallback(
    (result) => {
      setVisible(false)
      onChange({ date: result.date })
    },
    [setVisible, onChange]
  )

  const onConfirm = React.useCallback(
    ({ hours, minutes }) => {
      setTimeVisible(false)
      onChange({ hours, minutes })
    },
    [setTimeVisible, onChange]
  )
  const getDateString = React.useCallback((date: Date) => {
    let ye = new Intl.DateTimeFormat('nl', { year: 'numeric' }).format(date)
    let mo = new Intl.DateTimeFormat('nl', { month: 'short' }).format(date)
    let da = new Intl.DateTimeFormat('nl', { day: '2-digit' }).format(date)
    return `${da} ${mo} ${ye}`
  }, [])
  const getTimeString = React.useCallback((date: Date) => {
    let hours = ('0' + date.getHours()).substr(-2)
    let minutes = ('0' + date.getMinutes()).substr(-2)

    return `${hours}:${minutes}`
  }, [])

  return mode === 'date' ? (
    <>
      <DatePickerModal
        mode={'single'}
        visible={visible}
        onDismiss={onDismiss}
        date={date}
        onConfirm={onChangeFunc}
        saveLabel={'Opslaan'} // optional
        label={'Selecteer datum'} // optional
      />
      <View style={{ flexDirection: 'row' }}>
        <Title
          style={{
            marginBottom: 8,
            marginLeft: 6,
            marginRight: 8,
            fontSize: 16,
          }}
        >
          Datum:
        </Title>
        <Button
          onPress={() => setVisible(true)}
          mode={'outlined'}
          testID={'datepicker'}
          style={styles.button}
          icon={'calendar'}
        >
          {getDateString(date)}
        </Button>
      </View>
    </>
  ) : (
    <>
      <TimePickerModal
        visible={timeVisible}
        onDismiss={onDismissTime}
        onConfirm={onConfirm}
        hours={date.getHours()}
        minutes={date.getMinutes()}
        cancelLabel="Annuleren" // optional, default: 'Cancel'
        label={'Selecteer tijd'} // optional
      />
      <View style={{ flexDirection: 'row' }}>
        <Title
          style={{
            marginTop: 8,
            marginBottom: 6,
            marginLeft: 6,
            marginRight: 35,
            fontSize: 16,
          }}
        >
          Tijd:
        </Title>
        <Button
          onPress={() => setTimeVisible(true)}
          mode={'outlined'}
          style={styles.button}
          testID={'timepicker'}
          icon={
            'clock-time-' +
            hoursMap[date.getHours() + (date.getMinutes() > 30 ? 1 : 0)] +
            '-outline'
          }
        >
          {getTimeString(date)}
        </Button>
      </View>
    </>
  )
}

export default DateTimePicker
