import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getStatusBarHeight,
  getBottomSpace,
} from 'react-native-iphone-x-helper'
import { Platform } from 'react-native'

export default WrappedComponent => {
  class HOC extends Component {
    render() {
      const { height, width } = this.props
      const isLandscape = width > height

      let left = 0
      let right = 0
      let top = 0
      let bottom = 0

      const statusBarHeight = getStatusBarHeight() || 0
      const bottomHeight = getBottomSpace() || 0

      if (Platform.OS === 'ios') {
        if (isLandscape) {
          left = bottomHeight
          right = bottomHeight
        } else {
          top = statusBarHeight
          bottom = bottomHeight
        }
      }

      if (Platform.OS === 'android') {
        top = statusBarHeight
      }
      //70
      //56
      // if (Platform.OS === 'web') {
      //   if (isMedium) {
      //     bottom = 70
      //   } else {
      //     bottom = 56
      //   }
      // }

      const safeProperties = {
        left,
        right,
        top,
        bottom,
      }
      const safePadding = {
        paddingLeft: left,
        paddingRight: right,
        paddingTop: top,
        paddingBottom: bottom,
      }
      const safeMargin = {
        marginLeft: left,
        marginRight: right,
        marginTop: top,
        marginBottom: bottom,
      }

      return (
        <WrappedComponent
          {...this.props}
          safe={safeProperties}
          safePadding={safePadding}
          safeMargin={safeMargin}
        />
      )
    }
  }
  const mapStateToProps = state => ({
    height: state.widthAndHeight.height,
    width: state.widthAndHeight.width,
  })

  const con = connect(mapStateToProps)(HOC)

  if (WrappedComponent) {
    con.options = WrappedComponent.options
  }
  return con
}
