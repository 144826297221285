import React, { Component } from 'react'
import { ActivityIndicator, withTheme } from 'react-native-paper'
import actions from './actions'
import KeyboardAvoidingScrollView from '../components/KeyboardAvoidingScrollView'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import detailHOC from '../GeneralListFunctions/detailHOC'
import { connect } from 'react-redux'
import paramsAsPropsHOC from '../GeneralListFunctions/paramsAsPropsHOC'
import Container from '../components/Container'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Header from '../components/Header'

const fields = [
  {
    key: 'text',
    name: 'email',
    type: 'email',
    label: 'Email',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'timestamp',
    type: 'text',
    label: 'Tijd',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'smtp-id',
    type: 'text',
    label: 'SMTP ID',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'bounce_classification',
    type: 'text',
    label: 'Bouce reden',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: `event`,
    type: 'text',
    label: 'Event',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'ip',
    type: 'text',
    label: 'IP',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'sg_event_id',
    type: 'text',
    label: 'SG Event ID',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'sg_message_id',
    type: 'text',
    label: 'SG Message ID',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'response',
    type: 'text',
    label: 'Response',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'attempt',
    type: 'text',
    label: 'Attempt',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'reason',
    type: 'text',
    label: 'Reason',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'status',
    type: 'text',
    label: 'Status',
    // fieldProps: { },
  },
]
class EventDetail extends Component {
  render() {
    const { crud } = this.props
    const { loading } = crud

    // f
    let values = { ...crud.object } || {}

    let enhancedFields = fields
    if (values.id) {
      enhancedFields = fields.map((f) =>
        f.name === 'email'
          ? { ...f, fieldProps: { ...f.fieldProps, disabled: true } }
          : f
      )
    }

    return [
      <Container>
        <Header
          key="header"
          crud={crud}
        >
        </Header>
      </Container>,
      loading ? (
        <Container>
          <ActivityIndicator size={'small'} />
        </Container>
      ) : (
        <KeyboardAvoidingScrollView style={{ padding: 5 }} key="scroll">
          <Container>
            <FormContainer>
              <Fields
                fields={enhancedFields}
                values={values}
              />
            </FormContainer>
          </Container>
        </KeyboardAvoidingScrollView>
      ),
    ]
  }
}
const mapStateToProps = (state) => ({
  crud: state.event.crud,
})
export default safeAreaHOC(
  paramsAsPropsHOC(
    withTheme(
      connect(
        mapStateToProps
      )(
        detailHOC(EventDetail, mapStateToProps, actions, {
          optinEdit: false,
          enableDelete: true,
          reloadOnMount: false,
        })
      )
    )
  )
)
