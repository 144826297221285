import React, { Component } from 'react'
import ShimmerPlaceHolder from './ShimmerPlaceHolder'
import { styles } from './BigButton'

class BigButtonShimmer extends Component {
  render() {
    const { margin, height, style } = this.props
    return (
      <ShimmerPlaceHolder
        autoRun={true}
        style={[styles.button, { margin, height, maxHeight: height }, style]}
      />
    )
  }
}

BigButtonShimmer.defaultProps = {
  margin: 16,
  height: 200,
}

export default BigButtonShimmer
