import React, { Component } from 'react'
import { List } from 'react-native-paper'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'

class SafeListItem extends Component {
  render() {
    const { safeSettings, safePadding, style, ...rest } = this.props
    const { left, right } = safeSettings
    const { paddingLeft, paddingRight } = safePadding

    return (
      <List.Item
        {...rest}
        style={[
          {
            // backgroundColor: 'pink',
            paddingLeft: left ? paddingLeft + 8 : 0,
            paddingRight: right ? paddingRight + 8 : 0,
          },
          style,
        ]}
      />
    )
  }
}

SafeListItem.defaultProps = {
  safeSettings: {
    right: true,
    left: true,
  },
}

export default safeAreaHOC(SafeListItem)
