import React, { useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import Dropzone from 'react-dropzone'
import { Button, IconButton, ProgressBar } from 'react-native-paper'
import { fetch } from 'react-auth/lib/actions/authentication'
import { useDispatch } from 'react-redux'

const styles = StyleSheet.create({
  dropContainer: {
    height: 100,
    padding: 15,
    borderRadius: 5,
    borderStyle: 'dashed',
    borderWidth: 1,
    justifyContent: ' center',
    alignItems: 'center',
  },
  button: {
    maxWidth: 250,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#FFF',
  },
  error: {
    color: 'red',
  },
})

const MediaDropzone = ({ media, onChange }) => {
  const dispatch = useDispatch()

  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState(false)
  const [file, setFile] = useState(null)

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      setFile(file)
      onUpload(file)
    }
  }

  const onUpload = (file) => {
    setUploading(true)
    setError(false)

    const body = new FormData()
    body.append('file', file)

    dispatch(
      fetch({
        path: 'v1/media/upload',
        method: 'POST',
        type: 'json', //do not remove
        body,
        onSuccess: (response) => {
          setUploading(false)
          setFile(null)
          onChange(response)
        },
        onError: (error) => {
          console.log({ error })
          setUploading(false)
          setError(true)
          setFile(null)
        },
      })
    )
  }

  if (file && uploading) {
    return (
      <div
        style={{
          borderRadius: 10,
          overflow: 'hidden',
        }}
      >
        <ProgressBar indeterminate style={{ position: 'absolute' }} />
        <img
          src={URL.createObjectURL(file)}
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: 10,
            overflow: 'hidden',
          }}
          alt=""
        />
      </div>
    )
  }

  if (media) {
    return (
      <div style={{ position: 'relative' }}>
        <IconButton
          icon="close"
          onPress={() => onChange(undefined)}
          style={styles.closeButton}
          color="#000"
        />
        <img
          src={
            process.env.REACT_APP_STATIC_ASSETS_ENDPOINT +
            '/' +
            media.originalUrl
          }
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: 10,
            overflow: 'hidden',
          }}
          alt=""
        />
      </div>
    )
  }

  return (
    <Dropzone onDrop={(file) => onDrop(file)} testID={'dropzone'}>
      {({ getRootProps, getInputProps }) => {
        console.log(getInputProps())
        console.log(getRootProps())
        return (
          <section>
            <View style={styles.dropContainer} {...getRootProps()} testID={'dropzone'}>
              <input {...getInputProps()}/>
              {error && <Text style={styles.error}>Fout bij uploaden</Text>}
              <Button
                uppercase={false}
                style={styles.button}
                pointerEvents={'none'}
              >
                Voeg een afbeelding toe
              </Button>
            </View>
          </section>
        )
      }}
    </Dropzone>
  )
}

export default MediaDropzone
