import React, { Component } from 'react'
import {
  ActivityIndicator,
  Appbar,
  Button,
  Text,
  withTheme,
} from 'react-native-paper'
import actions from './actions'
import roleActions from './Role/actions'
import KeyboardAvoidingScrollView from '../components/KeyboardAvoidingScrollView'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import detailHOC from '../GeneralListFunctions/detailHOC'
import { connect } from 'react-redux'
import paramsAsPropsHOC from '../GeneralListFunctions/paramsAsPropsHOC'
import Container from '../components/Container'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Header from '../components/Header'
import RoleList from './Role/RoleList'
import { Hoverable } from 'react-native-web-hover'
import { push } from '../Navigation'
import * as routes from '../Routes.config'
import Addresses from '../Address/Addresses'
import Attributes from './Attributes/Attributes'
import { toPath } from 'lodash'

// const styles = StyleSheet.create({
//   nextFab: {
//     position: 'absolute',
//   },
// })
const fields = [
  {
    key: 'text',
    name: 'firstName',
    type: 'text',
    label: 'Voornaam',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'preposition',
    type: 'text',
    label: 'Tussenvoegsel',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'lastName',
    type: 'text',
    label: 'Achternaam',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'email',
    type: 'email',
    label: 'E-mail',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: `phone_numbers`,
    type: 'phoneNumber',
    label: 'Telefoonnummer',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'initials',
    type: 'text',
    label: 'Voorletters',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'function',
    type: 'text',
    label: 'Functie',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'language',
    type: 'options',
    options: [
      { title: 'Nederlands', description: '', value: 'nl' },
      { title: 'Engels', description: '', value: 'en' },
      { title: 'Duits', description: '', value: 'de' },
      { title: 'Frans', description: '', value: 'fr' },
    ],
    label: 'Taal',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'gender',
    type: 'options',
    options: [
      { title: 'Man', description: '', value: 'M' },
      { title: 'Vrouw', description: '', value: 'F' },
      { title: 'Anders', description: '', value: 'O' },
      { title: 'Geen', description: '', value: 'N' },
      { title: 'Onbekend', description: '', value: 'U' },
    ],
    label: 'Geslacht',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'organization',
    type: 'userOrganization',
    label: 'Organisatie',
    // fieldProps: { },
  },
]

const secondFields = [
  {
    key: 'text',
    name: 'status',
    type: 'options',
    options: [
      { title: 'Actief', description: '', value: 'ACTIVE' },
      {
        title: 'Meer informatie benodigd',
        description: '',
        value: 'MORE_INFO_REQUIRED',
      },
      { title: 'Inactief', description: '', value: 'INACTIVE' },
    ],
    label: 'Status',
    // fieldProps: { },
  },
]
class UserDetail extends Component {
  state = {
    selected: [],
    withInvite: false,
    changedAttributes: []
  }

  componentDidUpdate(prevProps) {
    console.log(this.props.role)
    if (prevProps.role !== this.props.role) {
      let cleanItems = []
      this.props.role.list.data.forEach(
        (it) =>
          (cleanItems = [...cleanItems, { name: it.name, value: it.name }])
      )
      console.log(cleanItems)
      this.setState({
        selected: cleanItems,
      })
    }
  }

  _submit = (withInvite) => {
    const { crud } = this.props
    const values = crud.object || {}
    const meta = {
      params: {
        organizationId: values.organization_id,
        embed: ['organization'],
      },
      changedAttributePaths: this.state.changedAttributes
    }

    if (!values.id) {
      meta.params.sendInvite = true
      meta.params.roles = this.state.selected.map((it) => it.value)
      meta.routeAddition = '/custom'
    }
    if (withInvite === true) {
      if (
        window.confirm('Weet je zeker dat je een uitnodiging wilt versturen?')
      ) {
        meta.params.sendInvite = true
      } else {
        return
      }
    }

    this.props.onSubmit({ meta })
  }

  _change = (name, value) => {
    if (name === 'organization') {
      this.props.onChange('organizationId', value && value.id)
    }
    if (name === 'phone_numbers') {
      this.props.onChange('phone_numbers', [
        {
          phone_number: value,
          type: 'mobile',
        },
      ])
      return
    }

    this.props.onChange(name, value)
  }

  _changedAttribute = (path, value) => {

    let attributes = this.props.crud.object?.attributes;

    let current = toPath(path);

    let attribute = attributes[Number(current[1])];

    let newAttributes = [...this.state.changedAttributes];

    if (value !== attribute.value && !this.state.changedAttributes.includes(path)) {
      newAttributes.push(path);
    }

    this.setState({
      changedAttributes: newAttributes,
    });

    this.props.onChange(path, value);
  }

  _changeRole = (ro) => {
    const exists = this.state.selected.some((it) => it.value === ro.value)
    console.log(
      exists,
      this.state.selected.filter((it) => it.value !== ro.value),
      ro
    )

    this.setState(
      {
        selected: exists
          ? this.state.selected.filter((it) => it.value !== ro.value)
          : [...this.state.selected, ro],
      },
      () => {
        if (
          this.props.crud &&
          this.props.crud.object &&
          this.props.crud.object.id
        ) {
          if (exists) {
            // TODO: Remove role from user if user is existing
            console.log('removing', ro.value)
            this.props.dispatch(roleActions.remove({ name: ro.value }))
          } else {
            // TODO: Add role to user, if user is existing
            this.props.dispatch(roleActions.create({ name: ro.value }))
          }
        }
      }
    )
  }

  render() {
    const { crud, componentId, orgList } = this.props
    const { loading } = crud

    // f
    let values = { ...crud.object } || {}
    values.organization = values.organization || {
      id: values.organization_id,
      name:
        orgList &&
        orgList.data &&
        orgList.data.map((org) =>
          values.organization_id === org.id ? `${org.name}` : ''
        ),
    }

    const name = [values.firstName, values.preposition, values.lastName]
      .filter((n) => n)
      .join(' ')

    let enhancedFields = fields
    if (values.id) {
      enhancedFields = fields.map((f) =>
        f.name === 'email'
          ? { ...f, fieldProps: { ...f.fieldProps, disabled: true } }
          : f
      )
    }

    const editableRoles =
      process.env.REACT_APP_EDITABLE_ROLES &&
      process.env.REACT_APP_EDITABLE_ROLES.split(',')
    const showRoles =
      editableRoles && editableRoles.some((element) => element !== 'user')

    console.log({ selected: this.state.selected, values })

    const enabledModules =
      process.env.REACT_APP_ENABLE_MODULES &&
      process.env.REACT_APP_ENABLE_MODULES.split(',')
    return [
      <Container>
        <Header
          key="header"
          componentId={componentId}
          crud={crud}
          onRemove={this.props.onRemove}
          onSubmit={this._submit}
          onSubmitAndInvite={() => this._submit(true)}
          inviteButtonDisabled={
            !(
              (this.state.selected?.length > 0 || enabledModules?.includes('inviteWithoutRoles')) &&
              values?.status?.toUpperCase() === 'ACTIVE' &&
              values?.organization?.status?.toUpperCase() === 'ACTIVE'
            )
          }
        >
          <Appbar.Content title={values.id ? `Bewerk ${name}` : 'Nieuw'} />
        </Header>
      </Container>,
      loading ? (
        <Container>
          <ActivityIndicator size={'small'} />
        </Container>
      ) : (
        <KeyboardAvoidingScrollView style={{ padding: 5 }} key="scroll">
          <Container>
            <FormContainer onSubmit={this._submit}>
              <Fields
                fields={enhancedFields}
                values={values}
                onChange={this._change}
                onSubmit={this._submit}
              />
              <Hoverable>
                {({ hovered }) => (
                  <Button
                    uppercase={false}
                    style={{ width: '100%' }}
                    onPress={() =>
                      push(componentId, {
                        component: {
                          name: routes.ORGANIZATION_DETAILS_SCREEN_ROUTE,
                          passProps: {
                            id: values.organizationId,
                          },
                        },
                      })
                    }
                  >
                    <Text
                      style={[
                        {
                          fontSize: 16,
                          padding: 16,
                        },
                        hovered && {
                          textDecorationLine: 'underline',
                          textDecorationStyle: 'solid',
                          textDecorationColor: '#000',
                          cursor: 'pointer',
                        },
                      ]}
                    >
                      {'Klik hier om naar de organisatie pagina te gaan'}
                    </Text>
                  </Button>
                )}
              </Hoverable>
              <Fields
                fields={secondFields}
                values={values}
                onChange={this._change}
                onSubmit={this._submit}
              />
              <Addresses addresses={crud?.object?.addresses || []} />
              <Attributes attributes={crud?.object?.attributes} onChange={this._changedAttribute} onSubmit={this._submit} />

              {showRoles && (
                <RoleList
                  selected={this.state.selected}
                  onChange={this._changeRole}
                />
              )}
            </FormContainer>
          </Container>
        </KeyboardAvoidingScrollView>
      ),
    ]
  }
}
const mapStateToProps = (state) => ({
  crud: state.user.crud,
  orgList: state.userOrganization.list,
  role: state.addUserRole,
})
export default safeAreaHOC(
  paramsAsPropsHOC(
    withTheme(
      connect(
        mapStateToProps
      )(
        detailHOC(UserDetail, mapStateToProps, actions, {
          optinEdit: false,
          enableDelete: true,
          reloadOnMount: false,
        })
      )
    )
  )
)
