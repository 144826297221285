import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { Menu, Button } from 'react-native-paper'
import actions from './actions'
import Navigation from '../Navigation'

import detailHOC from '../GeneralListFunctions/detailHOC'
import MailHeader from './MailHeader'
import Widget from './Widgets/Widget'
import { push } from '../Navigation'
import { MAILING_PREVIEW_ROUTE, DIRECT_SEND_ROUTE } from '../Routes.config'
import Container from '../components/Container'
import LanguageSwitcher from './LanguageSwitcher'
import { defaultLanguage, setTranslationValue } from '../helpers/language'
import { Animated } from 'react-native-web'
import SwipeableList from './SwipeableList'

const widgetTypes = [
  { key: 'text', title: 'Tekst' },
  { key: 'category', title: 'Categorie' },
  { key: 'product', title: 'Product' },
  { key: 'products', title: 'Producten' },
  { key: 'image', title: 'Afbeelding' },
  { key: 'video', title: 'Video' },
]

const supportedLanguages = process.env.REACT_APP_SUPPORTED_LANGUAGES
const languages = supportedLanguages ? supportedLanguages.split(',') : []

const styles = StyleSheet.create({
  root: { flex: 1, backgroundColor: '#f6f4f9' },
  textInput: { backgroundColor: 'transparent' },
  list: { flex: 1, maxHeight: 400 },
  item: {
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'hidden',
  },
  preview: { marginRight: 6 },
  footerContainer: {
    position: 'absolute',
    bottom: 16,
    right: 0,
    left: 0,
  },
  footerInner: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})

function array_move(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length
  }
  while (new_index < 0) {
    new_index += arr.length
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  const returnArray = arr.map((it, i) => {
    return {
      ...it,
      order: i,
    }
  })
  return returnArray // for testing purposes
}

class StepContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      language: defaultLanguage,
      hasNoSubject: [],
      theposition: 0,
      dragDropMode: false,
    }
    this.shakeAnimation = new Animated.Value(0)
  }

  componentDidUpdate() {
    // This piece of code removes the error notification when the subject is being adjusted.
    const mail = this.props.crud.object || {}
    if (
      Object.keys((mail && mail.subjectTranslations) || {}).filter((key) => {
        return mail && mail.subjectTranslations[key] !== ''
      }).length >
        languages.length - this.state.hasNoSubject.length &&
      this.state.hasNoSubject.length > 0
    ) {
      this.setState({
        hasNoSubject: [],
      })
    }
  }
  _openMenu = () => this.setState({ visible: true })
  _closeMenu = () => this.setState({ visible: false })

  _toggleDragDropMode = () =>
    this.setState({ dragDropMode: !this.state.dragDropMode })

  startShake = () => {
    Animated.sequence([
      Animated.timing(this.shakeAnimation, {
        toValue: 20,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(this.shakeAnimation, {
        toValue: -20,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(this.shakeAnimation, {
        toValue: 20,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(this.shakeAnimation, {
        toValue: -20,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(this.shakeAnimation, {
        toValue: 20,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(this.shakeAnimation, {
        toValue: 0,
        duration: 100,
        useNativeDriver: true,
      }),
    ]).start()
  }

  _preview = () => {
    const { componentId } = this.props
    const mail = this.props.crud.object || {}
    if (!(mail && mail.subjectTranslations)) {
      this.setState({
        hasNoSubject: languages,
      })
      this.startShake()
    } else {
      if (languages.some((lang) => !mail.subjectTranslations[lang])) {
        const notFound = languages
          .map((lang) => (!mail.subjectTranslations[lang] ? lang : null))
          .filter((f) => f)
        this.setState({
          hasNoSubject: notFound,
        })
        this.startShake()
      } else {
        this.setState({
          hasNoSubject: [],
        })
        push(componentId, {
          component: {
            name: MAILING_PREVIEW_ROUTE,
          },
        })
      }
    }
  }
  _directSend = () => {
    const { componentId } = this.props
    const mail = this.props.crud.object || {}
    if (!(mail && mail.subjectTranslations)) {
      console.log(' FIRST')
      this.setState({
        hasNoSubject: languages,
      })
      this.startShake()
    } else {
      if (languages.some((lang) => !mail.subjectTranslations[lang])) {
        const notFound = languages
          .map((lang) => (!mail.subjectTranslations[lang] ? lang : null))
          .filter((f) => f)
        this.setState({
          hasNoSubject: notFound,
        })
        this.startShake()
      } else {
        this._submit(mail)
        this.setState({
          hasNoSubject: [],
        })
        push(componentId, {
          component: {
            name: DIRECT_SEND_ROUTE,
            passProps: {
              id: mail.id,
            },
          },
        })
      }
    }
  }

  _deleteWidget = (widget) => () => {
    const mail = this.props.crud.object || {}
    const widgets = mail.widgets || []
    this.props.onChange(
      'widgets',
      widgets.filter((w) => w !== widget)
    )
  }
  _changeWidget = (widgetToChange) => (newWidget) => {
    const mail = this.props.crud.object || {}
    const widgets = mail.widgets || []

    this.props.onChange(
      'widgets',
      widgets.map((w) => (w === widgetToChange ? newWidget : w))
    )
  }
  _changeWithLanguage = (key) => (value) => {
    const mail = this.props.crud.object || {}

    // Enhance object with changes
    this.props.onSet(setTranslationValue(mail, key, value, this.state.language))
  }
  _addWidget = (type) => () => {
    const mail = this.props.crud.object || {}
    const widgets = mail.widgets || []
    let newWidget = {
      type: type,
      title: '',
      content: '',
    }
    console.log(newWidget.type)
    this._closeMenu()
    this.props.onChange('widgets', [...widgets, newWidget])
  }
  _renderItem = ({ item, index, move, moveEnd, isActive }) => {
    // :( DraggableFlatList on web
    if (item.type === 'footer') {
      return this._renderFooter()
    }
    return (
      <View style={styles.item}>
        <Widget
          item={item}
          isActive={isActive}
          onChange={this._changeWidget(item)}
          onDelete={this._deleteWidget(item)}
          move={move}
          moveEnd={moveEnd}
          language={this.state.language}
        />
      </View>
    )
  }

  _back = () => {
    Navigation.pop(this.props.componentId)
  }
  _keyExtractor = (item, index) =>
    `draggable-item-${item.id || ''}-${item.id || index}`

  _removeItem = () => {
    this.props.onRemove()
  }
  _switchLanguage = (language) => () => {
    this.setState({
      language,
    })
  }
  _submit = (item) => {
    this.props.onSubmit(item)
  }
  _moveEnd = (data) => {
    const array = this.props.crud.object.widgets
    if (!data.destination) {
      return
    }
    const newArray = array_move(
      array,
      data.source.index,
      data.destination.index
    )
    this.props.onChange('widgets', newArray)
  }

  render() {
    const { crud, componentId } = this.props
    const mail = crud.object || {}
    const sortedData = mail.widgets || []
    return (
      <Animated.View
        style={{
          flex: 1,
          backgroundColor: '#f6f4f9',
          transform: [{ translateX: this.shakeAnimation }],
        }}
        ref={'test'}
      >
        <MailHeader
          componentId={componentId}
          active={'content'}
          onRemove={this._removeItem}
          onSubmit={this._submit}
        />
        <LanguageSwitcher
          onChange={this._switchLanguage}
          value={this.state.language}
        />
        <SwipeableList
          data={sortedData}
          renderItem={this._renderItem}
          onMoveEnd={this._moveEnd}
          keyExtractor={this._keyExtractor}
          scrollPercent={5}
          style={styles.list}
          isActive={true}
          changeWidget={this._changeWidget}
          deleteWidget={this._deleteWidget}
          language={this.state.language}
          dragDropMode={this.state.dragDropMode}
          mail={mail}
          changeWithLanguage={this._changeWithLanguage}
          hasNoSubject={this.state.hasNoSubject}
          // contentContainerStyle={{
          //   height: Platform.OS === 'web' ? 'calc(100vh - 140px)' : undefined,
          // }}
          extraData={`${this.state.language}`}
        />
        <View style={styles.footerContainer} key={'footer'}>
          <Container>
            <View style={styles.footerInner}>
              <Button
                icon="hand"
                mode={this.state.dragDropMode ? 'contained' : 'outlined'}
                style={styles.preview}
                onPress={this._toggleDragDropMode}
                uppercase={false}
              >
                {this.state.dragDropMode
                  ? 'Stop met volgorde wijzigen'
                  : 'Volgorde wijzigen'}
              </Button>
              <Button
                icon="send"
                mode={'outlined'}
                style={styles.preview}
                onPress={this._directSend}
                uppercase={false}
              >
                Direct versturen
              </Button>
              <Button
                icon="magnify-plus-outline"
                mode={'outlined'}
                style={styles.preview}
                onPress={this._preview}
                uppercase={false}
              >
                Voorbeeld
              </Button>
              <Menu
                visible={this.state.visible}
                onDismiss={this._closeMenu}
                anchor={
                  <Button
                    mode={'contained'}
                    uppercase={false}
                    testID={'addWidgetButton'}
                    onPress={this._openMenu}
                  >
                    Widget toevoegen
                  </Button>
                }
              >
                {widgetTypes.map((widget) => (
                  <Menu.Item
                    key={widget.key}
                    testID={'widgetOption_' + widget.key}
                    onPress={this._addWidget(widget.key)}
                    title={widget.title}
                  />
                ))}
              </Menu>
            </View>
          </Container>
        </View>
      </Animated.View>
    )
  }
}

const mapStateToProps = (state) => ({
  crud: state.mail.crud,
})
export default detailHOC(StepContent, mapStateToProps, actions, {
  optinEdit: false,
  enableDelete: true,
  reloadOnMount: false,
  cacheFromList: false,
})
