import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  root: {
    maxWidth: 900,
    width: '100%',
    alignSelf: 'center',
  },
})

class Container extends Component {
  render() {
    return <View style={styles.root}>{this.props.children}</View>
  }
}

export default Container
