import React, { Component } from 'react'
import { View, StyleSheet, ScrollView, FlatList } from 'react-native'
import { connect } from 'react-redux'
import { withTheme, RadioButton, Checkbox, Appbar } from 'react-native-paper'
import { loadCategories } from '../../Home/actions'
import { getTranslation } from '../../language'
import AppbarHeader from '../../components/AppbarHeader'
import SafeAreaView from '../../components/SafeAreaView.web'
import BigButton from '../../components/BigButton'
import BigButtonShimmer from '../../components/BigButton.shimmer'

class L extends Component {
  state = {
    allCategories: [0],
    currentCategoryId: 0,
  }
  _getParams = (ignore, inputSearch) => {
    const { list } = this.props
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }
    return {
      ...list.params,
      order: 'name',
      search,
      name: search,
      'search[name]': search,
      'search[language]': 'nl',
    }
  }
  _goBack = () => {
    const newState = this.state.allCategories.filter(
      (it) => it !== this.state.currentCategoryId
    )
    const gotoId = newState[newState.length - 1]
    this.setState({
      currentCategoryId: gotoId,
      allCategories: newState,
    })
  }
  _press = (item) => () => {
    this.setState({
      currentCategoryId: item.id,
      allCategories: [...this.state.allCategories, item.id],
    })
  }
  _select = (item) => () => {
    this.props.onSelect(item)
  }

  _renderItem = ({ item }) => {
    const { currentCategoryId } = this.state
    const { downTree } = this.props.list
    const categoriesBeneath = downTree[item.id]
    const isSelected = this.props.isSelected(item)
    let SelectionElement

    if (this.props.singleSelect) {
      SelectionElement = RadioButton.Android
    } else if (this.props.multiSelect) {
      SelectionElement = Checkbox.Android
    }

    let inner =
      currentCategoryId === item.id ? (
        <></>
      ) : (
        <SafeAreaView
          key={`button_${item.id}`}
          top={false}
          bottom={false}
          enableFlex={false}
        >
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.leftContainer}>
              <SelectionElement
                status={isSelected ? 'checked' : 'unchecked'}
                onPress={this._select(item)}
                testID={'categoryItem'}
              />
            </View>
            <BigButton
              label={getTranslation(item, 'name')}
              onPress={
                categoriesBeneath.length === 0
                  ? this._select(item)
                  : this._press(item)
              }
              backgroundSizing={item.parentCategoryId === 0 ? 'cover' : 'small'}
              labelColor={item.parentCategoryId === 0 ? '#01136B' : '#000'}
            />
          </View>
        </SafeAreaView>
      )
    return <View key={`container_${item.id}`}>{inner}</View>
  }
  _renderShimmer = ({ item }) => {
    return (
      <View key={`${item}`}>
        <SafeAreaView top={false} bottom={false} enableFlex={false}>
          <BigButtonShimmer />
        </SafeAreaView>
      </View>
    )
  }
  _renderFooter = () => {
    return <View style={styles.footer} />
  }
  render() {
    const { width } = this.props
    const { loading, categoryById, downTree } = this.props.list

    const { currentCategoryId } = this.state
    const categoriesBeneath = downTree[currentCategoryId]
    return [
      <AppbarHeader>
        {currentCategoryId > 0 ? (
          <Appbar.BackAction onPress={this._goBack} />
        ) : null}
        <Appbar.Content />
        <Appbar.Action icon={'close'} onPress={this.props.onClose} testID={'closeButton'}/>
      </AppbarHeader>,
      <ScrollView style={{ flex: 1 }}>
        {loading ? (
          <FlatList
            // ref={el => (this.flatListRef = el)}
            key={`list_1_${width}`}
            data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            renderItem={this._renderShimmer}
            numColumns={1}
            style={{ flex: 1 }}
            contentContainerStyle={{ flexGrow: 1 }}
            contentInsetAdjustmentBehavior={'never'}
            keyExtractor={(item, index) => `${item.id || index}`}
          />
        ) : (
          <FlatList
            ref={(el) => (this.flatListRef = el)}
            keyExtractor={(item, index) => `${item.id || index}`}
            key={`list_2_${width}`}
            keyboardShouldPersistTaps={'handled'}
            keyboardDismissMode={'on-drag'}
            onScroll={this._handleScroll}
            ListFooterComponent={this._renderFooter}
            renderItem={this._renderItem}
            data={
              categoryById &&
              Object.entries(categoryById).length > 0 &&
              (categoriesBeneath && categoriesBeneath.length > 0
                ? categoriesBeneath.map((id) => {
                    return categoryById[id]
                  })
                : currentCategoryId !== 0
                ? [currentCategoryId].map((id) => {
                    return categoryById[id]
                  })
                : null)
            }
            initialNumToRender={
              categoriesBeneath.length > 0 ? categoriesBeneath.length : 10
            }
            numColumns={1}
            style={{ flex: 1 }}
            contentContainerStyle={{ flexGrow: 1 }}
            contentInsetAdjustmentBehavior={'never'}
          />
        )}
      </ScrollView>,
    ]
  }
}

// eslint-disable-next-line
const styles = StyleSheet.create({
  leftContainer: {
    marginTop: 23,
  },
})

L.propTypes = {}
L.defaultProps = {}

const mapStateToProps = (state) => ({
  list: state.category.categoryData,
})

export default connect(mapStateToProps, { loadCategories })(withTheme(L))
