import React, { Component } from 'react'
import { View, TextInput as TextInputRaw, StyleSheet } from 'react-native'
import { TextInput, Button, Menu } from 'react-native-paper'
import {
  setTranslationValue,
  getTranslationValue,
} from '../../helpers/language'
import MediaDropzone from '../../components/MediaDropzone'

const styles = StyleSheet.create({
  root: { flex: 1, padding: 16 },
  textInput: {
    flex: 1,
    backgroundColor: '#F6F4F9',
    padding: 14,
    fontFamily: 'Montserrat',
    borderRadius: 5,
  },
  textInputNormal: {
    backgroundColor: 'transparent',
    marginBottom: 8,
  },
})

class TextWidget extends Component {
  state = {
    height: 100,
    visible: false,
  }
  _change = (key) => (value) => {
    this.setState({
      visible: false,
    })

    const item = this.props.item
    setTranslationValue(item, key, value, this.props.language)

    this.props.onChange({
      ...item,
      ...setTranslationValue(item, key, value, this.props.language),
    })
  }
  _changeImage = (key) => (value) => {
    const { item, language } = this.props
    const image = item.image || {}

    this.props.onChange({
      ...item,
      image: {
        ...image,
        ...setTranslationValue(image, key, value, language),
      },
    })
  }

  render() {
    const { item, language } = this.props
    const image = item.image || {}
    return (
      <View style={styles.root}>
        <TextInput
          mode={'flat'}
          style={[styles.textInputNormal]}
          value={getTranslationValue(item, 'title', language)}
          onChangeText={this._change('title')}
          testID={'titleField'}
          label="Titel"
        />
        <View
          style={[
            item.mediaPosition === 'above' && {
              flexDirection: 'column-reverse',
            },
          ]}
        >
          <View>
            <TextInputRaw
              style={[
                styles.textInput,
                { height: this.state.height, marginTop: 16 },
              ]}
              multiline={true}
              value={getTranslationValue(item, 'content', language)}
              onChangeText={this._change('content')}
              blurOnSubmit={false}
              testID={'textField'}
              numberOfLines={10}
              placeholder="Vul hier uw tekst in"
            />
          </View>

          <View style={{ marginTop: 24 }}>
            {image ? (
              <Menu
                visible={this.state.visible}
                onDismiss={() =>
                  this.setState({
                    visible: false,
                  })
                }
                anchor={
                  <Button
                    uppercase={false}
                    onPress={() => {
                      this.setState({
                        visible: true,
                      })
                    }}
                  >
                    Wijzig positie afbeelding
                  </Button>
                }
              >
                <Menu.Item
                  onPress={() => this._change('mediaPosition')('above')}
                  title="Boven"
                />
                <Menu.Item
                  onPress={() => this._change('mediaPosition')('below')}
                  title="Onder"
                />
              </Menu>
            ) : null}

            <MediaDropzone
              media={getTranslationValue(image, 'media', language)}
              onChange={this._changeImage('media')}
            />
          </View>
        </View>
      </View>
    )
  }
}

export default TextWidget
