export const actionCreator = type => (payload, meta, error) => ({
  type,
  payload,
  meta,
  error: error || (payload && payload instanceof Error),
})

export const updatableListActions = namespace => {
  const RELOAD_LIST = `${namespace}_RELOAD_LIST`

  const LOAD_LIST = `${namespace}_LOAD_LIST`
  const LOADED_LIST = `${namespace}_LOADED_LIST`

  const LOAD_MORE_LIST = `${namespace}_LOAD_MORE_LIST`
  const LOADED_MORE_LIST = `${namespace}_LOADED_MORE_LIST`

  const LOAD = `${namespace}_LOAD`
  const LOADED = `${namespace}_LOADED`

  const CREATE = `${namespace}_CREATE`
  const CREATED = `${namespace}_CREATED`

  const UPDATE = `${namespace}_UPDATE`
  const UPDATED = `${namespace}_UPDATED`

  const REMOVE = `${namespace}_REMOVE`
  const REMOVED = `${namespace}_REMOVED`

  const SET_DATA = `${namespace}_SET_DATA`
  // const SET_LIST_PARAMS = `${SET_LIST_PARAMS}_SET_DATA`

  return {
    SET_DATA,
    setData: actionCreator(SET_DATA),
    RELOAD_LIST,
    reloadList: actionCreator(RELOAD_LIST),
    // SET_LIST_PARAMS,
    // setListParams: actionCreator(SET_LIST_PARAMS),
    LOAD_LIST,
    loadList: actionCreator(LOAD_LIST),
    LOADED_LIST,
    loadedList: actionCreator(LOADED_LIST),
    LOAD_MORE_LIST,
    loadMoreList: actionCreator(LOAD_MORE_LIST),
    LOADED_MORE_LIST,
    loadedMoreList: actionCreator(LOADED_MORE_LIST),
    LOAD,
    load: actionCreator(LOAD),
    LOADED,
    loaded: actionCreator(LOADED),
    CREATE,
    create: actionCreator(CREATE),
    CREATED,
    created: actionCreator(CREATED),
    UPDATE,
    update: actionCreator(UPDATE),
    UPDATED,
    updated: actionCreator(UPDATED),
    REMOVE,
    remove: actionCreator(REMOVE),
    REMOVED,
    removed: actionCreator(REMOVED),
  }
}
