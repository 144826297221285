import React, { Component } from 'react'
import { View } from 'react-native'
import { TextInput, Text, Title } from 'react-native-paper'

import CheckboxWithLabel from './CheckboxWithLabel'
import MailDesignSingleSelect from '../Mail/Design/SingleSelect'
import MailCampaignSingleSelect from '../Mail/Campaign/SingleSelect'
import RepeatMail from '../Mail/RepeatMailField'
import OrganizationGroups from '../Mail/OrganizationGroup/MultiSelect'
import MailProducts from '../Mail/Product/MultiSelect'
import MailProduct from '../Mail/Product/SingleSelect'
import Organizations from '../OrganizationGroup/Organization/MultiSelect'

import UserOrganizationSingleSelect from '../User/Organization/SingleSelect'
import TextInputMultiLanguage from './TextInputMultilanguage'
import Options from './Options'
import { fromTimestamp, getFriendlyDate } from '../dayjs'

export const isTextInput = (type) =>
  type === 'text' ||
  type === 'password' ||
  type === 'email' ||
  type === 'phoneNumber' ||
  type === 'number'

class Field extends Component {
  _change = (name) => (value) => {
    this.props.onChange(name, value)
  }
  _changeMultiSelect = (name) => (selected) => {
    const value = this.props.value || []

    const exist = value.some((v) => v.id === selected.id)
    if (exist) {
      this.props.onChange(
        name,
        value.filter((v) => v.id !== selected.id)
      )
    } else {
      this.props.onChange(name, [...value, selected])
    }
  }

  render() {
    const { name, type, value, label, fieldProps, error, options } = this.props
    let fieldComponent = <Text>{name} field is not implemented</Text>

    if (isTextInput(type)) {
      let resolvedValue = value
      let extraFieldProps = {}
      if (type === 'email') {
        extraFieldProps = {
          keyboardType: 'email-address',
          autoCapitalize: 'none',
          textContentType: 'username',
        }
      }
      if (type === 'password') {
        extraFieldProps = {
          secureTextEntry: true,
          textContentType: 'password',
        }
      }
      if (type === 'phoneNumber') {
        console.log(value)
        resolvedValue = value && value.length > 0 ? value[0].phone_number : ''
      }

      if (type === 'number') {
        extraFieldProps = {
          keyboardType: 'numeric'
        }
      }

      fieldComponent = (
        <TextInput
          mode={'flat'}
          label={label}
          type={type}
          {...extraFieldProps}
          {...fieldProps}
          style={{
            backgroundColor: '#fff',
            ...((fieldProps && fieldProps.style) || {}),
          }}
          value={resolvedValue || ''}
          onChangeText={this._change(name)}
        />
      )
    }

    if (type === 'label') {
      const dateObj = getFriendlyDate(fromTimestamp(value))
      if (value === 0 || !value) {
        fieldComponent = null
      } else {
        fieldComponent = (
          <Title
            style={{
              fontSize: 16,
              ...((fieldProps && fieldProps.style) || {}),
            }}
            key={fieldProps.testID}
            testid={fieldProps.testID}
          >
            {label}:{' '}
            <Title
              style={{
                fontSize: 16,
                ...((fieldProps && fieldProps.style) || {}),
                textDecorationLine: 'underline',
              }}
              key={fieldProps.testID}
              testid={fieldProps.testID}
            >
              {dateObj.weekday} {dateObj.description} om {dateObj.time}
            </Title>
          </Title>
        )
      }
    }

    if (type === 'languageText') {
      fieldComponent = <TextInputMultiLanguage />
    }

    if (type === 'checkbox') {
      fieldComponent = (
        <CheckboxWithLabel
          label={label}
          {...fieldProps}
          value={value}
          onChange={this._change(name)}
        />
      )
    }

    if (type === 'options') {
      fieldComponent = (
        <Options
          label={label}
          options={options}
          {...fieldProps}
          value={value}
          onChange={this._change(name, type)}
        />
      )
    }

    if (type === 'mailDesign') {
      fieldComponent = (
        <MailDesignSingleSelect
          label={label}
          {...fieldProps}
          value={value}
          onChange={this._change(name)}
        />
      )
    }
    if (type === 'mailCampaign') {
      fieldComponent = (
        <MailCampaignSingleSelect
          label={label}
          {...fieldProps}
          value={value}
          onChange={this._change(name)}
        />
      )
    }
    if (type === 'repeatMail') {
      fieldComponent = (
        <RepeatMail
          label={label}
          {...fieldProps}
          value={value}
          onChange={this._change(name)}
        />
      )
    }

    if (type === 'organizationGroups') {
      fieldComponent = (
        <OrganizationGroups
          label={label}
          {...fieldProps}
          value={value}
          onSelect={this._changeMultiSelect(name)}
        />
      )
    }

    if (type === 'organizations') {
      fieldComponent = (
        <Organizations
          label={label}
          {...fieldProps}
          value={value}
          onSelect={this._changeMultiSelect(name)}
        />
      )
    }

    if (type === 'mailProducts') {
      fieldComponent = (
        <MailProducts
          label={label}
          {...fieldProps}
          value={value}
          onSelect={this._changeMultiSelect(name)}
        />
      )
    }
    if (type === 'mailProduct') {
      fieldComponent = (
        <MailProduct
          label={label}
          {...fieldProps}
          value={value}
          onSelect={this._change(name)}
        />
      )
    }

    if (type === 'userOrganization') {
      fieldComponent = (
        <UserOrganizationSingleSelect
          label={label}
          {...fieldProps}
          value={value}
          onSelect={this._change(name)}
        />
      )
    }

    return [
      fieldComponent,
      error && (
        <View style={{}}>
          <Text style={{ color: 'red', fontSize: 12, paddingLeft: 16 }}>
            {error.message}
          </Text>
        </View>
      ),
    ]
  }
}
export default Field
