import { actionCreator as ac } from '../helpers/actionCreator'

const ns = type => `CATEGORY_${type}`

export const LOAD_LIST = ns('LOAD_LIST')
export const LOADED_LIST = ns('LOADED_LIST')
export const loadList = ac(LOAD_LIST)
export const loadedList = ac(LOADED_LIST)

export const LOAD_MORE_LIST = ns('LOAD_MORE_LIST')
export const LOADED_MORE_LIST = ns('LOADED_MORE_LIST')
export const loadMoreList = ac(LOAD_MORE_LIST)
export const loadedMoreList = ac(LOADED_MORE_LIST)

export const LOAD_CATEGORIES = ns('LOAD_CATEGORIES')
export const loadCategories = ac(LOAD_CATEGORIES)

export const LOADED_CATEGORIES = ns('LOADED_CATEGORIES')
export const loadedCategories = ac(LOADED_CATEGORIES)
