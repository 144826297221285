import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { withRouter, Router, Switch, Route } from 'react-router-dom'
import { history } from './Navigation.web'
import { allRoutes } from './Routes.config'
import TabMenu from './TabMenu'

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  heightFix: {
    flex: 1,
  },
  masterDetail: {
    flexDirection: 'row',
    flex: 1,
  },
  contentLeft: {
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#fff',
  },
  smallContent: {
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    overflow: 'hidden',
  },
})

const renderRoutes = (routes, smallScreen = false) =>
  routes.map(lr => (
    <Route
      key={lr.path}
      path={lr.pathOnWeb || lr.path}
      exact={smallScreen ? lr.exactOnSmallScreen || lr.exact : lr.exact}
      component={lr.component}
    />
  ))

class AppRouter extends Component {
  render() {
    const { width, height, isMedium, isLarge } = this.props
    const tabProps = {
      isMedium,
      isLarge,
      width,
      height,
    }
    return (
      <Router history={history}>
        <View style={styles.root}>
          <View style={styles.content}>
            <ScrollToTop>
              <Switch>{renderRoutes(allRoutes, true)}</Switch>
            </ScrollToTop>
          </View>

          <TabMenu {...tabProps} />
        </View>
      </Router>
    )
  }
}

export default AppRouter

class ScrollToTopDummy extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }
  render() {
    return this.props.children
  }
}
const ScrollToTop = withRouter(ScrollToTopDummy)
