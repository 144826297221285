import React, { useState } from 'react'
import { Title, Button, Portal } from 'react-native-paper'
import ClosableModal from './ClosableModal'
import PT from 'prop-types'
import { View } from 'react-native'
import Fields from '../Form/Fields'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import { set } from 'lodash'
import FormContainer from '../Form/FormContainer'
import Field from '../Form/Field'




const fields = [
  {
    key: 'text',
    name: 'key',
    type: 'text',
    label: 'Sleutel',
    // fieldProps: { },
  },
  {
    key: 'text',
    name: 'type',
    type: 'options',
    options: [
      { title: 'String', description: '', value: 'string' },
      { title: 'Number', description: '', value: 'integer' },
      { title: 'Boolean', description: '', value: 'boolean' },
      { title: 'Decimaal', description: '', value: 'decimal' },
    ],
    label: 'Type',

  },
]

function SettingsModel({ modalVisible, setModalVisible, onSubmit, onChange, determineType }) {

  const [values, setValues] = useState({ type: 'string' });

  let enhancedFields = fields

  const _onChange = (name, value) => {
    const newValues = { ...values };

    set(newValues, name, value);

    setValues(newValues)

    if (onChange) {
      onChange(name, value)
    }
  }

  const _onSubmit = () => {
    onSubmit(values);
  }

  return (

    <ClosableModal
      isVisible={modalVisible}
      onRequestClose={setModalVisible}
      hideHeader={true}
      showClose={true}
    >
      <Portal.Host>
        <FormContainer>
          <View style={{ padding: 16, zIndex: 100 }}>
            <View style={{ flexDirection: 'row', marginBottom: 8 }}>
              <Title style={{ flex: 2 }}>Instellingen</Title>
              <View>
                <Button mode='contained' icon='check' onPress={_onSubmit}>Opslaan</Button>
              </View>
            </View>

            <Fields fields={enhancedFields} values={values} onChange={_onChange} />

            <Field type={determineType(values.type, values.value)} label='Waarde' name='value' value={values.value} onChange={_onChange} />

          </View>
        </FormContainer>
      </Portal.Host>

    </ClosableModal>

  )
}

SettingsModel.propTypes = {
  modalVisible: PT.bool.isRequired
};

export default safeAreaHOC(SettingsModel)
