import React, { Component } from 'react'
import actions from './actions'
import { connect } from 'react-redux'
// import { StyleSheet } from 'react-native'
import { Menu, List } from 'react-native-paper'
import { Hoverable } from 'react-native-web-hover'

class SingleSelect extends Component {
  state = {
    visible: false,
  }

  componentWillMount() {
    // TODO maybe later only on open if more designs
    if (!this.props.list.loadedList) {
      this.props.loadList()
    }
  }

  _open = () => {
    this.setState({ visible: true })
  }
  _close = () => {
    this.setState({ visible: false })
  }
  _change = (item) => () => {
    if (item.id === 'deselect') {
      this.props.onChange(undefined)
    } else {
      this.props.onChange(item)
    }
    this._close()
  }

  render() {
    const { value, list, label, description } = this.props
    return (
      <Menu
        visible={this.state.visible}
        onDismiss={this._close}
        anchor={
          <Hoverable key={`campaign_single_select_${Math.random()}`}>
            {({ hovered }) => (
              <List.Item
                title={
                  value ? value.name : `Selecteer een ${label.toLowerCase()}`
                }
                style={
                  hovered ? { cursor: 'pointer' } : { backgroundColor: '#FFF' }
                }
                testID={this.props.testID}
                description={description}
                onPress={this._open}
                right={(iProps) => (
                  <List.Icon {...iProps} icon={'chevron-down'} />
                )}
              />
            )}
          </Hoverable>
        }
      >
        {[
          { id: 'deselect', name: `Geen ${label.toLowerCase()}` },
          ...(list.data || []),
        ].map((item) => {
          return (
            <Menu.Item
              key={item.id}
              testID={'campaignOption'}
              onPress={this._change(item)}
              title={item.name}
            />
          )
        })}
      </Menu>
    )
  }
}

const mapStateToProps = (state) => ({
  list: state.mailCampaign.list,
})

export default connect(mapStateToProps, actions)(SingleSelect)
