import React, { Component } from 'react'
import { connect } from 'react-redux'
import { update } from './actions'

export const mediumWidth = 700
export const largeWidth = 1100

export default WrappedComponent => {
  class HOC extends Component {
    render() {
      const { width, height } = this.props
      const isMedium = width >= mediumWidth
      const isLarge = width >= largeWidth
      return (
        <WrappedComponent
          {...this.props}
          width={width}
          height={height}
          isMedium={isMedium}
          isLarge={isLarge}
        />
      )
    }
  }
  const mapStateToProps = state => ({
    ...state.widthAndHeight,
  })

  const con = connect(
    mapStateToProps,
    { update }
  )(HOC)

  if (WrappedComponent) {
    con.options = WrappedComponent.options
  }
  return con
}
