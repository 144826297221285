import * as a from './actions'

const initialState = {
  data: {
    selectedLanguage: 'nl_nl', // language of app
  },
  loaded: false,
  error: false,
  loading: false,
}
const settings = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case a.RESTORED:
    case a.SILENT_RESTORED: {
      return error
        ? {
            ...state,
            error: true,
            loading: false,
            loaded: true,
          }
        : {
            ...state,
            data: { ...state.data, ...payload },
            error: false,
            loading: false,
            loaded: true,
          }
    }

    case a.RESTORE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    }

    default:
      return state
  }
}

export default settings
