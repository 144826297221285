import React, { useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Appbar,
  DataTable,
  List as PaperList,
  Text,
  TextInput,
  Title,
  withTheme,
} from 'react-native-paper'
import actions from './actions'
import KeyboardAvoidingScrollView from '../components/KeyboardAvoidingScrollView'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import detailHOC from '../GeneralListFunctions/detailHOC'
import { connect, useSelector } from 'react-redux'
import paramsAsPropsHOC from '../GeneralListFunctions/paramsAsPropsHOC'
import Container from '../components/Container'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Header from '../components/Header'
import { Dimensions, StyleSheet, View } from 'react-native'
import GeneralList from '../GeneralListFunctions/GeneralList'
import { useActionsAsFunctions } from '../Mail/ManageRegistrationsScreen'
import priceListActions from './PriceList/actions'
import Addresses from '../Address/Addresses'
import TableRowWithTwoColumns from '../components/TableRowWithTwoColumns'

const styles = StyleSheet.create({
  emptyTitle: { fontSize: 16 },
  emptyContainer: {
    flex: 1,

    // justifyContent: 'center',
    // alignItems: 'center',
    padding: 16,
    paddingTop: 0,
    paddingBottom: 56,
  },
})
const fields = [
  {
    key: 'text',
    name: 'name',
    type: 'text',
    label: 'Organisatienaam',
    fieldProps: {},
  },
  {
    key: 'text',
    name: 'email',
    type: 'text',
    label: 'E-mailadres',
    fieldProps: {},
  },
  {
    key: 'organizationGroups',
    name: 'organizationGroups',
    type: 'organizationGroups',
    label: 'Ontvangers',
    // fieldProps: { description: 'Selecteer een design' },
  },
  {
    key: 'text',
    name: 'status',
    type: 'options',
    options: [
      { title: 'Actief', description: '', value: 'active' },
      { title: 'Inactief', description: '', value: 'inactive' },
    ],
    label: 'Status',
  },
  {
    key: 'managedByOrganizations',
    name: 'managedByOrganizations',
    type: 'organizations',
    label: 'Managing organisaties',
    fieldProps: {
      customDescription: 'Selecteer managing organisaties',
      customDescriptionFilled: 'Managing organisaties',
    },
  },
]

type OrganizationDetailScreenPropsType = {
  onSubmit: ({
    meta,
  }: {
    meta: { params: { organizationId: number; embed: string[] } }
  }) => any
  onChange: (name: string, value: any) => any
  componentId: number
  onRemove: (id: number) => any
  id: string
}

function OrganizationDetailsScreen({
  onSubmit,
  onChange,
  componentId,
  onRemove,
  id,
}: OrganizationDetailScreenPropsType) {
  const crud = useSelector((state: any) => state.organization?.crud)

  const priceListList = useSelector(
    (state: any) => state.organizationPriceList.list
  )
  const actionsAsFunctions = useActionsAsFunctions(priceListActions)
  console.log({ actionsAsFunctions })
  const getParams = () => {
    return {
      organizationId: id,
      limit: 10000,
    }
  }
  const typeArr = ['farmer', 'transporter', 'contractor', undefined]

  // const dispatch = useDispatch()

  const getOrganizations = useSelector(
    (state: any) => state.organization?.crud.object?.managedByOrganizations
  )

  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    setOrganizations(getOrganizations)
  }, [getOrganizations])

  console.log(organizations)

  const submit = () => {
    const values = crud.object || {}
    const meta = {
      params: {
        organizationId: values.organization_id,
        embed: ['organization'],
      },
      updateManagingOrganizations: Array.isArray(
        values?.managedByOrganizations
      ),
    }
    if (!typeArr.some((type) => type === values?.settings?.type)) {
      if (
        window.confirm(
          'Weet je zeker dat je deze organisatie dit type wilt geven? Deze is niet bij ons bekend.'
        )
      ) {
        onSubmit({ meta })
      } else {
        return
      }
    } else {
      onSubmit({ meta })
    }
  }
  // only for fixing organization id
  const change = (name: string, value: any) => {
    console.log({ name, value })
    if (name === 'organization') {
      onChange('organizationId', value && value.id)
      return
    }
    onChange(name, value)
  }
  let values = { ...crud.object } || {}
  values.organization = values.organization || { id: values.organization_id }

  const name = [values.firstName, values.preposition, values.lastName]
    .filter((n) => n)
    .join(' ')

  let enhancedFields = fields
  if (values.id) {
    // @ts-ignore
    enhancedFields = fields.filter((f) => {
      const enabledModules =
        (process.env.REACT_APP_ENABLE_MODULES &&
          process.env.REACT_APP_ENABLE_MODULES.split(',')) ||
        []
      console.log({ enabledModules })
      if (
        !enabledModules?.some((mod) => mod === 'organization-group') &&
        f.name === 'organizationGroups'
      ) {
        return false
      } else if (
        !enabledModules?.some((mod) => mod === 'managing') &&
        f.name === 'managedByOrganizations'
      ) {
        return false
      }
      return true
    })
  } else {
    // @ts-ignore
    enhancedFields = fields.filter((f) => {
      const enabledModules =
        (process.env.REACT_APP_ENABLE_MODULES &&
          process.env.REACT_APP_ENABLE_MODULES.split(',')) ||
        []
      console.log({ enabledModules })
      if (
        !enabledModules?.some((mod) => mod === 'organization-group') &&
        f.name === 'organizationGroups'
      ) {
        return false
      } else if (
        !enabledModules?.some((mod) => mod === 'managing') &&
        f.name === 'managedByOrganizations'
      ) {
        return false
      } else if (
        !enabledModules?.some((mod) => mod === 'managing') &&
        f.name === 'settings'
      ) {
        return false
      }
      return true
    })
  }

  const enabledModules =
    (process.env.REACT_APP_ENABLE_MODULES &&
      process.env.REACT_APP_ENABLE_MODULES.split(',')) ||
    []
  const renderItem = ({ item }: { item: { id: number; name: string } }) => {
    return (
      <Container key={`${item.id}`}>
        <PaperList.Item title={item.name} />
      </Container>
    )
  }
  const renderAboveHeader = () => {
    return (
      <>
        <Title style={{}}>Prijslijsten</Title>
      </>
    )
  }

  const renderEmptyState = () => {
    return (
      <View
        key="empty"
        style={[
          styles.emptyContainer,
          { minHeight: Dimensions.get('window').height - 200 },
        ]}
      >
        <Text style={styles.emptyTitle}>Geen prijslijsten gekoppeld</Text>
      </View>
    )
  }


  return [
    <Container>
      {/* @ts-ignore*/}
      <Header
        key="header"
        componentId={componentId}
        crud={crud}
        onRemove={onRemove}
        onSubmit={submit}
      >
        <Appbar.Content title={values.id ? `Bewerk ${name}` : 'Nieuw'} />
      </Header>
    </Container>,
    crud.loading ? (
      <Container>
        <ActivityIndicator size={'small'} />
      </Container>
    ) : (
      <KeyboardAvoidingScrollView style={{ padding: 5 }} key="scroll">
        <Container>
          <View>
            <FormContainer onSubmit={submit}>
              {/* @ts-ignore*/}
              <Fields
                fields={enhancedFields}
                values={values}
                onChange={change}
                onSubmit={submit}
              />
            </FormContainer>
          </View>
          {enabledModules?.some((mod) => mod === 'price-list') && (
            <>
              <GeneralList
                reloadList={actionsAsFunctions.reloadList}
                renderAboveHeader={renderAboveHeader}
                loadList={actionsAsFunctions.loadList}
                loadMoreList={actionsAsFunctions.loadMoreList}
                list={priceListList}
                componentId={componentId}
                renderItem={renderItem}
                getParams={getParams}
                renderEmptyState={renderEmptyState}
                noSearch
              />
            </>
          )}

          <Addresses addresses={crud.object?.addresses || []} />

          {enabledModules?.some((mod) => mod === 'settings') && (
            <>
              <Title>Settings</Title>
              <View>
                <TextInput
                  label={'Type bedrijf'}
                  value={crud?.object?.settings?.type}
                  onChangeText={(value) => onChange('settings.type', value)}
                />
              </View>
              <DataTable>
                <DataTable.Header>
                  <DataTable.Title>Key</DataTable.Title>
                  <DataTable.Title>Value</DataTable.Title>
                </DataTable.Header>
                {Object.keys(crud?.object?.settings || {}).map((key) => {
                  return (
                    <TableRowWithTwoColumns first={key} second={crud?.object?.settings[key]} />
                  )
                })}
              </DataTable>
            </>
          )}
        </Container>
      </KeyboardAvoidingScrollView>
    ),
  ]
}
const mapStateToProps = (state: any) => ({
  crud: state.organization.crud,
})
export default safeAreaHOC(
  paramsAsPropsHOC(
    withTheme(
      connect(
        mapStateToProps
        // { loadGroupList }
      )(
        detailHOC(OrganizationDetailsScreen, mapStateToProps, actions, {
          optinEdit: false,
          enableDelete: true,
          reloadOnMount: true,
          cacheFromList: false,
        })
      )
    )
  )
)
