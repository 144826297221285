import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'

import cpf from 'dayjs/plugin/customParseFormat'
import lf from 'dayjs/plugin/localizedFormat'

dayjs.extend(cpf)
dayjs.extend(lf)

function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const getFriendlyDate = (date) => {
  var now = dayjs()
  dayjs.locale('nl')
  const differenceInWeeks = date.diff(now, 'week')
  if (differenceInWeeks < 1 && differenceInWeeks > -1) {
    if (now.subtract(1, 'day').isSame(date, 'day')) {
      return {
        name: `Gisteren`,
        description: date.format('D MMMM'),
        time: date.format('HH:mm'),
        weekday: date.format('dddd'),
      }
    }
    if (now.isSame(date, 'day')) {
      return {
        name: `Vandaag`,
        description: date.format('D MMMM'),
        time: date.format('HH:mm'),
        weekday: date.format('dddd'),
      }
    }
    if (now.add(1, 'day').isSame(date, 'day')) {
      return {
        name: `Morgen`,
        description: date.format('D MMMM'),
        time: date.format('HH:mm'),
        weekday: date.format('dddd'),
      }
    }

    const dayName = date.format('dddd')

    if (date.isBefore(now)) {
      return {
        name: `Afgelopen ${dayName}`,
        description: date.format('D MMMM'),
        time: date.format('HH:mm'),
        weekday: date.format('dddd'),
      }
    }

    return {
      name: ucfirst(dayName),
      description: date.format('D MMMM'),
      time: date.format('HH:mm'),
      weekday: date.format('dddd'),
    }
  }

  if (date.isSame(now, 'year')) {
    return {
      name: date.format('D'),
      description: date.format('MMMM'),
      time: date.format('HH:mm'),
      weekday: date.format('dddd'),
    }
  }

  return {
    name: date.format('DD-MM'),
    description: date.format('YYYY'),
    time: date.format('HH:mm'),
    weekday: date.format('dddd'),
  }
}
export const getToday = () => {
  return dayjs()
}
export const getTomorrow = () => {
  let tomorrow = dayjs().add(1, 'day')
  const dayOfWeek = tomorrow.get('day')

  // Nehemia 13  - Sunday === 0
  if (dayOfWeek === 0) {
    tomorrow = tomorrow.add(1, 'day')
  }
  return tomorrow
}
export const getFullFormattedDateKey = (date) => {
  return date.format('DD-MM-YYYY HH:mm')
}
export const getDateKey = (date) => {
  return date.format('YYYY-MM-DD') // keep year month day structure so we can sort on key
}
export const getDateFromKey = (key) => {
  const date = dayjs(key, 'YYYY-MM-DD') // keep year month day structure so we can sort on key
  return date
}
export const getTimestamp = (date) => {
  // returns the number of seconds since the Unix Epoch for the Dayjs.
  return date.unix()
}
export const fromTimestamp = (timestamp) => {
  return dayjs.unix(timestamp)
}
export const getDatesInFuture = (howMany) => {
  let dates = []
  for (let i = 0; i < howMany; i++) {
    const day = dayjs().add(i + 1, 'day')
    const dayOfWeek = day.get('day')
    // Sunday === 0
    // Nehemia 13
    if (dayOfWeek === 0) {
      continue
    }
    dates.push(day)
  }
  return dates
}
export const setLocale = (locale) => {
  dayjs.locale(locale)
}

export default dayjs
