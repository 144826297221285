import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadCategories, loadList } from '../../Home/actions'
import actions from './actions'

import { View, FlatList, StyleSheet, ScrollView } from 'react-native'
import { RadioButton, Checkbox, Appbar } from 'react-native-paper'
import SafeAreaView from '../../components/SafeAreaView'
import BigButton from '../../components/BigButton'
// import { HOME_SCREEN_ROUTE } from '../../Routes.config'
import BigButtonShimmer from '../../components/BigButton.shimmer'
import ProductItemShimmer from './ProductItemShimmer'
import ProductItem from './ProductItem'
import widthAndHeightHOC from '../../WidthAndHeight/widthAndHeightHOC'
import { getTranslation } from '../../language'
import AppbarHeader from '../../components/AppbarHeader'

const styles = StyleSheet.create({
  footer: {
    height: 200,
  },
})

class L extends Component {
  state = {
    allCategories: [0],
    currentCategoryId: 0,
  }
  componentDidMount() {
    const { loaded } = this.props.categoryData
    if (loaded) {
      this._loadProducts(0)
    }
  }

  _loadProducts = (catId) => {
    this.props.loadList(
      {
        params: {
          limit: 1000,
          'filter[category_ids]': [catId],
          hideZeroPrice: true,
          status: 'active',
        },
      },
      { key: catId }
    )
  }

  _press = (item) => () => {
    this.setState({
      currentCategoryId: item.id,
      allCategories: [...this.state.allCategories, item.id],
    })
    const { loaded } = this.props.categoryData
    if (loaded) {
      this._loadProducts(item.id)
    }
  }
  _goBack = () => {
    const newState = this.state.allCategories.filter(
      (it) => it !== this.state.currentCategoryId
    )
    const gotoId = newState[newState.length - 1]
    this.setState({
      currentCategoryId: gotoId,
      allCategories: newState,
    })
  }
  _pressProduct = (product) => () => {
    this.props.onSelect(product)
  }

  _renderLeft = (item, isSelected) => () => {
    let SelectionElement

    if (this.props.singleSelect) {
      SelectionElement = RadioButton.Android
    } else if (this.props.multiSelect) {
      SelectionElement = Checkbox.Android
    }

    if (SelectionElement) {
      return (
        <SelectionElement
          status={isSelected ? 'checked' : 'unchecked'}
          onPress={this._pressProduct(item)}
        />
      )
    }
    return null
  }
  _renderItem = ({ item }) => {
    const { list } = this.props
    const { currentCategoryId } = this.state
    // console.log(item.id, 'RENDER ITEM')
    // console.log({image})
    let inner =
      currentCategoryId === item.id ? (
        <></>
      ) : (
        !this.state.searching && (
          <BigButton
            label={getTranslation(item, 'name')}
            onPress={this._press(item)}
            backgroundSizing={item.parentCategoryId === 0 ? 'cover' : 'small'}
            labelColor={item.parentCategoryId === 0 ? '#01136B' : '#000'}
          />
        )
      )
    const selectedList = list[this.state.currentCategoryId]
    const loaded = selectedList && selectedList.loaded && !selectedList.loading
    const data = selectedList && selectedList.data
    return (
      <View key={`container_${item.id}`}>
        {inner}

        {loaded
          ? data.map((p) => {
              return (
                <ProductItem
                  key={`${p.id}`}
                  padding
                  left={this._renderLeft(p, this.props.isSelected(p))}
                  product={p}
                  onPress={this._pressProduct(p)}
                />
              )
            })
          : [...Array(item.amountOfProducts)].map((x, i) => (
              <ProductItemShimmer key={`${item.id}_${i}`} />
            ))}
      </View>
    )
  }
  _renderShimmer = ({ item }) => {
    return (
      <View key={`${item}`}>
        <SafeAreaView top={false} bottom={false} enableFlex={false}>
          <BigButtonShimmer />
        </SafeAreaView>
      </View>
    )
  }
  _renderFooter = () => {
    return <View style={styles.footer} />
  }
  render() {
    const { width } = this.props
    const { loading, categoryById, downTree } = this.props.categoryData

    const { currentCategoryId } = this.state
    const categoriesBeneath = downTree[currentCategoryId]
    return [
      <AppbarHeader>
        {currentCategoryId > 0 ? (
          <Appbar.BackAction onPress={this._goBack}  />
        ) : null}
        <Appbar.Content />
        <Appbar.Action icon={'close'} onPress={this.props.onClose} testID={'closeButtonProduct'} />
      </AppbarHeader>,
      <ScrollView style={{ flex: 1 }}>
        {loading ? (
          <FlatList
            // ref={el => (this.flatListRef = el)}
            key={`list_1_${width}`}
            data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            renderItem={this._renderShimmer}
            numColumns={1}
            style={{ flex: 1 }}
            contentContainerStyle={{ flexGrow: 1 }}
            contentInsetAdjustmentBehavior={'never'}
            keyExtractor={(item, index) => `${item.id || index}`}
          />
        ) : (
          <FlatList
            ref={(el) => (this.flatListRef = el)}
            keyExtractor={(item, index) => `${item.id || index}`}
            key={`list_2_${width}`}
            keyboardShouldPersistTaps={'handled'}
            keyboardDismissMode={'on-drag'}
            onScroll={this._handleScroll}
            ListFooterComponent={this._renderFooter}
            renderItem={this._renderItem}
            data={
              categoryById &&
              Object.entries(categoryById).length > 0 &&
              (categoriesBeneath && categoriesBeneath.length > 0
                ? categoriesBeneath.map((id) => {
                    return categoryById[id]
                  })
                : currentCategoryId !== 0
                ? [currentCategoryId].map((id) => {
                    return categoryById[id]
                  })
                : null)
            }
            initialNumToRender={
              categoriesBeneath.length > 0 ? categoriesBeneath.length : 10
            }
            numColumns={1}
            style={{ flex: 1 }}
            contentContainerStyle={{ flexGrow: 1 }}
            contentInsetAdjustmentBehavior={'never'}
          />
        )}
      </ScrollView>,
    ]
  }
}
const mapStateToProps = (state, props) => ({
  categoryData: state.category.categoryData,
  list: state.category.row,
})
export default widthAndHeightHOC(
  connect(mapStateToProps, { ...actions, loadCategories, loadList })(L)
)
