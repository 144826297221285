import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { Appbar } from 'react-native-paper'
import CategoryWidget from './CategoryWidget'
import ProductsWidget from './ProductsWidget'
import ProductWidget from './ProductWidget'
import TextWidget from './TextWidget'
import ImageWidget from './ImageWidget'
import VideoWidget from './VideoWidget'
import Container from '../../components/Container'

const styles = StyleSheet.create({
  root: {
    minHeight: 100,
    // flex: 1,
    // maxWidth: 600,
    width: 900,

    backgroundColor: '#fff',
    // alignItems: 'center',
    marginTop: 24,
    marginBottom: 24,
    borderRadius: 6,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  title: {
    fontWeight: 'bold',
    color: '#000',
    fontSize: 19,
  },
  header: {
    elevation: 0,
    backgroundColor: 'transparent',
    width: '100%',
  },
})
const typeToTitle = {
  category: 'Categorie',
  product: 'Product',
  products: 'Producten',
  text: 'Tekst',
  image: 'Afbeelding',
  video: 'Video',
}
const typeToComponent = {
  category: CategoryWidget,
  products: ProductsWidget,
  product: ProductWidget,
  text: TextWidget,
  image: ImageWidget,
  video: VideoWidget,
}
class Widget extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.language !== nextProps.language ||
      this.props.item !== nextProps.item ||
      this.props.isActive !== nextProps.isActive
    )
  }
  render() {
    const { item, dragDropMode } = this.props
    const Component = typeToComponent[item.type]
    return (
      <Container>
        <View style={styles.root}>
          <Appbar style={styles.header} dark={false}>
            <Appbar.Content title={typeToTitle[item.type]} />
            <View>
              <Appbar.Action
                icon={'delete-outline'}
                onPress={this.props.onDelete}
                testID={'deleteWidget_' + item.type}
              />
            </View>
          </Appbar>
          {!dragDropMode ? <Component {...this.props} /> : null}
        </View>
      </Container>
    )
  }
}

export default Widget
