import React, { Component } from 'react'
import { Button } from 'react-native-paper'
import ClosableModal from '../../components/ClosableModal'
import L from './List'

class SingleSelect extends Component {
  state = {
    modalVisible: false,
  }
  _hideModal = () => {
    this.setState({ modalVisible: false })
  }
  _showModal = () => {
    this.setState({ modalVisible: true })
  }
  _isSelected = (item) => {
    return (this.props.value || {}).id === item.id
  }
  _select = (item) => {
    this.props.onSelect(item)
    this._hideModal()
  }

  render() {
    const { modalVisible } = this.state
    const value = this.props.value

    return [
      <Button
        key="button"
        icon="chevron-down"
        mode={'outlined'}
        uppercase={false}
        style={{ marginRight: 6 }}
        onPress={this._showModal}
      >
        {value
          ? [value.firstName, value.preposition, value.lastName]
              .filter((n) => n)
              .join(' ') || 'Geen naam'
          : 'Gebruiker'}
      </Button>,
      <ClosableModal
        key="modal"
        isVisible={modalVisible}
        onBackButtonPress={this._hideModal}
        onBackdropPress={this._hideModal}
        onRequestClose={this._hideModal}
        hideHeader={true}
        // showClose={true}
      >
        <L
          {...this.props}
          singleSelect
          isSelected={this._isSelected}
          searchIcon={'close'}
          onSearchIconPress={this._hideModal}
          onSelect={this._select}
        />
      </ClosableModal>,
    ]
  }
}

export default SingleSelect
