import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { List } from 'react-native-paper'
import ClosableModal from '../../components/ClosableModal'
import L from './List'
import { getTranslation } from '../../language'
import { Hoverable } from 'react-native-web-hover'

const styles = StyleSheet.create({
  root: { width: '100%' },
})

class SingleSelect extends Component {
  state = {
    modalVisible: false,
  }
  _hideModal = () => {
    this.setState({ modalVisible: false })
  }
  _showModal = () => {
    this.setState({ modalVisible: true })
  }
  _isSelected = (item) => {
    return (this.props.value || {}).id === item.id
  }
  _select = (item) => {
    this.props.onSelect(item)
    this._hideModal()
  }

  render() {
    const { modalVisible } = this.state
    const { value } = this.props

    return (
      <View style={styles.root}>
        <Hoverable key={`product_single_select_${Math.random()}`}>
          {({ hovered }) => (
            <List.Item
              style={
                hovered
                  ? { backgroundColor: '#DFDFDF', cursor: 'pointer' }
                  : { backgroundColor: '#FFF' }
              }
              onPress={this._showModal}
              title={
                value
                  ? getTranslation(value, 'name')
                  : 'Geen product geselecteerd'
              }
              description={value ? 'Product' : 'Selecteer een product'}
              right={(p) => <List.Icon {...p} icon="chevron-down" />}
            />
          )}
        </Hoverable>

        <ClosableModal
          isVisible={modalVisible}
          onBackButtonPress={this._hideModal}
          onBackdropPress={this._hideModal}
          onRequestClose={this._hideModal}
          hideHeader={true}
          // showClose={true}
        >
          <L
            {...this.props}
            singleSelect
            isSelected={this._isSelected}
            searchIcon={'close'}
            onClose={this._hideModal}
            onSelect={this._select}
          />
        </ClosableModal>
      </View>
    )
  }
}

export default SingleSelect
