import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import {
  ActivityIndicator,
  Appbar,
  Button,
  withTheme,
} from 'react-native-paper'

import Navigation, { push } from '../Navigation'
import widthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'

const styles = StyleSheet.create({
  header: {
    backgroundColor: 'transparent',
    elevation: 0,
  },
  root: {
    flex: 1,
    paddingRight: 12,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  one: {
    fontSize: 15,
    // fontWeight: '600',
    padding: 5,
    paddingLeft: 6,
    paddingRight: 6,
  },

  spacer: { flex: 1, maxWidth: 56 },
})

class MailHeader extends Component {
  _back = () => {
    const { componentId } = this.props
    Navigation.pop(componentId)
  }
  _goTo = (page) => () => {
    const mail = this.props.crud.object || {}

    push(this.props.componentId, {
      component: {
        name: page,
        passProps: {
          id: mail.id,
        },
      },
    })
  }
  render() {
    const { crud, isMedium } = this.props
    const { creating, updating, removing } = crud
    const mail = this.props.crud.object || {}

    return (
      <Appbar key="header" style={styles.header}>
        <Appbar.BackAction onPress={this._back} />
        {this.props.children}
        {creating || updating || removing ? (
          isMedium ? (
            <Button key={'save'} loading={true} mode={'contained'} disabled>
              Bezig....
            </Button>
          ) : (
            <ActivityIndicator size={'small'} />
          )
        ) : (
          [
            mail.id && (
              <Appbar.Action
                key={'remove'}
                icon="delete-outline"
                onPress={this.props.onRemove}
              />
            ),
            isMedium ? (
              <>
                {this.props.onSubmitAndInvite && (
                  <Button
                    key={'save'}
                    icon="send"
                    mode={'outlined'}
                    onPress={this.props.onSubmitAndInvite}
                    style={{ marginRight: 12 }}
                    disabled={this.props.inviteButtonDisabled}
                  >
                    Opslaan en uitnodigen
                  </Button>
                )}
                <Button
                  key={'save'}
                  icon="check"
                  mode={'contained'}
                  onPress={this.props.onSubmit}
                >
                  Opslaan
                </Button>
              </>
            ) : (
              <>
                {this.props.onSubmitAndInvite && (
                  <Appbar.Action
                    key={'save'}
                    icon="send"
                    disabled={this.props.inviteButtonDisabled}
                    onPress={this.props.onSubmitAndInvite}
                  />
                )}
                <Appbar.Action
                  key={'save'}
                  icon="check"
                  onPress={this.props.onSubmit}
                />
              </>
            ),
          ]
        )}
        {isMedium ? <View style={{ width: 6 }} /> : null}
      </Appbar>
    )
  }
}

export default widthAndHeightHOC(withTheme(MailHeader))
