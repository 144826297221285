import React, { Component } from 'react'
import { StyleSheet } from 'react-native'
import { List, withTheme } from 'react-native-paper'
import { connect } from 'react-redux'
import actions from './actions'
import { push } from '../Navigation'
import * as routes from '../Routes.config'
import GeneralList from '../GeneralListFunctions/GeneralList'
import ItemShimmer from '../components/Item.shimmer'
import Container from '../components/Container'
import { Hoverable } from 'react-native-web-hover'

// eslint-disable-next-line
const styles = StyleSheet.create({})

class ListScreen extends Component {
  state = {
    title: '',
    rerenderKey: 0,
  }
  _pressItem = (item) => () => {
    push(this.props.componentId, {
      component: {
        name: routes.EVENT_DETAIL_SCREEN_ROUTE,
        passProps: {
          id: item.id,
        },
      },
    })
  }

  _getParams = (ignore, inputSearch) => {
    const { list } = this.props
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }
    return {
      ...list.params,
      showAllUsers: true,
      preloadAll: true,
      'search[email]': search,
      sort: 'email',
    }
  }
  _renderShimmer = (key) => {
    return (
      <Container key={key}>
        <ItemShimmer key={key} />
      </Container>
    )
  }

  _renderItem = ({ item }) => {
    return (
      <Container>
        <Hoverable key={`${item.id}`}>
          {({ hovered }) => (
            <List.Item
              style={
                hovered
                  ? { backgroundColor: '#DFDFDF', cursor: 'pointer' }
                  : { backgroundColor: '#FFF' }
              }
              onPress={this._pressItem(item)}
              title={
                [item.email].filter((n) => n).join(' ') || 'Geen email opgegeven'
              }
              description={`Event: ${item.event}`}
            />
          )}
        </Hoverable>
      </Container>
    )
  }

  render() {
    const { loadList, reloadList, loadMoreList, componentId, list } = this.props

    return (
      <>
        <GeneralList
          renderAboveHeader={this._renderAboveHeader}
          reloadList={reloadList}
          loadList={loadList}
          loadMoreList={loadMoreList}
          list={list}
          componentId={componentId}
          renderItem={this._renderItem}
          renderShimmer={this._renderShimmer}
          getParams={this._getParams}
          title={'Events'}
          reloadListOnMount={true}
          loadListOnMount={true}
        />
      </>
    )
  }
}

ListScreen.propTypes = {}
ListScreen.defaultProps = {}

const mapStateToProps = (state) => ({
  list: state.event.list,
})

export default connect(mapStateToProps, actions)(withTheme(ListScreen))
