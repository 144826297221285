import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { TextInput, Text } from 'react-native-paper'
import {
  setTranslationValue,
  getTranslationValue,
} from '../../helpers/language'

const styles = StyleSheet.create({
  root: { flex: 1, padding: 16 },
  textInput: {
    flex: 1,
    backgroundColor: '#F6F4F9',
    padding: 14,
    fontFamily: 'Montserrat',
    borderRadius: 5,
  },
  textInputNormal: {
    backgroundColor: 'transparent',
    marginBottom: 8,
  },
})

function getYoutubeIdFromUrl(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  var match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

function getYoutubeUrlFromId(id) {
  if (!id) {
    return ``
  }
  return `https://youtu.be/${id}`
}

class VideoWidget extends Component {
  state = {
    height: 100,
  }
  _change = (key) => (url) => {
    const value = getYoutubeIdFromUrl(url) ? getYoutubeIdFromUrl(url) : url
    const video = this.props.item.video || {}

    this.props.onChange({
      ...this.props.item,
      video: {
        ...video,
        ...setTranslationValue(video, key, value, this.props.language),
      },
    })
  }

  render() {
    const { item, language } = this.props
    const video = item.video || {}
    // console.log('item: ', item)
    const youtubeId = getTranslationValue(video, 'youtubeId', language)
    const youtubeUrl = getYoutubeUrlFromId(youtubeId)
    return (
      <View style={styles.root}>
        <TextInput
          mode={'flat'}
          style={[styles.textInputNormal]}
          value={youtubeUrl}
          onChangeText={this._change('youtubeId')}
          label="YouTube-URL"
          testID={'youtubeField'}
        />
        {youtubeId ? null : (
          <Text style={{ marginTop: 6, marginBottom: 6 }}>
            Plak een YouTube-URL in het bovenstaande tekstveld.
          </Text>
        )}
        {youtubeId ? (
          <iframe
            style={{ width: '100%', height: 400 }}
            src={`https://www.youtube.com/embed/${youtubeId}`}
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Video"
          />
        ) : null}
      </View>
    )
  }
}

export default VideoWidget
