import React, { PureComponent } from 'react'

import { List } from 'react-native-paper'
import { weekDays } from './RepeatMailField'
import dayjs from '../dayjs'
import { Hoverable } from 'react-native-web-hover'

class ListItem extends PureComponent {
  static displayName = 'List.Item'

  render() {
    const item = this.props.item || {}
    const repeatMail = item.repeatMail || {}
    const days = (repeatMail.days || []).map((d) => d.day)
    const titleA = [item.internalName, item.subject, item.name].filter((t) => t)
    const activeDaysWithLabel = weekDays.filter((wd) =>
      days.some((d) => d === wd.value)
    )

    let description = ''

    if (activeDaysWithLabel.length > 0 && repeatMail.active) {
      description += 'Iedere '
      description += activeDaysWithLabel
        .map((wd) => wd.title.toLowerCase())
        .join(', ')
      description += ' om '
      const testDate = new Date(item.repeatMail.time * 1000)
      const fullDate = new Date(
        testDate.getUTCFullYear(),
        testDate.getUTCMonth(),
        testDate.getUTCDate(),
        testDate.getUTCHours(),
        testDate.getUTCMinutes(),
        0
      )
      console.log(fullDate)
      description += dayjs(fullDate).format('H:mm')
    } else if (!repeatMail.active && item.deliveryDate) {
      const testDate = new Date(item.deliveryDate * 1000)
      const fullDate = new Date(
        testDate.getUTCFullYear(),
        testDate.getUTCMonth(),
        testDate.getUTCDate(),
        testDate.getUTCHours(),
        testDate.getUTCMinutes(),
        0
      )
      description = `${dayjs(fullDate).format('DD-MM-YYYY HH:mm')}`
    }

    return (
      <Hoverable key={`list_item_mail_${Math.random()}`}>
        {({ hovered }) => (
          <List.Item
            style={
              hovered
                ? { cursor: 'pointer', backgroundColor: '#DFDFDF' }
                : { backgroundColor: '#FFF' }
            }
            testID={'TEST'}
            onPress={this.props.onPress}
            title={titleA.join(' - ')}
            description={description}
          />
        )}
      </Hoverable>
    )
  }
}

export default ListItem
