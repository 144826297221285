import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import ShimmerPlaceHolder from '../../components/ShimmerPlaceHolder'

class ProductItemShimmer extends Component {
  render() {
    const { icon } = this.props
    return (
      <View style={styles.item} left={false} right={false} enableFlex={false}>
        {icon ? (
          <View style={styles.iconContainer}>
            <ShimmerPlaceHolder autoRun={true} style={styles.icon} />
          </View>
        ) : null}
        <View style={styles.itemInner}>
          <ShimmerPlaceHolder autoRun={true} style={styles.title} />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  item: {
    height: 72,
    flexDirection: 'row',
    padding: 8,
    alignItems: 'center',
  },
  iconContainer: {
    width: 40,
    height: 40,
    margin: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: 25,
    width: 25,
    borderRadius: 15,
  },
  itemInner: {
    flex: 1,
    margin: 8,
  },
  title: {
    width: 150,
    height: 30,
    borderRadius: 15,
  },
})

export default ProductItemShimmer
