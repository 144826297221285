import React, { Component } from 'react'
import { View } from 'react-native'
import { List } from 'react-native-paper'
import L from './List'
import ClosableModal from '../../components/ClosableModal'

class SingleSelect extends Component {
  state = {
    modalVisible: false,
  }
  _hideModal = () => {
    this.setState({ modalVisible: false })
  }
  _showModal = () => {
    this.setState({ modalVisible: true })
  }
  _isSelected = (item) => {
    return item.id === (this.props.value && this.props.value.id)
  }
  _select = (item) => {
    this._hideModal()
    this.props.onSelect(item)
  }

  render() {
    const { modalVisible } = this.state
    const { value } = this.props

    let name = `Geen organisatie`
    if (value && value.id) {
      name = value.name
    }
    return (
      <View>
        <List.Item
          onPress={this._showModal}
          title={value ? name : 'Selecteer organisatie'}
          description={value ? 'Organisatie' : 'Geen organisatie geselecteerd'}
          right={(p) => <List.Icon {...p} icon="chevron-down" />}
        />

        <ClosableModal
          isVisible={modalVisible}
          onBackButtonPress={this._hideModal}
          onBackdropPress={this._hideModal}
          onRequestClose={this._hideModal}
          hideHeader={true}
        >
          <L
            {...this.props}
            singleSelect
            isSelected={this._isSelected}
            searchIcon={'close'}
            onSearchIconPress={this._hideModal}
            onSelect={this._select}
          />
        </ClosableModal>
      </View>
    )
  }
}

export default SingleSelect
