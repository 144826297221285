import React, { Component } from 'react'
import { StyleSheet } from 'react-native'
import { withTheme } from 'react-native-paper'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Searchbar from '../components/Searchbar'

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#E8E7EA',
    marginTop: 0,
    marginBottom: 0,
    shadowOpacity: 0,
    elevation: 0,
  },
  inputStyle: {
    fontSize: 16,
    fontWeight: '400',
  },
})

class SearchHeader extends Component {
  constructor(props) {
    super(props)
    // THIS HAS TO BE DONE IN ORDER TO FIX THE INSTANT ENTER AFTER PRESSING CNTRL + V AND ENTER
    this.state = {
      changedText: false,
    }
  }
  debouncedSearch = (value) => {
    this.props.onSearch(value)
  }
  _search = (value) => {
    this.setState({
      changedText: true,
    })
    this.props.onSearch(value)
  }
  render() {
    const {
      value,
      searchPlaceholder,
      safeMargin,
      searchIcon,
      autoFocusSearch,
    } = this.props

    return (
      <Searchbar
        key="search-bar"
        testID={'search'}
        onSubmitEditing={() =>
          !this.state.changedText ? this.debouncedSearch(value) : null
        }
        placeholder={searchPlaceholder || 'Zoeken'}
        onChangeText={this._search}
        style={[safeMargin, styles.root]}
        inputStyle={styles.inputStyle}
        value={value}
        autoCorrect={false}
        autoFocus={autoFocusSearch}
        icon={searchIcon || 'magnify'}
        onIconPress={this.props.onIconPress}
      />
    )
  }
}

export default safeAreaHOC(withTheme(SearchHeader))
