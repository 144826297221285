import { actionCreator as ac } from '../../helpers/actionCreator'

const ns = (type) => `DIRECT_SEND_${type}`

export const SELECT_USER = ns('SELECT_USER')
export const selectUser = ac(SELECT_USER)

export const SELECT_ORGANIZATION = ns('SELECT_ORGANIZATION')
export const selectOrganization = ac(SELECT_ORGANIZATION)

export const SEND_MAIL = ns('SEND_MAIL')
export const sendMail = ac(SEND_MAIL)

export const SENDED_MAIL = ns('SENDED_MAIL')
export const sendedMail = ac(SENDED_MAIL)
