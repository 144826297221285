import { combineReducers } from 'redux'

import auth from 'react-auth/lib/reducers/authentication'
import forgotPassword from 'react-auth/lib/reducers/forgotPassword'

import settings from './Settings/reducer'
import user from './User/reducer'
import userOrganization from './User/Organization/reducer'
import mail from './Mail/reducer'
import mailDesign from './Mail/Design/reducer'
import mailCampaign from './Mail/Campaign/reducer'
import mailCategory from './Mail/Category/reducer'
import mailProduct from './Mail/Product/reducer'
import mailUser from './Mail/User/reducer'
import mailOrganization from './Mail/User/Organization/reducer'
import organization from './Organization/reducer'

import organizationGroup from './OrganizationGroup/reducer'
import organizationGroupOrganization from './OrganizationGroup/Organization/reducer'

import mailOrganizationGroup from './Mail/OrganizationGroup/reducer'
import mailManageRegistrations from './Mail/ManageRegistrations/reducer'
import organizationPriceList from './Organization/PriceList/reducer'

import widthAndHeight from './WidthAndHeight/reducer'
import notification from './Notifications/reducer'
import preview from './Mail/Preview/reducer'
import directSend from './Mail/DirectSend/reducer'
import category from './Home/reducer'
import addUserRole from './User/Role/reducer'
import userRegistration from './User/Registration/reducer'
import addUserRoleAll from './User/Role/All/reducer'
import selectedOrganization from './Organization/SelectedOrganization/reducer'

import event from './Event/reducer'

export default combineReducers({
  auth,
  forgotPassword,
  widthAndHeight,
  settings,
  user,
  userOrganization,
  mailManageRegistrations,
  organizationPriceList, userRegistration,
  organization,
  organizationGroup,
  organizationGroupOrganization,
  mailCampaign,
  preview,
  directSend,
  mail,
  mailOrganization,
  mailDesign,
  mailCategory,
  addUserRole,
  addUserRoleAll,
  mailProduct,
  mailOrganizationGroup,
  mailUser,
  notification,
  category,
  selectedOrganization,
  event,
})
