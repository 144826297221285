import { put, takeLatest } from 'redux-saga/effects'
import * as a from './actions'

import { Platform, NativeModules } from 'react-native'

function* restoreData() {
  try {
    const selectedLanguage = getLanguageCode()

    yield put(
      a.restored({
        selectedLanguage,
      })
    )
  } catch (error) {
    console.log('error restoring: ', error)
  }
}

function convertLanguageCode(lang) {
  // console.log(lang)
  if (lang === 'nl') {
    return 'nl_nl'
  } else if (lang === 'be') {
    return 'be_nl'
  } else if (lang === 'fr') {
    return 'be_fr'
  } else if (lang === 'en') {
    return 'en_us'
  } else {
    return 'nl_nl'
  }
}
function getLanguageCode() {
  let systemLanguage = 'nl_nl'
  if (Platform.OS === 'android') {
    systemLanguage = NativeModules.I18nManager.localeIdentifier
  } else if (Platform.OS === 'web') {
    systemLanguage = navigator.language || navigator.userLanguage
  } else {
    systemLanguage = NativeModules.SettingsManager.settings.AppleLocale
  }
  const languageCode = convertLanguageCode(systemLanguage.substring(0, 2))
  // console.log(languageCode)
  return languageCode
}

export default function* saga() {
  yield takeLatest(a.RESTORE, restoreData)
}
