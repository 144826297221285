import React, { Component } from 'react'

import Fields from '../../Form/Fields'

const fields = [
  {
    key: 'products',
    name: 'products',
    type: 'mailProducts',
    label: 'Producten',
  },
]
class ProductWidget extends Component {
  _change = (key, value) => {
    const item = this.props.item || {}
    this.props.onChange({
      ...item,
      [key]: value,
    })
  }
  render() {
    const item = this.props.item || {}
    return (
      <Fields
        key={'Form fields'}
        fields={fields}
        values={item}
        onChange={this._change}
      />
    )
  }
}

export default ProductWidget
