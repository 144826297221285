import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../reducers'
import mySaga from '../sagas'

import { restore as restoreLanguage } from '../Settings/actions'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const initialState = {}

export default function configureStore() {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware()

  // mount it on the Store
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )

  // then run the saga
  sagaMiddleware.run(mySaga)

  store.dispatch(restoreLanguage())

  return store
}
