import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { Appbar, Button } from 'react-native-paper'
import SafeAreaView from '../components/SafeAreaView'
import KeyboardAvoidingScrollView from '../components/KeyboardAvoidingScrollView'
import Navigation from '../Navigation'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import AddressFields from '../Form/AddressFields'
import { connect } from 'react-redux'
import { updateProfile } from 'react-auth/lib/actions/authentication'

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
  resizer: {
    // flex: 1,
    maxWidth: 900,
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  avatar: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  button: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 300,
  },
})
const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    name: 'name',
    label: 'Organisatie',
    type: 'text',
    fieldProps: { style: { marginTop: 16, marginBottom: 16 } },
  },
  {
    name: 'email',
    label: 'E-mailadres',
    type: 'email',
    fieldProps,
  },
  {
    name: 'phone',
    label: 'Telefoon nummer',
    type: 'text',
    fieldProps,
  },
]
class OrganizationDetails extends Component {
  state = {
    address: {},
    profile: {
      user: { ...this.props.user.user },
      organization: {
        ...this.props.user.organization,
        addresses: [
          this.props.user.organization &&
          this.props.user.organization.addresses &&
          this.props.user.organization.addresses[0]
            ? this.props.user.organization.addresses[0]
            : {},
        ],
      },
    },
  }
  _change = (name, value, address) => {
    if (address) {
      let address =
        this.state.profile.organization &&
        this.state.profile.organization.addresses &&
        this.state.profile.organization.addresses.find(
          address => address.type === ''
        )

      address = {
        ...address,
        [name]: value,
        type: '',
      }
      this.setState({
        profile: {
          user: { ...this.state.profile.user },
          organization: {
            ...this.state.profile.organization,
            addresses: [address],
          },
        },
      })
    } else {
      this.setState({
        profile: {
          user: { ...this.state.profile.user },
          organization: {
            ...this.state.profile.organization,
            [name]: value,
          },
        },
      })
    }
  }
  _update = () => {
    this.props.updateProfile(this.state.profile)
  }
  _back = () => {
    Navigation.pop(this.props.componentId)
  }
  _changeAddress = address => {
    this.setState({
      profile: {
        user: { ...this.state.profile.user },
        organization: {
          ...this.state.profile.organization,
          addresses: [address],
        },
      },
    })
  }
  render() {
    const { organization } = this.props.user

    return (
      <SafeAreaView>
        <View style={styles.resizer}>
          <Appbar style={styles.header}>
            <Appbar.BackAction onPress={this._back} />
            <Appbar.Content title={organization.name} />
          </Appbar>
        </View>

        <KeyboardAvoidingScrollView
          keyboardShouldPersistTaps={'always'}
          keyboardDismissMode={'on-drag'}
          style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 15 }}
        >
          <View style={styles.resizer}>
            <FormContainer key="form-container" onSubmit={this.props.onSubmit}>
              <Fields
                fields={fields}
                values={this.state.profile.organization || {}}
                onChange={(name, value, address) =>
                  this._change(name, value, address)
                }
                onSubmit={this._update}
              />
              <AddressFields
                values={this.state.profile.organization.addresses[0] || {}}
                onChange={this._changeAddress}
              />

              <Button
                mode={'contained'}
                // icon="send"
                onPress={this._update}
                type="submit"
                loading={this.props.loading}
                // color={theme.colors.primary}
                uppercase={false}
                style={styles.button}
                contentStyle={styles.buttonContentStyle}
                touchableStyle={styles.buttonTouchableStyle}
              >
                Opslaan
              </Button>
            </FormContainer>
          </View>
        </KeyboardAvoidingScrollView>
      </SafeAreaView>
    )
  }
}

export default connect(
  null,
  { updateProfile }
)(OrganizationDetails)
