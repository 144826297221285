// import { set } from 'lodash'

export const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE

export const getTranslationValue = (object, key, language) => {
  if (!object) {
    return ''
  }
  const translations = object[`${key}Translations`] || {}
  //   console.log('translations!!', translations)
  const value = translations[language]
  //   console.log('VALUE', value)

  // only fallback if default is same as value..
  if (!value && defaultLanguage === language) {
    return object[key] || ''
  }
  return value || ''
}

export const setTranslationValue = (object, key, value, language) => {
  let changes = {}

  // change root key if current language is the default
  if (language === defaultLanguage) {
    changes[key] = value
  }
  const translationKey = `${key}Translations`

  // get original translations object
  let translationObject = object[translationKey] || {}

  // Set value in translations object with current language
  translationObject[language] = value

  // add to changes object
  changes[translationKey] = translationObject

  // console.log('CHANGES', changes)
  return changes
}
