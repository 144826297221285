import * as a from './actions'

const initialState = {
  data: null,
  user: {},
  loaded: false,
  error: false,
  loading: false,
}
const preview = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case a.SELECT_USER: {
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
        user: payload,
      }
    }
    case a.PREVIEW_MAIL: {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    }

    case a.PREVIEWED_MAIL: {
      return error
        ? {
            ...state,
            error: true,
            loading: false,
            loaded: true,
          }
        : {
            ...state,
            data: payload,
            error: false,
            loading: false,
            loaded: true,
          }
    }
    case a.CLEAN_REDUX: {
      return initialState
    }
    default:
      return state
  }
}

export default preview
