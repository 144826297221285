import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import actions from './ManageRegistrations/actions'
import GeneralList from '../GeneralListFunctions/GeneralList'
import ItemShimmer from '../components/Item.shimmer'
import Container from '../components/Container'
import { Checkbox, List as PaperList, Snackbar } from 'react-native-paper'
import { usePrevious } from './DirectSend/DirectSend'
import { Hoverable } from 'react-native-web-hover'
// @ts-ignore
import Navigation from '../Navigation'

// const styles = StyleSheet.create({})

export function useActionsAsFunctions<T>(actions: T): T {
  const dispatch = useDispatch()
  const actionsAsProps = React.useMemo(() => {
    // console.log('re-calculate actions')
    //@ts-ignore
    let functionMap: T = {}
    Object.keys(actions)
      .filter((actionKey) => {
        // @ts-ignore
        const action = actions[actionKey]
        return typeof action === 'function'
      })
      .forEach((actionKey: string) => {
        // @ts-ignore
        const action = actions[actionKey]
        // @ts-ignore
        functionMap[actionKey] = (
          payload?: string,
          meta?: any,
          error?: any
        ) => {
          dispatch(action(payload, meta, error))
        }
      })
    return functionMap as any
  }, [actions, dispatch])

  // console.log({ actionsAsProps })

  return actionsAsProps as T
}

type ManageRegistrationsScreenPropsType = {
  componentId: number
}

export type SubscriptionStatusType = {
  id: number
  status: string
  user: UserType
}
export type UserType = {
  id: number
  email: string
  firstName: string
  preposition: string
  lastName: string
  organization: OrganizationType
}
export type OrganizationType = {
  id: number
  name: string
}

function ManageRegistrationsScreen({
  componentId,
}: ManageRegistrationsScreenPropsType) {
  const actionsAsFunctions = useActionsAsFunctions(actions)
  const list = useSelector((state: any) => state.mailManageRegistrations.list)
  const crud = useSelector((state: any) => state.mailManageRegistrations.crud)
  const [stateData, setStateData] = useState<SubscriptionStatusType[]>([])
  const prevLoadedList = usePrevious(list.loadedList)
  const prevUpdating = usePrevious(crud.updating)
  const [succesFullySaved, setSuccesFullySaved] = useState(false)

  useEffect(() => {
    if (list.loadedList && list.loadedList !== prevLoadedList) {
      setStateData(list.data)
    }
  }, [list, stateData, prevLoadedList])

  useEffect(() => {
    if (list.loadingList) {
      setStateData([])
    }
  }, [list.loadingList])

  const getParams = (ignore: any, inputSearch: string) => {
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }
    return {
      ...list.params,
      showAllUsers: true,
      preloadAll: true,
      'search[name]': search,
      sort: 'name',
      'embed[]': 'organization',
    }
  }
  const renderShimmer = (key: string) => {
    return (
      <Container key={key}>
        <ItemShimmer key={key} />
      </Container>
    )
  }
  const toggleActive = (item: SubscriptionStatusType) => {
    if (item.status === 'subscribed') {
      actionsAsFunctions.update({
        ...item,
        status: 'unsubscribed',
      })
    } else {
      actionsAsFunctions.update({
        ...item,
        status: 'subscribed',
      })
    }
  }

  useEffect(() => {
    if (prevUpdating && crud.updating !== prevUpdating && crud.object) {
      setStateData(
        stateData.map((it: SubscriptionStatusType) => {
          if (it.id === crud.object.user.id) {
            return { ...crud.object, id: crud.object.user.id }
          }
          return it
        })
      )
      setSuccesFullySaved(true)
    }
  }, [crud, prevUpdating, stateData])

  const renderItem = ({ item }: { item: SubscriptionStatusType }) => {
    return (
      <Container key={item.user.id}>
        <Hoverable key={`list_item_registration_${item.id}`}>
          {({ hovered }) => (
            <PaperList.Item
              // onPress={this._pressItem(item)}
              style={
                hovered
                  ? // @ts-ignore
                    { cursor: 'pointer' }
                  : { backgroundColor: '#FFF' }
              }
              title={
                [item.user.firstName, item.user.preposition, item.user.lastName]
                  .filter((n) => n)
                  .join(' ') || 'Geen naam opgegeven'
              }
              description={item && item.user && item.user.email}
              left={() => (
                <View style={{ marginTop: 6, marginBottom: 6 }}>
                  <Checkbox
                    onPress={() => toggleActive(item)}
                    status={
                      item.status === 'subscribed' ? 'checked' : 'unchecked'
                    }
                  />
                </View>
              )}
            />
          )}
        </Hoverable>
      </Container>
    )
  }
  const cleanList = {
    ...list,
    data: stateData,
  }
  const back = () => {
    Navigation.pop(componentId)
  }
  return [
    <GeneralList
      {...actionsAsFunctions}
      list={cleanList}
      componentId={componentId}
      renderItem={renderItem}
      renderShimmer={renderShimmer}
      getParams={getParams}
      title={'Geabonneerd op de nieuwsbrief'}
      onBack={back}
    />,
    <Snackbar
      key="snackbar"
      visible={!!succesFullySaved}
      onDismiss={() => setSuccesFullySaved(false)}
      style={[
        // styles.message,
        {
          marginBottom: 16,
        },
      ]}
      theme={{ colors: { accent: 'white' } }}
      action={{
        label: 'OK',
        onPress: () => setSuccesFullySaved(false),
      }}
    >
      {crud && crud.object && crud.object.status === 'subscribed'
        ? `${
            [
              crud.object && crud.object.user && crud.object.user.firstName,
              crud.object && crud.object.user && crud.object.user.preposition,
              crud.object && crud.object.user && crud.object.user.lastName,
            ]
              .filter((n) => n)
              .join(' ') || 'Geen naam opgegeven'
          } (${
            crud.object && crud.object.user && crud.object.user.email
          }) is succesvol ingeschreven.`
        : `${
            [
              crud.object && crud.object.user && crud.object.user.firstName,
              crud.object && crud.object.user && crud.object.user.preposition,
              crud.object && crud.object.user && crud.object.user.lastName,
            ]
              .filter((n) => n)
              .join(' ') || 'Geen naam opgegeven'
          } (${
            crud.object && crud.object.user && crud.object.user.email
          }) is succesvol uitgeschreven.`}
    </Snackbar>,
  ]
}

export default ManageRegistrationsScreen
