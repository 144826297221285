import React from 'react'

export const MailPage = ({ data }) => (
  <iframe
    srcdoc={data}
    height="100%"
    title="preview_mail"
    frameBorder={0}
    style={{ border: 0 }}
  />
)
