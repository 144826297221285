import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { Checkbox, List, Text } from 'react-native-paper'
import actions from './actions'
import { connect, useDispatch } from 'react-redux'

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
  },
  itemText: {
    padding: 10,
    paddingLeft: 0,
  },
})

function AllList({ role, loadList, selected, onChange }) {
  const envItems =
    process.env.REACT_APP_EDITABLE_ROLES &&
    process.env.REACT_APP_EDITABLE_ROLES.split(',')
  const apiItems = role.list.data
  const translatedMap = {
    // Agro4All roles
    contractor: 'Loonwerker',
    farmer: 'Boer',
    horticulturist: 'Teler',
    advisor: 'Adviseur',
    transporter: 'Transporteur',
    manager: 'Manager',
    admin: 'Admin',
    manufacturer: 'Fabrikant',
    wholesaler: 'Groothandelaar',
    drift_user: 'Driftzoeker gebruiker',
    'drift-user': 'Driftzoeker gebruiker',
    knpv: 'KNPV gebruiker',
    // PePe Parts roles.
    main_role: 'Organisatie beheerder',
    user: 'Gebruiker',
    list_invoice: 'Factuur inzicht',
    list_cost_price: 'Inkoopprijs inzicht',
    order_confirm: 'Order bevestigen',
    // Agrisnellaad roles
    sub_contractor: 'Onderaannemer',
  }

  const roles = apiItems
    .map((element) => {
      console.log({ envItems, element })
      return envItems &&
        envItems.length > 0 &&
        envItems.some(
          (envItem) => envItem !== 'user' && envItem === element.name
        ) // role 'user' is automatically added by the backend
        ? {
            name: translatedMap[element.name]
              ? translatedMap[element.name]
              : element.name,
            value: element.name,
          }
        : null
    })
    .filter((n) => n)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadList())
  }, [dispatch, loadList])

  return roles.map((ro) => (
    <List.Item
      left={() => (
        <Checkbox.Android
          // style={{ margin: 8 }}
          status={
            selected.some((it) => it.value === ro.value)
              ? 'checked'
              : 'unchecked'
          }
          onPress={() => onChange(ro)}
        />
      )}
      right={() => <Text style={styles.itemText}>{ro.name}</Text>}
      style={styles.item}
      onPress={() => onChange(ro)}
    />
  ))
}

const mapStateToProps = (state) => ({
  role: state.addUserRoleAll,
})
export default connect(mapStateToProps, actions)(AllList)
