import React, { Component } from 'react'
import { View } from 'react-native'
import { TextInput } from 'react-native-paper'

class TextInputMultiLanguage extends Component {
  render() {
    return (
      <View>
        <TextInput value={'nl'} />
        <TextInput value={'be'} />
      </View>
    )
  }
}
export default TextInputMultiLanguage
