import React, { Component } from 'react'
import PT from 'prop-types'
import { View, ScrollView, StyleSheet } from 'react-native'
import keyboardHOC from './keyboardHOC'

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
})

class KeyboardAvoidingScrollView extends Component {
  render() {
    const { keyboardHeight } = this.props

    return (
      <View style={styles.flex}>
        <ScrollView {...this.props} />
        <View style={{ height: keyboardHeight || 0 }} />
      </View>
    )
  }
}

KeyboardAvoidingScrollView.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
  keyboardHeight: PT.number.isRequired,
}
KeyboardAvoidingScrollView.defaultProps = {
  scrollToEnd: false,
}
export default keyboardHOC(KeyboardAvoidingScrollView)
