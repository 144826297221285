import React from 'react'
import {
  ActivityIndicator,
  withTheme,
  Appbar,
} from 'react-native-paper'
import actions from './actions'

import KeyboardAvoidingScrollView from '../components/KeyboardAvoidingScrollView'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import detailHOC from '../GeneralListFunctions/detailHOC'
import { connect, useSelector } from 'react-redux'
import paramsAsPropsHOC from '../GeneralListFunctions/paramsAsPropsHOC'
import Container from '../components/Container'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Header from '../components/Header'

const fields = [
  {
    key: 'text',
    name: 'name',
    type: 'text',
    label: 'Groepnaam',
    fieldProps: {},
  },
  {
    key: 'member',
    name: 'organizations',
    type: 'organizations',
    label: 'Organisaties',
  },
]

type OrganizationGroupDetailScreenPropsType = {
  onSubmit: ({
    payload,
    meta,
  }: {
    payload: any
    meta: { params: { organizationGroupId: number; embed: string[] } }
  }) => any
  onChange: (name: string, value: any) => any
  componentId: number
  onRemove: (id: number) => any
}

function OrganizationGroupDetailsScreen({
  onSubmit,
  onChange,
  componentId,
  onRemove,
}: OrganizationGroupDetailScreenPropsType) {
  const crud = useSelector((state: any) => state.organizationGroup.crud)
  const submit = () => {
    const values = crud.object || {}
    const meta = {
      params: {
        organizationGroupId: values.organization_group_id,
        embed: ['organizationGroup'],
      },
    }

    onSubmit({ payload: values, meta })
  }
  // only for fixing organization id
  const change = (name: string, value: any) => {
    if (name === 'organization') {
      onChange('organization_group_id', value && value.id)
      return
    }
    onChange(name, value)
  }
  const [values, setValues] = React.useState(crud.object || {})
  React.useEffect(() => {
    if (crud.object && crud.object !== values) {
      setValues(crud.object)
    }
  }, [values, crud.object])

  let enhancedFields = fields
  if (values.id) {
    enhancedFields = fields.map((f) =>
      f.name === 'email'
        ? { ...f, fieldProps: { ...f.fieldProps, disabled: true } }
        : f
    )
  }

  return [
    <Container>
    {/*// @ts-ignore*/}
    <Header
      key="header"
      componentId={componentId}
      crud={crud}
      onRemove={onRemove}
      onSubmit={submit}
    >
      <Appbar.Content title={values.id ? `Bewerk ${values.name}` : 'Nieuw'} />
    </Header>
    </Container>,
    crud.loading ? (
      <Container>
        <ActivityIndicator size={'small'} />
      </Container>
    ) : (
      <KeyboardAvoidingScrollView style={{ padding: 5 }} key="scroll">
        <Container>
          <FormContainer onSubmit={submit}>
            {/* @ts-ignore*/}
            <Fields
              fields={enhancedFields}
              values={values}
              onChange={change}
              onSubmit={submit}
            />
          </FormContainer>
        </Container>
      </KeyboardAvoidingScrollView>
    ),
  ]
}
const mapStateToProps = (state: any) => ({
  crud: state.organizationGroup.crud,
})
export default safeAreaHOC(
  paramsAsPropsHOC(
    withTheme(
      connect(
        mapStateToProps
        // { loadGroupList }
      )(
        detailHOC(OrganizationGroupDetailsScreen, mapStateToProps, actions, {
          optinEdit: false,
          enableDelete: true,
          reloadOnMount: false,
          cacheFromList: true,
        })
      )
    )
  )
)
