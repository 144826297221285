import React, { Component } from 'react'
import { Platform, Image, View, StyleSheet } from 'react-native'
import { Snackbar, withTheme, Button, IconButton } from 'react-native-paper'
import forgotPasswordHOC from 'react-auth/lib/hocs/forgotPasswordHOC'

import Navigation from '../Navigation'
import * as routes from '../Routes.config'
import Logo from '../../img/logo.png'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import KeyboardAvoidingScrollView from '../components/KeyboardAvoidingScrollView'
import keyboardHOC from '../components/keyboardHOC'
import PinkView from './PinkView'
import LoginScreen from './LoginScreen'

const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    name: 'email',
    label: 'E-mailadres',
    type: 'email',

    fieldProps: { ...fieldProps, autoFocus: true },
  },
]

//
class ForgotPassword extends Component {
  _change = (name, value) => {
    this.props.onChangeField(name, value)
  }
  _submit = () => {
    this.props.onSubmit()
  }
  _gotoLogin = () => {
    Navigation.pop(this.props.componentId, {
      component: {
        name: routes.AUTH_SCREEN_ROUTE,
      },
    })
  }

  render() {
    const { sending, error, keyboardHeight, completed } = this.props

    return [
      <KeyboardAvoidingScrollView
        key=""
        keyboardShouldPersistTaps={'always'}
        keyboardDismissMode={'on-drag'}
      >
        <IconButton
          icon="chevron-left"
          size={32}
          color={'#000000'}
          onPress={this._gotoLogin}
        />

        <FormContainer key="form-container" onSubmit={this._submit}>
          <View style={styles.loginContainer}>
            <Image
              style={styles.logo}
              source={process.env.REACT_APP_STATIC_ASSETS_ENDPOINT ? `${process.env.REACT_APP_STATIC_ASSETS_ENDPOINT}/media/template/logo.png` : Logo}
            />

            <View style={styles.resize}>
              <View style={styles.loginView}>
                <Fields
                  fields={fields}
                  values={this.props.values || {}}
                  onChange={this._change}
                  onSubmit={this._submit}
                />

                <Button
                  mode={'contained'}
                  // icon="send"
                  onPress={this._submit}
                  type="submit"
                  loading={sending}
                  // color={theme.colors.primary}
                  uppercase={false}
                  contentStyle={styles.buttonContentStyle}
                >
                  Reset wachtwoord
                </Button>
              </View>
            </View>
          </View>
          <PinkView/>
        </FormContainer>
      </KeyboardAvoidingScrollView>,

      <Snackbar
        key="snackbar"
        visible={!!error}
        onDismiss={this.props.dismiss}
        style={[
          styles.message,
          {
            marginBottom: keyboardHeight + 16,
          },
        ]}
      >
        {'Fout bij het aanvragen van wachtwoord'}
      </Snackbar>,
      <Snackbar
        key="snackbar"
        visible={completed}
        onDismiss={this.props.dismiss}
        style={[
          styles.message,
          {
            marginBottom: keyboardHeight + 16,
          },
        ]}
      >
        {
          'Er is een mail verstuurd met instructies om je wachtwoord te veranderen.'
        }
      </Snackbar>,
    ]
  }
}

LoginScreen.propTypes = {}
LoginScreen.defaultProps = {}

const styles = StyleSheet.create({
  buttonContentStyle: {
    height: 56,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  logoContainer: {
    padding: 20,
    paddingTop: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    marginTop: 50,
    marginBottom: 50,
    height: 70,
    width: 200,
    resizeMode: 'contain',
  },
  loginContainer: {
    flex: Platform.OS === 'web' ? '1 1 auto' : 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#fff',
  },

  resize: {
    maxWidth: 500,
    paddingLeft: 6,
    paddingRight: 6,
    width: '100%',
    flex: 1,
  },
  errorMessage: {
    backgroundColor: 'red',
  },
})

export default keyboardHOC(withTheme(forgotPasswordHOC(ForgotPassword)))
