import { call, put, select, takeLatest } from 'redux-saga/effects'
import { fetchListener } from 'react-auth/lib/sagas/authentication'
import ua from '../actions'
import * as a from './actions'
import { toPath } from 'lodash'


const castTo = {
  'string': (v) => String(v),
  'decimal': (v) => Number(v),
  'integer': (v) => v | 0,
  'boolean': (v) => Boolean(v)
}

function* removeAttribute({ payload }) {
  try {
    const user = yield select(state => state.user.crud.object);

    const attribute = user.attributes[payload];

    yield call(fetchListener, {
      path: `v1/user/${user.id}/attribute/${attribute.key}`,
      method: 'DELETE',
    });

    user.attributes.splice(payload, 1);

    yield put(ua.updated(user));
  } catch (e) {
    console.log(e);
  }
}

function* addAttribute({ payload }) {
  try {
    const user = yield select(state => state.user.crud.object);

    let index = user.attributes.push(payload) - 1;

    let response = yield call(fetchListener, {
      path: `v1/user/${user.id}`,
      method: 'PUT',
      body: user,
    });

    user.attributes[index] = response.attributes[index]

    yield put(ua.updated(user))
  } catch (e) {
    console.log(e);
  }
}

function* updateAttribute({ meta }) {
  try {
    const user = yield select(state => state.user.crud.object);

    for (const path of meta.changedAttributePaths) {
      const parsed = toPath(path);
      const index = Number(parsed[1]);

      const { key, type, value } = user.attributes[index];

      let response = yield call(fetchListener, {
        path: `v1/user/${user.id}/attribute/${key}`,
        method: 'PUT',
        body: {
          type: type,
          value: castTo[type](value),
        },
      });
      user.attributes[index] = response
    }

    yield put(ua.updated(user))
  } catch (e) {
    console.error(e)
  }
}

export default function* attribute() {
  yield takeLatest([ua.UPDATE], updateAttribute)
  yield takeLatest([a.ADD_ATTRIBUTE], addAttribute)
  yield takeLatest([a.REMOVE_ATTRIBUTE], removeAttribute);
}
