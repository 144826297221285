import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Image, StyleSheet, View } from 'react-native'
import { Link, withRouter } from 'react-router-dom'
import {
  Avatar,
  List,
  Text,
  TouchableRipple,
  withTheme,
} from 'react-native-paper'
import Logo from './Logo'
import { getGravatar } from './helpers/gravatar'
import { push } from './Navigation'
import { ACCOUNT_SCREEN_ROUTE } from './Routes.config'

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#fff',
    elevation: 10,
    height: 56,
    width: '100%',
    flexDirection: 'row',
    borderTopWidth: 1,
    borderColor: '#EDEDED',
    alignItems: 'center',
  },
  rootMedium: {
    paddingLeft: 16,
    paddingRight: 16,
    height: 70,
  },

  tabRoot: {
    flex: 1,
  },
  tabRootMedium: { flexDirection: 'row', minWidth: 150 },
  tabRootLarge: { flexDirection: 'row', minWidth: 250, flexShrink: 0 },

  tab: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  tabLarge: { flexDirection: 'row' },

  tabLink: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  tabIcon: {
    width: 20,
    height: 20,
  },
  tabIconLarge: {
    width: 30,
    height: 30,
  },
  tabLabel: {
    marginTop: 6,
    fontSize: 12,
  },
  tabLabelLarge: {
    marginLeft: 12,
    fontSize: 14,
  },

  space: {
    flex: 1,
    flexDirection: 'row',
  },

  avatar: {
    marginLeft: 16,
  },
})

const linkStyle = {
  flex: 1,
  display: 'flex',
  textDecoration: 'none',
  maxWidth: 250,
  height: '100%',
}

const nullFunc = () => null
class Tab extends Component {
  render() {
    const { label, icon, selected, theme, url, isMedium, isLarge } = this.props

    return (
      <Link to={url} style={linkStyle}>
        <TouchableRipple
          onPress={nullFunc}
          style={[
            styles.tabRoot,
            isMedium && styles.tabRootMedium,
            isLarge && styles.tabRootLarge,
          ]}
        >
          <View
            style={[
              styles.tab,
              isMedium && styles.tabMedium,
              isLarge && styles.tabLarge,
            ]}
          >
            <Image
              source={icon}
              style={[
                styles.tabIcon,
                isLarge && styles.tabIconLarge,
                selected && { tintColor: theme.colors.primary },
              ]}
              resizeMode="center"
            />
            <Text
              style={[
                styles.tabLabel,
                isLarge && styles.tabLabelLarge,
                selected && { color: theme.colors.primary },
              ]}
            >
              {label}
            </Text>
          </View>
        </TouchableRipple>
      </Link>
    )
  }
}

const isSelected = (path, location) => {
  if (path === 'home') {
    return location.pathname === '/'
  }
  if (location && location.pathname) {
    // console.log(path, location, location.pathname.includes(path))
    return (
      location.pathname.endsWith(path) || location.pathname.includes(path + '/')
    )
  }

  return false
}

const locationIncludes = (location, v) => {
  return !!(location && location.pathname && location.pathname.includes(v))
}

class TabMenu extends Component {
  _pressAccount = () => {
    push(this.props.componentId, {
      component: {
        name: ACCOUNT_SCREEN_ROUTE,
      },
    })
  }
  render() {
    const { organization, user, theme, location, isLarge, isMedium } =
      this.props

    const enabledModules =
      process.env.REACT_APP_ENABLE_MODULES &&
      process.env.REACT_APP_ENABLE_MODULES.split(',')

    if (locationIncludes(location, 'auth')) {
      return null
    }
    if (locationIncludes(location, 'change-password')) {
      return null
    }
    if (locationIncludes(location, 'forgot-password')) {
      return null
    }
    if (locationIncludes(location, 'mailing/preview')) {
      return null
    }
    const tabProps = {
      isLarge,
      isMedium,
      theme,
    }

    return (
      <View
        style={[styles.root, isMedium && styles.rootMedium]}
        onLayout={this._onLayout}
      >
        {isLarge ? (
          <Link to={'/'}>
            <View style={styles.logo}>
              <Logo />
            </View>
          </Link>
        ) : null}
        {isMedium ? <View style={styles.space} /> : null}

        {/* <Tab
          url={'/dashboard'}
          icon={require('../img/dashboard/icon-120.png')}
          label={'Dashboard'}
          selected={isSelected('/dashboard', location)}
          {...tabProps}
        /> */}

        {enabledModules?.includes('mailing') && (
          <Tab
            url={'/mailing'}
            icon={require('../img/mail/icon-120.png')}
            label={'Mailing'}
            selected={
              isSelected('/mailing', location) || isSelected('home', location)
            }
            {...tabProps}
          />
        )}
        {enabledModules.includes('user') && (
          <Tab
            url={'/user'}
            icon={require('../img/user/icon-120.png')}
            label={'Gebruikers'}
            selected={isSelected('/user', location)}
            {...tabProps}
          />
        )}
        {enabledModules.includes('organization') && (
          <Tab
            url={'/organization'}
            icon={require('../img/organization/icon-120.png')}
            label={'Organisaties'}
            selected={isSelected('/organization', location)}
            {...tabProps}
          />
        )}
        {enabledModules.includes('organization-group') && (
          <Tab
            url={'/organization-group'}
            icon={require('../img/organization/icon-120.png')}
            label={'Organisatiegroepen'}
            selected={isSelected('/organization-group', location)}
            {...tabProps}
          />
        )}

        {isMedium ? <View style={styles.space} /> : null}

        {!isLarge && isMedium && user && user.email ? (
          <List.Item
            left={() => (
              <Avatar.Image
                size={46}
                source={getGravatar(user.email, 35 * 3)}
                style={styles.avatar}
              />
            )}
            onPress={this._pressAccount}
          />
        ) : null}
        {isLarge && user && organization ? (
          <List.Item
            left={() => (
              <Avatar.Image
                size={46}
                source={getGravatar(user.email, 35 * 3)}
                style={styles.avatar}
              />
            )}
            title={
              user.preposition !== ''
                ? `${user.last} ${user.preposition} ${user.lastName}`
                : `${user.firstName} ${user.lastName}`
            }
            description={organization.name || 'Geen organisatie'}
            onPress={this._pressAccount}
          />
        ) : null}
      </View>
    )
  }
}

const mapStateToProps = (state, props) => ({
  user: state.auth && state.auth.user && state.auth.user.user,
  organization: state.auth && state.auth.user && state.auth.user.organization,
})
export default withRouter(connect(mapStateToProps)(withTheme(TabMenu)))
