import React, { useState, useCallback } from 'react'
import { Searchbar as PureSearchbar } from 'react-native-paper'

export default function Searchbar({ value, onChangeText, ...rest }) {
  const [innerValue, setInnerValue] = useState(value)

  const onInnerChangeText = useCallback(
    text => {
      setInnerValue(text)
      onChangeText(text)
    },
    [setInnerValue, onChangeText]
  )

  return (
    <PureSearchbar
      {...rest}
      value={innerValue}
      onChangeText={onInnerChangeText}
    />
  )
}
