import React from "react";
import { View } from 'react-native'
import { Title, DataTable } from "react-native-paper";
import PT from 'prop-types'

const fields = {
  "street": "straat",
  "houseNumber": "nummer",
  "postalCode": "postcode",
  "city": "plaats",
}

const enabled = (process.env.REACT_APP_ENABLE_MODULES || []).split(',').includes('address');

function Addresses({ addresses }) {
  if (!enabled) {
    return null;
  }

  if (addresses.length === 0) {
    return null;
  }

  return (<View style={{ marginBottom: 16 }}>
    <Title>Addressen</Title>
    {
      addresses.map(addres => (
        <DataTable>
          <DataTable.Header>
            <DataTable.Title>Type</DataTable.Title>

            <DataTable.Title>{addres['type']}</DataTable.Title>
          </DataTable.Header>
          {
            Object.keys(fields).map(key => (
              <DataTable.Row>
                <DataTable.Cell>{fields[key]}</DataTable.Cell>
                <DataTable.Cell>{addres[key]}</DataTable.Cell>
              </DataTable.Row>
            ))
          }
        </DataTable>
      )
      )
    }
  </View>);
}

Addresses.propTypes = {
  addresses: PT.array
}

Addresses.defaultProps = {
  addresses: []
}

export default Addresses;
