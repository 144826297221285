import React, { Component } from 'react'
import Routes from './Routes'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import { Provider } from 'react-redux'
import WidthAndHeight from './WidthAndHeight/WidthAndHeight'

import { connect } from 'react-redux'
import configureStore from './helpers/configureStore'
import NotificationContainer from './Notifications/NotificationContainer'
import translation from './translations'
import { Provider as Translation } from 'react-translated'
import { wrap } from './components/Modal'

const store = configureStore()

const fontF = weight => ({
  fontFamily: 'Montserrat',
  fontWeight: weight,
})

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#FFFFFF',
    accent: '#0018A7',
    primary: '#0018A7',
    secondary: '#FFFFFF',
  },

  roundness: 5,
  fonts: {
    regular: fontF('400'),
    medium: fontF('500'),
    light: fontF('300'),
    thin: fontF('300'),
  },
}

class TranslationWrapper extends Component {
  render() {
    const { detectedLanguage } = this.props
    return (
      <Translation language={detectedLanguage} translation={translation}>
        {this.props.children}
      </Translation>
    )
  }
}

const ConnectedTranslationWrapper = connect(state => ({
  loaded: state.settings.loaded,
  detectedLanguage: state.settings.data.detectedLanguage,
}))(TranslationWrapper)

export const HOC = WrappedComponent => {
  class Wrapper extends Component {
    render() {
      const inner = (
        <WidthAndHeight
          listenToChanges
          render={props => [
            <WrappedComponent key="content" {...this.props} {...props} />,
            <NotificationContainer key="notifications" {...props} />,
          ]}
        />
      )
      if (window.Cypress) {
        window.store = store
      }
      return (
        <Provider store={store}>
          <PaperProvider theme={theme}>
            <ConnectedTranslationWrapper>
              {wrap(inner)}
            </ConnectedTranslationWrapper>
          </PaperProvider>
        </Provider>
      )
    }
  }
  if (WrappedComponent) {
    Wrapper.options = WrappedComponent.options
  }
  return Wrapper
}

export default HOC(Routes)
