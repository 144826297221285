import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { Appbar, Avatar, Button } from 'react-native-paper'
import SafeAreaView from '../components/SafeAreaView'
import KeyboardAvoidingScrollView from '../components/KeyboardAvoidingScrollView'
import Navigation from '../Navigation'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import { getGravatar } from '../helpers/gravatar'
import { connect } from 'react-redux'
import { updateProfile } from 'react-auth/lib/actions/authentication'

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
  resizer: {
    // flex: 1,
    maxWidth: 900,
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  avatar: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  button: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 300,
  },
})
const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    name: 'firstName',
    label: 'Voornaam',
    type: 'text',
    fieldProps: { style: { marginTop: 16, marginBottom: 16 } },
  },
  {
    name: 'preposition',
    label: 'Tussenvoegsel',
    type: 'text',
    fieldProps,
  },
  {
    name: 'lastName',
    label: 'Achternaam',
    type: 'text',
    fieldProps,
  },
]
class AccountDetails extends Component {
  state = {
    profile: {
      user: this.props.user.user,
      organization: this.props.user.organization,
    },
  }
  _change = (name, value) => {
    this.setState({
      profile: {
        organization: { ...this.state.profile.organization },
        user: {
          ...this.state.profile.user,
          [name]: value,
        },
      },
    })
  }
  _update = () => {
    this.props.updateProfile(this.state.profile)
  }
  _back = () => {
    Navigation.pop(this.props.componentId)
  }
  render() {
    const { user } = this.props.user

    return (
      <SafeAreaView>
        <View style={styles.resizer}>
          <Appbar style={styles.header}>
            <Appbar.BackAction onPress={this._back} />
            <Appbar.Content
              title={
                user.preposition !== ''
                  ? `${user.firstName} ${user.preposition} ${user.lastName}`
                  : `${user.firstName} ${user.lastName}`
              }
            />
          </Appbar>
        </View>

        <KeyboardAvoidingScrollView
          keyboardShouldPersistTaps={'always'}
          keyboardDismissMode={'on-drag'}
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          <View style={styles.resizer}>
            <Avatar.Image
              size={184}
              source={getGravatar(user.email, 140 * 12)}
              style={styles.avatar}
            />
            <FormContainer key="form-container" onSubmit={this.props.onSubmit}>
              <Fields
                fields={fields}
                values={this.state.profile.user || {}}
                onChange={this._change}
                onSubmit={this._update}
              />

              <Button
                mode={'contained'}
                // icon="send"
                onPress={this._update}
                type="submit"
                loading={this.props.loading}
                // color={theme.colors.primary}
                uppercase={false}
                style={styles.button}
                contentStyle={styles.buttonContentStyle}
                touchableStyle={styles.buttonTouchableStyle}
              >
                Opslaan
              </Button>
            </FormContainer>
          </View>
        </KeyboardAvoidingScrollView>
      </SafeAreaView>
    )
  }
}

export default connect(null, { updateProfile })(AccountDetails)
