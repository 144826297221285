import { combineReducers } from 'redux'
import * as a from './actions'

import keyPagedList from '../helpers/keyPagedListReducer'

const initialCategoryState = {
  loading: false,
  loaded: false,
  error: false,
  categoriesByParentId: {},
  categoryById: {},
  downTree: {},
  upTree: {},
}

const categoryReducer = (
  state = initialCategoryState,
  { type, error, payload }
) => {
  switch (type) {
    case 'RESET': {
      return initialCategoryState
    }
    case a.LOAD_CATEGORIES: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    }
    case a.LOADED_CATEGORIES: {
      return error
        ? {
            ...state,
            loading: false,
            loaded: true,
            error: true,
          }
        : {
            ...state,
            loading: false,
            loaded: true,
            error: false,
            ...payload,
          }
    }
    default:
      return state
  }
}

const reducer = combineReducers({
  row: keyPagedList({
    load: a.LOAD_LIST,
    loaded: a.LOADED_LIST,
    loadMore: a.LOAD_MORE_LIST,
    loadedMore: a.LOADED_MORE_LIST,
  }),
  categoryData: categoryReducer,
})
export default reducer
