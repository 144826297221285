import React, { Component } from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import {
  Button,
  Text,
  withTheme,
  Appbar,
  ActivityIndicator,
} from 'react-native-paper'
import {
  MAILING_DETAIL_ONE_ROUTE,
  MAILING_DETAIL_TWO_ROUTE,
} from '../Routes.config'
import Navigation, { push } from '../Navigation'
import { connect } from 'react-redux'
import widthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'
import Container from "../components/Container";

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
  root: {
    flex: 1,
    paddingRight: 12,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  one: {
    fontSize: 13,
    padding: 5,
    paddingLeft: 6,
    paddingRight: 6,

    textAlign: 'center',
    letterSpacing: 0.5,
  },

  spacer: { flex: 1, maxWidth: 56 },
})

class MailHeader extends Component {
  _back = () => {
    const { componentId } = this.props
    if (Platform.OS === 'web') {
      if (!document.referrer.includes('mailing')) {
        Navigation.popToRoot(this.props.componentId)
        return
      }
    }
    Navigation.pop(componentId)
  }
  _goTo = (goToPage) => () => {
    const { componentId, active, crud } = this.props
    const mail = crud.object || {}

    if (Platform.OS === 'web' || goToPage === MAILING_DETAIL_TWO_ROUTE) {
      if (active === 'content' && goToPage === MAILING_DETAIL_TWO_ROUTE) {
        // already on content page, so skip
        return
      }
      push(this.props.componentId, {
        component: {
          name: goToPage,
          passProps: {
            id: mail.id,
          },
        },
      })
    } else if (
      Platform.OS !== 'web' &&
      goToPage === MAILING_DETAIL_ONE_ROUTE &&
      active === 'content'
    ) {
      // Pop when is on second page and on native device
      Navigation.pop(componentId)
    }
  }
  render() {
    const { theme, active, crud, isMedium } = this.props
    const { creating, updating, removing } = crud
    const mail = this.props.crud.object || {}

    return (
        <View style={styles.header}><Container style={styles.header}>
        <Appbar key="header" style={styles.header}>
        <Appbar.BackAction onPress={this._back} />
        <View style={styles.root}>
          <Text
            style={[
              styles.one,
              active === 'mailing' && { color: theme.colors.primary },
            ]}
            onPress={this._goTo(MAILING_DETAIL_ONE_ROUTE)}
          >
            Mailing
          </Text>
          <View style={styles.spacer} />
          <Text
            style={[
              styles.one,
              active === 'content' && { color: theme.colors.primary },
            ]}
            onPress={this._goTo(MAILING_DETAIL_TWO_ROUTE)}
          >
            Inhoud
          </Text>
        </View>
        {creating || updating || removing ? (
          isMedium ? (
            <Button
              key={'save'}
              loading={true}
              mode={'contained'}
              uppercase={false}
              disabled
            >
              Bezig....
            </Button>
          ) : (
            <ActivityIndicator size={'small'} />
          )
        ) : (
          [
            mail.id && (
              <Appbar.Action
                key={'remove'}
                icon="delete-outline"
                testID={'deleteButton'}
                onPress={this.props.onRemove}
              />
            ),
            isMedium ? (
              <Button
                key={'save'}
                icon="check"
                mode={'contained'}
                onPress={this.props.onSubmit}
                testID={'saveButton'}
                uppercase={false}
              >
                Opslaan
              </Button>
            ) : (
              <Appbar.Action
                key={'save'}
                icon="check"
                onPress={this.props.onSubmit}
              />
            ),
          ]
        )}
        {isMedium ? <View style={{ width: 6 }} /> : null}
      </Appbar>
      </Container>
        </View>
    )
  }
}
const mapStateToProps = (state) => ({
  crud: state.mail.crud,
  preview: state.preview,
})
export default widthAndHeightHOC(
  connect(mapStateToProps)(withTheme(MailHeader))
)
