import * as a from './actions'

const initialState = {
  data: [],
  loadedList: false,
  loadingList: false,
  error: false,
}
const sortData = data => {
  let headCategories = {}
  data.forEach(it => {
    if (it.parentCategoryId === 0) {
      headCategories[it.order] = [
        it,
        ...data.filter(item => item.parentCategoryId === it.id),
      ]
    }
  })

  let sortedArr = []
  for (const headCat in headCategories) {
    const found = headCategories[headCat]
    sortedArr = [...sortedArr, ...found]
  }

  return sortedArr
}
const reducer = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case a.LOAD_LIST: {
      return {
        ...state,
        loadingList: true,
        loadedList: false,
      }
    }
    case a.LOADED_LIST: {
      return error
        ? {
            ...state,
            error: true,
            loadingList: false,
            loadedList: true,
          }
        : {
            ...state,
            data: sortData(payload.data),
            error: false,
            loadingList: false,
            loadedList: true,
          }
    }
    default:
      return state
  }
}

export default reducer
