import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { TextInput } from 'react-native-paper'
import {
  setTranslationValue,
  getTranslationValue,
} from '../../helpers/language'
import MediaDropzone from '../../components/MediaDropzone'

const styles = StyleSheet.create({
  root: { flex: 1, padding: 16 },
  textInput: {
    flex: 1,
    backgroundColor: '#F6F4F9',
    padding: 14,
    fontFamily: 'Montserrat',
    borderRadius: 5,
  },
  textInputNormal: {
    backgroundColor: 'transparent',
    marginBottom: 8,
  },
})

class ImageWidget extends Component {
  state = {
    height: 100,
    alt: '',
  }

  _change = (key) => (value) => {
    const { item, language } = this.props
    const image = item.image || {}
    this.props.onChange({
      ...item,
      image: {
        ...image,
        ...setTranslationValue(image, key, value, language),
      },
    })
  }

  render() {
    const { item, language } = this.props
    const image = item.image || {}
    return (
      <View style={styles.root}>
        <TextInput
          mode={'flat'}
          style={[styles.textInputNormal]}
          value={getTranslationValue(image, 'alt', language)}
          onChangeText={this._change('alt')}
          label="Beschrijving (alt)"
        />
        <MediaDropzone
          media={getTranslationValue(image, 'media', language)}
          onChange={this._change('media')}
        />
      </View>
    )
  }
}

export default ImageWidget
