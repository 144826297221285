import { actionCreator as ac } from '../../helpers/actionCreator'

const ns = type => `PREVIEW_${type}`

export const PREVIEW_MAIL = ns('PREVIEW_MAIL')
export const previewMail = ac(PREVIEW_MAIL)

export const PREVIEWED_MAIL = ns('PREVIEWED_MAIL')
export const previewedMail = ac(PREVIEWED_MAIL)

export const CLEAN_REDUX = ns('CLEAN_REDUX')
export const cleanRedux = ac(CLEAN_REDUX)

export const SELECT_USER = ns('SELECT_USER')
export const selectUser = ac(SELECT_USER)
