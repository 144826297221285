import React, { Component } from 'react'
import { View } from 'react-native'
import { List } from 'react-native-paper'
import L from './List'
import ClosableModal from '../../components/ClosableModal'
import { getTranslation } from '../../language'
import { Hoverable } from 'react-native-web-hover'

class SingleSelect extends Component {
  state = {
    modalVisible: false,
  }
  _hideModal = () => {
    this.setState({ modalVisible: false })
  }
  _showModal = () => {
    this.setState({ modalVisible: true })
  }
  _isSelected = (item) => {
    const valueId = this.props.value && this.props.value.id
    return item.id === valueId
  }
  _select = (item) => {
    this._hideModal()
    this.props.onSelect(item)
  }

  render() {
    const { modalVisible } = this.state
    const { value, key } = this.props

    return (
      <View>
        <Hoverable key={`category_single_select_${key}`}>
          {({ hovered }) => (
            <List.Item
              style={
                hovered
                  ? { backgroundColor: '#DFDFDF', cursor: 'pointer' }
                  : { backgroundColor: '#FFF' }
              }
              onPress={this._showModal}
              testID={'categoryButton'}
              title={
                value
                  ? getTranslation(value, 'name')
                  : 'Geen categorie geselecteerd'
              }
              description={value ? 'Categorie' : 'Selecteer een categorie'}
              right={(p) => <List.Icon {...p} icon="chevron-down" />}
            />
          )}
        </Hoverable>
        <ClosableModal
          isVisible={modalVisible}
          onBackButtonPress={this._hideModal}
          onBackdropPress={this._hideModal}
          onRequestClose={this._hideModal}
          hideHeader={true}
          // showClose={true}
        >
          <L
            {...this.props}
            singleSelect
            isSelected={this._isSelected}
            searchIcon={'close'}
            onSearchIconPress={this._hideModal}
            onSelect={this._select}
          />
        </ClosableModal>
      </View>
    )
  }
}

export default SingleSelect
