import { call, put, take, fork, select, takeLatest } from 'redux-saga/effects'

import log from './log'
import api from 'react-api'
import { fetchListener } from 'react-auth/lib/sagas/authentication'

function* load(url, a, stateName, loadMore, useAuthenticating, action) {
  try {
    const state = yield select(state => state)
    const data = stateName(state)

    const extraParams = data.params
    const payloadParams =
      (action && action.payload && action.payload.params) || {}

    const params = {
      limit: 50,
      ...payloadParams,
      ...extraParams,
    }

    if (loadMore) {
      params.offset = data.pagination.limit + data.pagination.offset
    }

    let response = yield call(useAuthenticating ? fetchListener : api, {
      path: url(state),
      params,
    })

    yield put(loadMore ? a.loadedMoreList(response) : a.loadedList(response))
  } catch (e) {
    log(e)
    yield put(
      loadMore
        ? a.loadedMoreList(e, null, true, useAuthenticating)
        : a.loadedList(e, null, true, useAuthenticating)
    )
  }
  if (a.LOAD_MORE_LIST) {
    yield take(a.LOAD_MORE_LIST)
    yield fork(load, url, a, stateName, true, useAuthenticating, action)
  } else {
    log('load more not defined')
  }
}

export default function* pagedList(url, actions, stateName, useAuthenticating) {
  yield takeLatest(
    actions.LOAD_LIST,
    load,
    url,
    actions,
    stateName,
    false,
    useAuthenticating
  )
}
