import React, { Component } from 'react'
import { View } from 'react-native'
import { Checkbox, List } from 'react-native-paper'
import { Hoverable } from 'react-native-web-hover'

class CheckboxWithLabel extends Component {
  _toggle = () => {
    this.props.onChange(!this.props.value)
  }
  render() {
    const { value, label, description } = this.props
    return (
      <Hoverable key={`checkbox_${Math.random()}`}>
        {({ hovered }) => (
          <List.Item
            style={
              hovered ? { cursor: 'pointer' } : { backgroundColor: '#FFF' }
            }
            title={label}
            description={description}
            testID={this.props.testID}
            onPress={this._toggle}
            left={() => (
              <View style={{ justifyContent: 'center' }}>
                <Checkbox
                  onPress={this._toggle}
                  status={value ? 'checked' : 'unchecked'}
                />
              </View>
            )}
          />
        )}
      </Hoverable>
    )
  }
}

export default CheckboxWithLabel
