import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Snackbar } from 'react-native-paper'
import { StyleSheet, Platform } from 'react-native'
const styles = StyleSheet.create({
  root: {
    zIndex: 1000,
    position: 'absolute',
    bottom: 100,
    maxWidth: 1000,
    right: 0,
    left: 0,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rootPaddingBottom: {
    marginBottom: 55,
  },
})

class NotificationContainer extends Component {
  state = {
    visible: false,
  }
  componentDidUpdate(prevProps) {
    const { messages } = this.props

    if (prevProps.messages !== messages && messages && messages.length > 0) {
      this.setState({
        visible: true,
      })
    }
  }
  _dismiss = () => {
    this.setState({ visible: false })
  }
  render() {
    const { messages, isMedium } = this.props
    let paddingBottom = false
    if (!isMedium && Platform.OS === 'web') {
      paddingBottom = true
    }

    return (
      <Snackbar
        visible={this.state.visible}
        onDismiss={this._dismiss}
        duration={20000}
        theme={{
          colors: {
            accent: '#FFFFFF',
          },
        }}
        action={{
          label: 'Ok',
          onPress: this._dismiss,
        }}
        style={[styles.root, paddingBottom && styles.rootPaddingBottom]}
      >
        {messages.map((message, i) => `${i === 0 ? '' : '\n'}${message}`)}
      </Snackbar>
    )
  }
}

export default connect((state) => ({
  messages: state.notification.messages,
}))(NotificationContainer)
