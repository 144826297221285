import { actionCreator as ac } from '../../helpers/actionCreator'

const ns = type => `USER_ATTRIBUTE_${type}`

export const ADD_ATTRIBUTE = ns('ADD')
export const addAttribute = ac(ADD_ATTRIBUTE)


export const REMOVE_ATTRIBUTE = ns('REMOVE');
export const removeAttribute = ac(ADD_ATTRIBUTE);
