import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { TouchableRipple, Text, Button } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import actions from './actions'
// @ts-ignore
import { push } from '../Navigation'
import * as routes from '../Routes.config'
import ListItem from './ListItem'
import GeneralList from '../GeneralListFunctions/GeneralList'
import ItemShimmer from '../components/Item.shimmer'
import Container from '../components/Container'
import { usePrevious } from './DirectSend/DirectSend'

const styles = StyleSheet.create({
  tabs: { flexDirection: 'row', paddingLeft: 5 },
  tab: {},
  tabText: {
    paddingLeft: 16,
    paddingRight: 16,
    textAlignVertical: 'center',
    letterSpacing: 0.5,
    height: 46,
    lineHeight: 46,
  },
  tabTextSelected: {
    fontWeight: 'bold',
  },
})
const tabs: TabType[] = [
  { key: 'done', name: 'Actief', value: 'true' },
  { key: 'concept', name: 'Inactief', value: 'false' },
]
type TabType = {
  key: string
  name: string
  value: string
}
type ListScreenPropsType = {
  activeTab: string
  componentId: number
}
type MailListItemType = {
  id: number
}

function ListScreen({ activeTab, componentId }: ListScreenPropsType) {
  const [currentTab, setCurrentTab] = useState(
    tabs.find((tab) => tab.key === activeTab) || tabs[0]
  )
  const dispatch = useDispatch()
  const list = useSelector((state: any) => state.mail.list)
  const prevCurrentTab = usePrevious(currentTab)

  const pressAdd = () => {
    push(componentId, {
      component: {
        name: routes.MAILING_NEW_ROUTE,
      },
    })
  }
  const pressItem = (item: MailListItemType) => () => {
    push(componentId, {
      component: {
        name: routes.MAILING_DETAIL_ONE_ROUTE,
        passProps: {
          id: item.id,
        },
      },
    })
  }

  const getParams = React.useCallback(
    (ignore: any, inputSearch: string) => {
      let search = inputSearch
      if (search === undefined) {
        search = list.params && list.params.search
      }
      return {
        ...list.params,
        'search[internalName]': search,
        active: currentTab.value,
        'preloads[]': 'repeatMail',
      }
    },
    [list.params, currentTab.value]
  )

  useEffect(() => {
    if (currentTab !== prevCurrentTab) {
      dispatch(
        actions.loadList({
          params: getParams(undefined, ''),
        })
      )
    }
  }, [prevCurrentTab, currentTab, dispatch, getParams])

  const selectTab = (currentTabProp: TabType) => () => {
    push(componentId, {
      component: {
        name: routes.MAILING_SCREEN_ROUTE,
        passProps: {
          activeTab: currentTabProp.key,
        },
      },
    })
    setCurrentTab(currentTabProp)
  }

  const manageRegistration = () => {
    push(componentId, {
      component: {
        name: routes.MANAGE_REGISTRATIONS_SCREEN_ROUTE,
      },
    })
  }

  const renderAboveHeader = () => {
    return (
      <Container>
        <View style={styles.tabs}>
          {tabs.map((tab) => (
            <TouchableRipple
              key={tab.key}
              onPress={selectTab(tab)}
              // @ts-ignore
              style={Platform.OS === 'web' && { cursor: 'pointer' }}
            >
              <Text
                style={[
                  styles.tabText,
                  currentTab === tab && styles.tabTextSelected,
                ]}
              >
                {tab.name}
              </Text>
            </TouchableRipple>
          ))}
          <View style={{ position: 'absolute', right: 0, top: -10 }}>
            <Button
              icon="mail"
              mode="outlined"
              onPress={manageRegistration}
              color="#000"
              style={[
                {
                  width: 200,
                  marginTop: 16,
                },
                // @ts-ignore
                Platform.OS === 'web' && { cursor: 'pointer' },
              ]}
            >
              Inschrijvingen
            </Button>
          </View>
        </View>
      </Container>
    )
  }
  const renderShimmer = (key: string) => {
    return (
      <Container key={key}>
        <ItemShimmer key={key} />
      </Container>
    )
  }
  const renderItem = ({ item }: { item: MailListItemType }) => {
    return (
      <Container key={item.id}>
        <ListItem onPress={pressItem(item)} item={item} />
      </Container>
    )
  }

  return (
    <GeneralList
      reloadList={actions.reloadList}
      loadList={actions.loadList}
      loadMoreList={actions.loadMoreList}
      list={list}
      componentId={componentId}
      renderAboveHeader={renderAboveHeader}
      renderItem={renderItem}
      renderShimmer={renderShimmer}
      getParams={getParams}
      onPressAdd={pressAdd}
      title={'Mailing'}

    />
  )
}

export default ListScreen
