import { actionCreator as ac } from '../helpers/actionCreator'

const ns = type => `SETTINGS_${type}`

export const RESTORE = ns('RESTORE')
export const restore = ac(RESTORE)

export const SET = ns('SET')
export const set = ac(SET)

export const RESTORED = ns('RESTORED')
export const restored = ac(RESTORED)

export const SILENT_RESTORED = ns('SILENT_RESTORED')
export const silentRestored = ac(SILENT_RESTORED)

export const SET_TEXT = ns('SET_TEXT')
export const setCountry = ac(SET_TEXT)
