import React, { useEffect } from 'react'
import { Title } from 'react-native-paper'
import actions from './actions'
import List from './All/List'
import { connect, useDispatch } from 'react-redux'

function RoleList({ loadList, selected, onChange }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadList())
  }, [dispatch, loadList])

  return (
    <>
      <Title>Rollen</Title>
      <List selected={selected} onChange={onChange} />
    </>
  )
}

export default connect(null, actions)(RoleList)
