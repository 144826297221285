import React, { useState } from 'react';
import { View } from 'react-native'
import { Title, DataTable, Button, IconButton } from 'react-native-paper'
import TableRowWithTwoColumns from './TableRowWithTwoColumns'
import PT from 'prop-types'
import Field from '../Form/Field'
import SettingsModel from './SettingsModel';



const enabled = (process.env.REACT_APP_ENABLE_MODULES || []).split(',').includes('settings');

function Settings({ settings, onChange, onSubmit, determineType, onAddSubmit, onAddChange, closeOnSubmit, onDelete }) {

  const [modalVisible, setModalVisible] = useState(false);

  if (!enabled) {
    return null;
  }

  const toggle = () => {
    setModalVisible(!modalVisible);
  }

  const _HOFSubmit = () => {
    if (closeOnSubmit) {
      return (values) => {
        setModalVisible(false);
        onAddSubmit(values)
      }
    }

    return onAddSubmit;
  }

  const _HOFDelete = (index) => () => {
    const confirm = window.confirm('Weet je het zeker');

    if (confirm) {
      onDelete(index);
    }
  }

  return (
    <>
      <SettingsModel determineType={determineType} modalVisible={modalVisible} setModalVisible={toggle} onSubmit={_HOFSubmit()} onChange={onAddChange} />
      <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 16, paddingBottom: 16 }}>
        <Title style={{ flex: 2 }}>Settings</Title>
        <View>
          <Button mode='contained' icon='plus' onPress={toggle}>Toevoegen</Button>
        </View>
      </View>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>Sleutel</DataTable.Title>
          <DataTable.Title>Waarde</DataTable.Title>
        </DataTable.Header>
        {(settings || []).map(({ key, value, type }, index) => {
          return (
            <TableRowWithTwoColumns
              first={key}
              second={
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <View style={{ flex: 4, width: '100%' }}>
                    <Field name={`attributes[${index}].value`} value={value} type={determineType(type, value) ?? 'text'} onChange={onChange} onSubmit={onSubmit} />
                  </View>

                  <IconButton style={{ alignSelf: 'flex-end' }} icon='trash-can' onPress={_HOFDelete(index)} />
                </View>}
            />
          )
        })}
      </DataTable>
    </>
  );
}

Settings.propTypes = {
  settings: PT.arrayOf(PT.shape({
    id: PT.number,
    type: PT.string,
    key: PT.string.isRequired,
    value: PT.any.isRequired
  })).isRequired,
  onchange: PT.func.isRequired,
  onsubmit: PT.func.isRequired,
  determineType: PT.func
}

export default Settings;
