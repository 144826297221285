import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import {
  Button,
  IconButton,
  List as PaperList,
  withTheme,
} from 'react-native-paper'
import { connect } from 'react-redux'
import actions from './actions'
import { push } from '../Navigation'
import * as routes from '../Routes.config'
import GeneralList from '../GeneralListFunctions/GeneralList'
import ItemShimmer from '../components/Item.shimmer'
import Container from '../components/Container'
import ClosableModal from '../components/ClosableModal'
import List from './Organization/List'
import { Hoverable } from 'react-native-web-hover'
import { fromTimestamp, getFullFormattedDateKey } from '../dayjs'
import { setMessages } from '../Notifications/actions'

// eslint-disable-next-line
const styles = StyleSheet.create({})

const iconMap = {
  registration_status_undefined: 'account-alert',
  invited: 'account-clock',
  pending_approval: 'account-clock',
  registered: 'account-check',
}
const colorMap = {
  registration_status_undefined: 'red',
  invited: 'green',
  pending_approval: 'orange',
  registered: 'green',
}
class ListScreen extends Component {
  state = {
    title: '',
    rerenderKey: 0,
    modalVisible: false,
    selectedItems: [],
  }
  _pressAdd = () => {
    push(this.props.componentId, {
      component: {
        name: routes.USER_NEW_ROUTE,
      },
    })
  }
  _pressRegistration = () => {
    push(this.props.componentId, {
      component: {
        name: routes.USER_REGISTRATION_SCREEN_ROUTE,
      },
    })
  }
  _pressItem = (item) => () => {
    push(this.props.componentId, {
      component: {
        name: routes.USER_DETAIL_SCREEN_ROUTE,
        passProps: {
          id: item.id,
        },
      },
    })
  }

  _getParams = (ignore, inputSearch) => {
    const { list } = this.props
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }
    return {
      // ...list.params,
      showAllUsers: true,
      preloadAll: true,
      'search[name]': search,
      sort: 'name',
      'embed[]': 'organization',
    }
  }
  _renderShimmer = (key) => {
    return (
      <Container key={key}>
        <ItemShimmer key={key} />
      </Container>
    )
  }
  _hideModal = () => {
    this.setState({ modalVisible: false })
  }
  _showModal = () => {
    this.setState({ modalVisible: true })
  }
  _select = (item) => {
    const id = item.id
    let selectedItems = this.state.selectedItems.filter((it) => it !== id)
    if (this.state.selectedItems.find((it) => it === id)) {
      this.setState({
        selectedItems,
      })
    } else {
      selectedItems = [...this.state.selectedItems, id]
      this.setState({
        selectedItems,
      })
    }
    this.props.loadList({
      params: {
        ...this._getParams(),
        organizationIds: selectedItems,
      },
    })
  }
  _renderAboveHeader = () => {
    return (
      <Container>
        <Button
          icon="domain"
          mode="outlined"
          onPress={this._showModal}
          color="#000"
          style={{
            width: 200,
            marginTop: 6,
          }}
        >
          Organisaties
        </Button>
        <View style={{ position: 'absolute', right: 0, top: -10 }}>
          <Button
            icon="domain"
            mode="outlined"
            onPress={this._pressRegistration}
            color="#000"
            style={{
              width: 200,
              marginTop: 16,
            }}
          >
            Registraties
          </Button>
        </View>
      </Container>
    )
  }

  _renderItem = ({ item }) => {
    return (
      <Container>
        <Hoverable key={`${item.id}`}>
          {({ hovered }) => (
            <PaperList.Item
              style={
                hovered
                  ? { backgroundColor: '#DFDFDF', cursor: 'pointer' }
                  : { backgroundColor: '#FFF' }
              }
              onPress={this._pressItem(item)}
              title={
                [item.firstName, item.preposition, item.lastName]
                  .filter((n) => n)
                  .join(' ') || 'Geen naam opgegeven'
              }
              description={`${
                item.organization
                  ? `${item.organization.name} - ${item.email}`
                  : `${item.email}`
              }`}
              right={() => (
                <IconButton
                  icon={iconMap[item.registrationStatus.toLowerCase()]}
                  color={colorMap[item.registrationStatus.toLowerCase()]}
                  onPress={() =>
                    item.registrationStatus.toLowerCase() === 'invited'
                      ? this.props.setMessages([
                          `De gebruiker is aangemaakt op ${getFullFormattedDateKey(
                            fromTimestamp(item.createdAt)
                          )}.`,
                        ])
                      : item.registrationStatus.toLowerCase() ===
                        'registration_status_undefined'
                      ? this.props.setMessages([
                          `De gebruiker is nog niet uitgenodigd en niet geregistreerd.`,
                        ])
                      : item.registrationStatus.toLowerCase() ===
                        'pending_approval'
                      ? this.props.setMessages([
                          `De gebruiker is nog niet uitgenodigd, en moet nog geactiveerd worden. De gebruiker is aangemaakt op ${getFullFormattedDateKey(
                            fromTimestamp(item.createdAt)
                          )}.`,
                        ])
                      : this.props.setMessages([
                          `Deze gebruiker is succesvol geregistreerd. De gebruiker is aangemaakt op ${getFullFormattedDateKey(
                            fromTimestamp(item.createdAt)
                          )}.`,
                        ])
                  }
                />
              )}
            />
          )}
        </Hoverable>
      </Container>
    )
  }

  render() {
    const { loadList, reloadList, loadMoreList, componentId, list } = this.props
    const { modalVisible } = this.state

    return (
      <>
        <GeneralList
          renderAboveHeader={this._renderAboveHeader}
          reloadList={reloadList}
          loadList={loadList}
          loadMoreList={loadMoreList}
          list={list}
          componentId={componentId}
          renderItem={this._renderItem}
          renderShimmer={this._renderShimmer}
          getParams={this._getParams}
          onPressAdd={this._pressAdd}
          title={'Gebruikers'}
          reloadListOnMount={true}
          loadListOnMount={true}
        />
        <ClosableModal
          isVisible={modalVisible}
          onBackButtonPress={this._hideModal}
          onBackdropPress={this._hideModal}
          onRequestClose={this._hideModal}
          hideHeader={true}
        >
          <List
            {...this.props}
            isSelected={(item) =>
              this.state.selectedItems.find((it) => it === item.id)
            }
            searchIcon={'close'}
            hideModal={this._hideModal}
            onBack={this._hideModal}
            onSearchIconPress={this._hideModal}
            onSelect={this._select}
            multiSelect
          />
        </ClosableModal>
      </>
    )
  }
}

ListScreen.propTypes = {}
ListScreen.defaultProps = {}

const mapStateToProps = (state) => ({
  list: state.user.list,
})

export default connect(mapStateToProps, { ...actions, setMessages })(
  withTheme(ListScreen)
)
