import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { Text, Appbar, ActivityIndicator } from 'react-native-paper'

import Navigation from '../../Navigation'
import { connect } from 'react-redux'
import { cleanRedux, previewMail, selectUser } from './actions'
import { MailPage } from './MailPage'
import SingleSelect from '../User/SingleSelect'
import Container from "../../components/Container";

class Preview extends Component {
  componentWillMount() {
    this.props.previewMail()
  }
  _back = () => {
    Navigation.pop(this.props.componentId)
    this.props.cleanRedux()
  }

  render() {
    const { preview, hasMailBody } = this.props
    const { loading, error, user } = preview
    const data = preview.data || {}
    const showMailPage = !loading && !error
    return [
      <View style={styles.root}>
        <Container>
        <Appbar key="header" style={styles.header}>
          <Appbar.BackAction onPress={this._back} />
          <Appbar.Content title={'Mail voorbeeld'} />
          <SingleSelect onSelect={this.props.selectUser} value={user} />
        </Appbar>
        </Container>

        {loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size={'large'} />
          </View>
        ) : null}

        {showMailPage ? <MailPage data={data} /> : null}
        {error && hasMailBody ? (
          <View style={styles.errorContainer}>
            <Text>Fout bij laden voorbeeld.</Text>
          </View>
        ) : null}
        {error && !hasMailBody ? (
          <View style={styles.errorContainer}>
            <Text>Ga terug en zorg dat de mail ingeladen is.</Text>
          </View>
        ) : null}
      </View>,
    ]
  }
}
const styles = StyleSheet.create({
  root: { flex: 1, backgroundColor: '#FCFCFC' },
  header: {
    backgroundColor: 'transparent',
    elevation: 0,
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  loadingContainer: { flex: 1, justifyContent: 'center', alignItems: 'center' },
})
const mapStateToProps = state => ({
  preview: state.preview,
  hasMailBody: !!state.mail.crud.object,
})
export default connect(
  mapStateToProps,
  { cleanRedux, previewMail, selectUser }
)(Preview)
