import React, { Component } from 'react'

import Fields from '../../Form/Fields'
import MediaDropzone from '../../components/MediaDropzone.web'
import {
  getTranslationValue,
  setTranslationValue,
} from '../../helpers/language'
import { Button, Menu, TextInput } from 'react-native-paper'
import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  root: { flex: 1, padding: 16 },
  textInput: {
    flex: 1,
    backgroundColor: '#F6F4F9',
    padding: 14,
    fontFamily: 'Montserrat',
    borderRadius: 5,
  },
  textInputNormal: {
    backgroundColor: 'transparent',
    marginBottom: 8,
  },
})
const fields = [
  {
    key: 'product',
    name: 'product',
    type: 'mailProduct',
    label: 'Product',
  },
]
class ProductWidget extends Component {
  state = {
    visible: false,
    visibleAlt: false,
  }
  _change = (key, value) => {
    const item = this.props.item || {}
    this.props.onChange({
      ...item,
      [key]: value,
    })
  }
  _changeImage = (key) => (value) => {
    const { item, language } = this.props
    const image = item.image || {}

    this.props.onChange({
      ...item,
      image: {
        ...image,
        ...setTranslationValue(image, key, value, language),
      },
    })
  }
  _changePositionAlt = (key) => (value) => {
    const { item } = this.props
    const image = item.image || {}

    this.setState({
      visibleAlt: false,
    })
    this.props.onChange({
      ...item,
      image: {
        ...image,
        [key]: value,
      },
    })
  }
  _changePosition = (key) => (value) => {
    const { item } = this.props
    this.setState({
      visible: false,
    })
    this.props.onChange({
      ...item,
      [key]: value,
    })
  }
  render() {
    const { item, language } = this.props
    const image = item.image || {}
    return [
      <View
        style={[
          item.mediaPosition === 'above' && {
            flexDirection: 'column-reverse',
          },
        ]}
      >
        <View>
          <Fields
            key={'Form fields' + Math.random()}
            fields={fields}
            values={item}
            onChange={this._change}
          />
        </View>
        <View style={styles.root}>
          <View
            style={[
              image.altPosition === 'below' && {
                flexDirection: 'column-reverse',
              },
            ]}
          >
            <View>
              <TextInput
                mode={'flat'}
                style={[styles.textInputNormal]}
                value={getTranslationValue(image, 'alt', language)}
                onChangeText={this._changeImage('alt')}
                label="Beschrijving afbeelding (alt)"
              />
              <View style={{ marginBottom: 24 }}>
                <Menu
                  visible={this.state.visibleAlt}
                  onDismiss={() =>
                    this.setState({
                      visibleAlt: false,
                    })
                  }
                  anchor={
                    <Button
                      uppercase={false}
                      onPress={() => {
                        this.setState({
                          visibleAlt: true,
                        })
                      }}
                    >
                      Wijzig positie beschrijving
                    </Button>
                  }
                >
                  <Menu.Item
                    onPress={() =>
                      this._changePositionAlt('altPosition')('above')
                    }
                    title="Boven"
                  />
                  <Menu.Item
                    onPress={() =>
                      this._changePositionAlt('altPosition')('below')
                    }
                    title="Onder"
                  />
                </Menu>
              </View>
            </View>
            <MediaDropzone
              media={getTranslationValue(image, 'media', language)}
              onChange={this._changeImage('media')}
            />
          </View>

          <View style={{ marginTop: 24 }}>
            {image ? (
              <Menu
                visible={this.state.visible}
                onDismiss={() =>
                  this.setState({
                    visible: false,
                  })
                }
                anchor={
                  <Button
                    uppercase={false}
                    onPress={() => {
                      this.setState({
                        visible: true,
                      })
                    }}
                  >
                    Wijzig positie afbeelding
                  </Button>
                }
              >
                <Menu.Item
                  onPress={() => this._changePosition('mediaPosition')('above')}
                  title="Boven"
                />
                <Menu.Item
                  onPress={() => this._changePosition('mediaPosition')('below')}
                  title="Onder"
                />
              </Menu>
            ) : null}
          </View>
        </View>
      </View>,
    ]
  }
}

export default ProductWidget
