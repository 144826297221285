import React, { useEffect, useRef, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { Appbar, FAB, Snackbar, Title } from 'react-native-paper'

// @ts-ignore
import Navigation from '../../Navigation'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser, selectOrganization, sendMail } from './actions'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import List from '../User/List'
import OrganizationList from '../User/Organization/List'
import Container from "../../components/Container";

const styles = StyleSheet.create({
  root: { flex: 1, backgroundColor: '#FCFCFC' },
  header: {
    backgroundColor: 'transparent',
    elevation: 0,
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  nextFab: {
    position: 'absolute',
  },
  loadingContainer: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  container: {
    maxWidth: 900,
    width: '100%',
    height: '50%',
    alignSelf: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  resize: {
    maxWidth: 1200,
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
})

type User = {
  id: number
}
type Organization = {
  id: number
}

export function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
type DirectSendPropsType = {
  safe: { bottom: number; right: number }
  componentId: number
  id: string
}

function DirectSend({ safe, componentId, id }: DirectSendPropsType) {
  const back = () => {
    Navigation.pop(componentId)
  }
  const [succesFullySent, setSuccesFullySent] = useState(false)
  const dispatch = useDispatch()
  const directSend: {
    users: number[]
    organizations: number[]
    loading: boolean
    loaded: boolean
  } = useSelector((state: any) => state.directSend)
  const previousLoaded = usePrevious(directSend.loaded)

  useEffect(() => {
    if (
      directSend.loaded &&
      directSend.loaded !== previousLoaded &&
      !succesFullySent
    ) {
      setSuccesFullySent(true)
    }
  }, [directSend, succesFullySent, previousLoaded])

  const send = () => {
    const { users, organizations } = directSend
    dispatch(
      sendMail({
        userIds: users,
        organizationIds: organizations,
        id: id,
      })
    )
  }

  const select = (user: User) => {
    const { users } = directSend
    if (users.some((us) => us === user.id)) {
      dispatch(selectUser(users.filter((us) => us !== user.id)))
    } else {
      dispatch(selectUser([...users, user.id]))
    }
  }

  const selectOrganizationFunc = (organization: Organization) => {
    const { organizations } = directSend
    if (organizations.some((org) => org === organization.id)) {
      dispatch(
        selectOrganization(
          organizations.filter((org) => org !== organization.id)
        )
      )
    } else {
      dispatch(selectOrganization([...organizations, organization.id]))
    }
  }

  const isUserSelected = (item: User) => {
    const { users } = directSend
    return users.find((us) => us === item.id)
  }

  const isOrgSelected = (item: Organization) => {
    const { organizations } = directSend
    return organizations.find((org) => org === item.id)
  }
  return [
    <View style={styles.root}>

      <Container>
      <Appbar key="header" style={styles.header}>
        <Appbar.BackAction onPress={back} />
        <Appbar.Content title={'Direct versturen'} />
      </Appbar>
      </Container>
      <View style={styles.container}>
        <Title style={{ paddingTop: 10, paddingBottom: 10 }}>
          Gebruikers selecteren
        </Title>
        <List onSelect={select} multiSelect isSelected={isUserSelected} />
      </View>
      <View style={styles.container}>
        <Title style={{ paddingTop: 10, paddingBottom: 10 }}>
          Organisaties selecteren
        </Title>
        <OrganizationList
          onSelect={selectOrganizationFunc}
          multiSelect
          isSelected={isOrgSelected}
        />
      </View>
    </View>,
    <FAB
      key="send"
      icon={'send'}
      label={'Versturen'}
      onPress={send}
      loading={directSend.loading}
      style={[
        styles.nextFab,
        { bottom: 16 + safe.bottom, right: 16 + safe.right },
      ]}
    />,
    <View style={styles.resize}>
      <Snackbar
        key="snackbar"
        visible={!!succesFullySent}
        onDismiss={() => setSuccesFullySent(false)}
        style={[
          // styles.message,
          {
            marginBottom: 16,
          },
        ]}
        theme={{ colors: { accent: 'white' } }}
        action={{
          label: 'OK',
          onPress: () => setSuccesFullySent(false),
        }}
      >
        {'De mail is succesvol verstuurd'}
      </Snackbar>
    </View>,
  ]
}

export default safeAreaHOC(DirectSend)
