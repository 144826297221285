import React, { Component } from 'react'
import { ScrollView } from 'react-native'
import { withRouter } from 'react-router-dom'

class ScrollViewWithSaving extends Component {
  _scrollView = null
  componentDidMount() {
    const { key } = this.props.location
    // console.log(key)
    // console.log(window.savedY)

    if (window.savedY && window.savedY[key] && this._scrollView) {
      this._scrollView.scrollTo({
        x: 0,
        y: window.savedY[key],
        animated: false,
      })
    }
    if (!window.savedY) {
      window.savedY = {
        [key]: 0,
      }
    }
  }
  _handleScroll = e => {
    const { key } = this.props.location
    // console.log({ ...e.nativeEvent.contentOffset })

    if (
      e &&
      e.nativeEvent &&
      e.nativeEvent.contentOffset &&
      e.nativeEvent.contentOffset.y
    ) {
      window.savedY[key] = e.nativeEvent.contentOffset.y
    }

    if (this.props.onScroll) {
      this.props.onScroll(e)
    }
  }
  _ref = c => {
    this._scrollView = c
  }
  render() {
    return (
      <ScrollView
        ref={this._ref}
        {...this.props}
        onScroll={this._handleScroll}
      />
    )
  }
}

export default withRouter(ScrollViewWithSaving)
