import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { withTheme, Appbar, FAB } from 'react-native-paper'

import Modal from './Modal'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'

class ClosableModal extends Component {
  render() {
    const { hideHeader, showClose, title, safePadding } = this.props

    return (
      <Modal
        animationInTiming={150}
        backdropTransitionInTiming={150}
        animationOutTiming={150}
        backdropTransitionOutTiming={150}
        useNativeDriver
        {...this.props}
        style={[
          styles.modal,
          !hideHeader && { paddingTop: safePadding.paddingTop },
        ]}
      >
        <View style={styles.closeContainer}>
          {showClose ? (
            <FAB
              icon={'close'}
              testID={'closeModal'}
              onPress={this.props.onRequestClose}
              style={styles.close}
            />
          ) : null}
        </View>
        {!hideHeader ? (
          <View style={styles.headerContainer} pointerEvents="none">
            <Appbar style={styles.header} dark={true}>
              <Appbar.Content title={title} />
              <Appbar.Action
                pointerEvents="all"
                icon={'close'}
                testID={'closeModal'}
                onPress={this.props.onRequestClose}
              />
            </Appbar>
          </View>
        ) : null}
        <View
          style={[
            styles.modalContent,
            safePadding,
            !hideHeader && { paddingTop: 0 },
          ]}
        >
          {this.props.children}
        </View>
      </Modal>
    )
  }
}

ClosableModal.propTypes = {}
ClosableModal.defaultProps = {}

const styles = StyleSheet.create({
  header: {
    backgroundColor: 'transparent',
    elevation: 0,
  },
  closeContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
  },
  close: {
    marginBottom: 10,
    marginRight: 16,
  },
  modal: {
    margin: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContainer: {
    width: '100%',
    maxWidth: 600,
  },
  modalContent: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    backgroundColor: '#fff',
    borderRadius: 0,
    margin: 0,
    position: 'relative',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    // overflow: 'hidden',
  },
})

export default safeAreaHOC(withTheme(ClosableModal))
