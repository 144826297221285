import { call, put, take, fork, select, takeEvery } from 'redux-saga/effects'

import log from './log'
import { getErrorMessage } from '../api'
import { fetchListener } from 'react-auth/lib/sagas/authentication'

function* load(url, a, stateName, loadMore, action) {
  try {
    const state = yield select(state => state)
    const data = stateName(state)[action.meta.key]

    const extraParams = data.params
    const payloadParams =
      (action && action.payload && action.payload.params) || {}

    const params = {
      limit: 50,
      ...payloadParams,
      ...extraParams,
    }

    if (loadMore) {
      params.offset = data.pagination.limit + data.pagination.offset
    }
    let response = yield call(fetchListener, {
      path: url(state),
      params,
    })

    yield put(
      loadMore
        ? a.loadedMoreList(response, action.meta)
        : a.loadedList(response, action.meta)
    )
  } catch (errorResponse) {
    log(errorResponse)
    const e = getErrorMessage(errorResponse)
    yield put(
      loadMore
        ? a.loadedMoreList(e, action.meta, true)
        : a.loadedList(e, action.meta, true)
    )
  }
  if (a.LOAD_MORE_LIST) {
    yield take(a.LOAD_MORE_LIST)
    yield fork(load, url, a, stateName, true, action)
  } else {
    console.log('load more not defined')
  }
}

export default function* pagedList(url, actions, stateName) {
  yield takeEvery(actions.LOAD_LIST, load, url, actions, stateName, false)
}
