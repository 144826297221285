import React, { Component } from 'react'
import { StyleSheet } from 'react-native'
import { List, withTheme } from 'react-native-paper'
import { connect } from 'react-redux'
import actions from './actions'
import { push } from '../Navigation'
import * as routes from '../Routes.config'
import GeneralList from '../GeneralListFunctions/GeneralList'
import ItemShimmer from '../components/Item.shimmer'
import Container from '../components/Container'
import { Hoverable } from 'react-native-web-hover'

// eslint-disable-next-line
const styles = StyleSheet.create({})

class OrganizationScreen extends Component {
  state = {
    title: '',
    rerenderKey: 0,
  }
  _pressAdd = () => {
    push(this.props.componentId, {
      component: {
        name: routes.ORGANIZATION_NEW_SCREEN_ROUTE,
      },
    })
  }
  _pressItem = (item) => () => {
    push(this.props.componentId, {
      component: {
        name: routes.ORGANIZATION_DETAILS_SCREEN_ROUTE,
        passProps: {
          id: item.id,
        },
      },
    })
  }

  _getParams = (ignore, inputSearch) => {
    const { list } = this.props
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }
    return {
      ...list.params,
      showAllUsers: true,
      preloadAll: true,
      embed: ['organization'],
      'search[name]': search,
      sort: 'name',
    }
  }
  _renderShimmer = (key) => {
    return (
      <Container key={key}>
        <ItemShimmer key={key} />
      </Container>
    )
  }
  _renderItem = ({ item }) => {
    let description = ''
    if (item.email && item.email !== '') {
      description += item.email
    } else {
      description += 'Geen email'
    }
    // if (item.organizationGroups && item.organizationGroups.length > 0) {
    //   description += ' - '
    //   description += item.organizationGroups.map((it) => it.name).join(', ')
    // }
    return (
      <Container key={item.id}>
        <Hoverable key={`list_item_organization_${Math.random()}`}>
          {({ hovered }) => (
            <List.Item
              style={
                hovered
                  ? { cursor: 'pointer', backgroundColor: '#DFDFDF' }
                  : { backgroundColor: '#FFF' }
              }
              onPress={this._pressItem(item)}
              title={
                [item.name].filter((n) => n).join(' ') || 'Geen naam opgegeven'
              }
              description={description}
            />
          )}
        </Hoverable>
      </Container>
    )
  }
  render() {
    const { loadList, reloadList, loadMoreList, componentId, list } = this.props
    return (
      <GeneralList
        reloadList={reloadList}
        loadList={loadList}
        loadMoreList={loadMoreList}
        list={list}
        componentId={componentId}
        renderItem={this._renderItem}
        renderShimmer={this._renderShimmer}
        getParams={this._getParams}
        onPressAdd={this._pressAdd}
        title={'Organisaties'}
      />
    )
  }
}

OrganizationScreen.propTypes = {}
OrganizationScreen.defaultProps = {}

const mapStateToProps = (state) => ({
  list: state.organization.list,
})

export default connect(mapStateToProps, actions)(withTheme(OrganizationScreen))
