import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet, Image, Platform } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'

class BigButton extends Component {
  render() {
    const {
      backgroundColor,
      backgroundImage,
      labelColor,
      label,
      onPress,
      margin,
      height,
      style,
    } = this.props
    console.log(label)
    const inner = (
      <View style={styles.innerButton}>
        <View style={styles.centeredRow}>
          <Text style={[styles.title, { color: labelColor }]}>{label}</Text>
        </View>
      </View>
    )

    let content = inner
    if (backgroundImage) {
      content = (
        <View style={[styles.fill, styles.imageBackgroundContainer]}>
          <View
            style={[styles.fill, styles.overlay]}
            // style={styles.overlay}
          >
            {inner}
          </View>
          <Image
            resizeMode={'cover'}
            // style={[styles.fill, styles.imageBackground]}
            style={styles.imageBackground}
            source={{ uri: backgroundImage }}
          />
        </View>
      )
    }
    return (
      <View
        style={[
          styles.button,
          // Platform.OS !== 'android' && styles.buttonShadow,
          {
            height,
            maxHeight: height,
            margin,
            backgroundColor: backgroundColor,
          },
          style && style,
          Platform.OS === 'web' && {cursor:'pointer'}
        ]}
      >
        <TouchableRipple
          onPress={onPress}
          rippleColor="rgba(0, 0, 0, .32)"
          underlayColor="#FFF" // rgba(0,0,0,0.9)
          borderless={true}
          useForeground={true}
          testID={'categoryOfProducts'}
          style={styles.ripple}
        >
          {content}
        </TouchableRipple>
      </View>
    )
  }
}

BigButton.propTypes = {
  backgroundColor: PropTypes.string,
  labelColor: PropTypes.string,
  label: PropTypes.string,
  onPress: PropTypes.func,
  margin: PropTypes.number,
}
BigButton.defaultProps = {
  backgroundColor: '#f8f8f8',
  labelColor: '#000',
  label: 'Cooperatie (test)',
  onPress: () => {},
  margin: 16,
  height: 50,
}

export const styles = StyleSheet.create({
  fill: {
    flex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,

    position: 'absolute',
  },
  centeredRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    backgroundColor: '#f8f8f8',

    // marginBottom: 30,
    borderRadius: 20,
    // shadowRadius: 10,
    // elevation: 10,
    // width: '100%',
    overflow: 'hidden',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ripple: {
    flex: 1,
    width: '100%',
  },
  // buttonShadow: {
  //   shadowColor: 'grey',
  //   shadowOffset: { width: 0, height: 5 },
  //   height: 200,
  //   shadowOpacity: 0.3,
  //   shadowRadius: 12,
  // },

  innerButton: {
    flex: 1,
    width: '100%',
    alignItems: 'flex-start',
    paddingLeft: 16,
    position: 'relative',
    justifyContent: 'center',
  },
  imageBackgroundContainer: {
    flex: 1,

    flexDirection: 'row',
  },
  imageBackground: {
    flex: 1,
    width: '50%',
    maxWidth: 200,
    zIndex: 1,
  },
  overlay: {
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    // backgroundColor: 'rgba(0,0,0,0.6)',
    position: 'relative',
  },
  title: {
    fontSize: 23,
    // textAlign: 'center',
    // textTransform: 'capitalize',
    fontWeight: '600',
  },

  container: {
    padding: 10,
  },
})

export default BigButton
