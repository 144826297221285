import React, { Component } from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import PropTypes from 'prop-types'

class ErrorMessages extends Component {
  render() {
    const { errorMessages } = this.props

    if (!errorMessages || errorMessages.length <= 0) {
      return null
    }

    return (
      <View
        style={{
          padding: 15,
          margin: 0,
          // borderRadius: 25,
          paddingLeft: 25,
          paddingRight: 25,
          backgroundColor: 'red',
        }}
      >
        {errorMessages
          ? errorMessages.map((message, i) => (
              <Text style={{ color: 'white' }} key={i}>
                {message}
              </Text>
            ))
          : null}
      </View>
    )
  }
}
ErrorMessages.propTypes = {
  errorMessages: PropTypes.array,
}
ErrorMessages.defaultProps = {
  errorMessages: [],
}

export default ErrorMessages
