import { Platform } from 'react-native'
import redirectWhenAuthenticatedHOC from 'react-auth/lib/hocs/redirectWhenAuthenticatedHOC'
import requireLoginHOC from 'react-auth/lib/hocs/requireLoginHOC'
import paramsAsProp from './GeneralListFunctions/paramsAsPropsHOC'

// import HomeScreen from './Home/Home'
import LoginScreen from './Auth/LoginScreen'
import ForgotPasswordScreen from './Auth/ForgotPassword'
import ChangePasswordScreen from './Auth/ChangePasswordScreen'
import AccountScreen from './Auth/AccountScreen'
import UserScreen from './User/ListScreen'
import UserDetailScreen from './User/UserDetail'
import MailScreen from './Mail/ListScreen'
import MailDetailScreen from './Mail/StepMailing'
import AccountDetailScreen from './Auth/AccountDetails'
import OrganizationScreen from './Organization'
import OrganizationDetailsScreen from './Organization/OrganizationDetailsScreen'
import OrganizationGroupScreen from './OrganizationGroup'
import OrganizationGroupDetailsScreen from './OrganizationGroup/OrganizationGroupDetailsScreen'
import OrganizationDetailScreen from './Auth/OrganizationDetails'
import ManageRegistrationsScreen from './Mail/ManageRegistrationsScreen'
// import DashboardScreen from './Dashboard/Dashboard'
import { pushAuth, pushHome } from './Navigation'
import Logout from 'react-auth/lib/components/Logout'
import ContentStep from './Mail/StepContent'
import FirstStep from './Mail/StepMailing'
import Preview from './Mail/Preview/Preview'
import DirectSend from './Mail/DirectSend/DirectSend'
import RegistrationScreen from "./User/Registration/RegistrationScreen";
import EventScreen from './Event/ListScreen'
import EventDetailScreen from './Event/EventDetail'

const redirectWhenAuthenticated = (component) =>
  redirectWhenAuthenticatedHOC(component, pushHome)
const requireLogin = (component) => {
  if (process.env.REACT_APP_DISABLE_AUTHENTICATION === 'true') {
    return component
  }
  return requireLoginHOC(component, pushAuth, null, true)
}
export const HOME_SCREEN_ROUTE = '/'
export const AUTH_SCREEN_ROUTE = '/auth'
export const ACCOUNT_SCREEN_ROUTE = '/account'
// export const DASHBOARD_SCREEN_ROUTE = '/home'

export const USER_SCREEN_ROUTE = '/user'
export const USER_DETAIL_SCREEN_ROUTE = '/user/'
export const USER_REGISTRATION_SCREEN_ROUTE = '/user/registration'
export const USER_NEW_ROUTE = '/user/new'

export const MAILING_SCREEN_ROUTE = '/mailing'
export const MANAGE_REGISTRATIONS_SCREEN_ROUTE = '/mailing/registrations'
export const MAILING_DETAIL_ONE_ROUTE = '/mailing/mailing/'
export const MAILING_DETAIL_TWO_ROUTE = '/mailing/content/'

export const MAILING_NEW_ROUTE = '/mailing/new'
export const MAILING_PREVIEW_ROUTE = '/mailing/preview'
export const DIRECT_SEND_ROUTE = '/mailing/direct-send/'

export const ORGANIZATION_SCREEN_ROUTE = '/organization'
export const ORGANIZATION_DETAILS_SCREEN_ROUTE = '/organization/'
export const ORGANIZATION_NEW_SCREEN_ROUTE = '/organization/new'

export const ORGANIZATION_GROUP_SCREEN_ROUTE = '/organization-group'
export const ORGANIZATION_GROUP_DETAILS_SCREEN_ROUTE = '/organization-group/'
export const ORGANIZATION_GROUP_NEW_SCREEN_ROUTE = '/organization-group/new'

export const CHANGE_PASSWORD_SCREEN_ROUTE = '/change-password'
export const FORGOT_PASSWORD_SCREEN_ROUTE = '/forgot-password'
export const ACCOUNT_DETAIL_SCREEN_ROUTE = '/account/user'
export const LOGOUT_SCREEN = '/logout'
export const ORGANIZATION_DETAIL_SCREEN_ROUTE = '/account/organization'

export const EVENT_SCREEN_ROUTE = '/event'
export const EVENT_DETAIL_SCREEN_ROUTE = '/event/'

const wrapDetailRoute = (component) => {
  if (Platform.OS === 'web') {
    // same behaviour as native params as props instead of url
    return paramsAsProp(component)
  }
  return component
}

export const listRoutes = [
  {
    path: AUTH_SCREEN_ROUTE,
    exact: true,
    component: redirectWhenAuthenticated(LoginScreen),
  },
  {
    path: LOGOUT_SCREEN,
    exact: true,
    component: requireLogin(Logout),
  },
  {
    path: CHANGE_PASSWORD_SCREEN_ROUTE,
    exact: true,
    pathOnWeb: `${CHANGE_PASSWORD_SCREEN_ROUTE}/:secret?`,
    component: redirectWhenAuthenticated(ChangePasswordScreen),
  },
  {
    path: FORGOT_PASSWORD_SCREEN_ROUTE,
    exact: true,
    exactOnSmallScreen: true,
    component: redirectWhenAuthenticated(ForgotPasswordScreen),
  },
  {
    path: '/',
    exact: true,
    component: `${process.env.REACT_APP_CURRENT_NAMESPACE}`
      ? requireLogin(redirectWhenAuthenticated(UserScreen))
      : requireLogin(redirectWhenAuthenticated(MailScreen)),
  },
  {
    path: ACCOUNT_SCREEN_ROUTE,
    exact: true,
    component: requireLogin(AccountScreen),
  },
  {
    path: MAILING_PREVIEW_ROUTE,
    exact: true,
    component: requireLogin(Preview),
  },
  {
    path: DIRECT_SEND_ROUTE,
    pathOnWeb: `${DIRECT_SEND_ROUTE}:id`,
    exact: true,
    component: wrapDetailRoute(requireLogin(DirectSend)),
  },
  // {
  //   path: DASHBOARD_SCREEN_ROUTE,
  //   exact: true,
  //   component: requireLogin(DashboardScreen),
  // },
  {
    path: HOME_SCREEN_ROUTE,
    exact: true,
    component:
      `${process.env.REACT_APP_CURRENT_NAMESPACE}` === 'agro4all'
        ? requireLogin(UserScreen)
        : requireLogin(MailScreen),
  },
  {
    path: USER_SCREEN_ROUTE,
    exact: true,
    component: requireLogin(UserScreen),
  },
  {
    path: USER_NEW_ROUTE,
    pathOnWeb: USER_NEW_ROUTE,
    exact: true,
    component: requireLogin(UserDetailScreen),
  },
  {
    path: USER_REGISTRATION_SCREEN_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(RegistrationScreen)),
  },
  {
    path: USER_DETAIL_SCREEN_ROUTE,
    pathOnWeb: `${USER_DETAIL_SCREEN_ROUTE}:id`,
    exact: true,
    component: wrapDetailRoute(requireLogin(UserDetailScreen)),
  },
  {
    path: ORGANIZATION_SCREEN_ROUTE,
    exact: true,
    component: requireLogin(OrganizationScreen),
  },
  {
    path: ORGANIZATION_NEW_SCREEN_ROUTE,
    pathOnWeb: ORGANIZATION_NEW_SCREEN_ROUTE,
    exact: true,
    component: requireLogin(OrganizationDetailsScreen),
  },
  {
    path: ORGANIZATION_DETAILS_SCREEN_ROUTE,
    pathOnWeb: `${ORGANIZATION_DETAILS_SCREEN_ROUTE}:id`,
    exact: true,
    component: wrapDetailRoute(requireLogin(OrganizationDetailsScreen)),
  },
  {
    path: ORGANIZATION_GROUP_SCREEN_ROUTE,
    exact: true,
    component: requireLogin(OrganizationGroupScreen),
  },
  {
    path: ORGANIZATION_GROUP_NEW_SCREEN_ROUTE,
    pathOnWeb: ORGANIZATION_GROUP_NEW_SCREEN_ROUTE,
    exact: true,
    component: requireLogin(OrganizationGroupDetailsScreen),
  },
  {
    path: ORGANIZATION_GROUP_DETAILS_SCREEN_ROUTE,
    pathOnWeb: `${ORGANIZATION_GROUP_DETAILS_SCREEN_ROUTE}:id`,
    exact: true,
    component: wrapDetailRoute(requireLogin(OrganizationGroupDetailsScreen)),
  },
  {
    path: MAILING_NEW_ROUTE,
    pathOnWeb: MAILING_NEW_ROUTE,
    exact: true,
    component: requireLogin(MailDetailScreen),
  },
  {
    path: MAILING_DETAIL_ONE_ROUTE,
    pathOnWeb: `${MAILING_DETAIL_ONE_ROUTE}:id?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(FirstStep)),
  },
  {
    path: MAILING_DETAIL_TWO_ROUTE,
    pathOnWeb: `${MAILING_DETAIL_TWO_ROUTE}:id?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(ContentStep)),
  },
  {
    path: ORGANIZATION_DETAIL_SCREEN_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(OrganizationDetailScreen)),
  },
  {
    path: ACCOUNT_DETAIL_SCREEN_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(AccountDetailScreen)),
  },
  {
    path: MANAGE_REGISTRATIONS_SCREEN_ROUTE,
    exact: true,
    component: requireLogin(ManageRegistrationsScreen),
  },
  {
    path: MAILING_SCREEN_ROUTE,
    pathOnWeb: `${MAILING_SCREEN_ROUTE}/:activeTab?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(MailScreen)),
  },
  {
    path: EVENT_SCREEN_ROUTE,
    exact: true,
    component: requireLogin(EventScreen),
  },
  {
    path: EVENT_DETAIL_SCREEN_ROUTE,
    pathOnWeb: `${EVENT_DETAIL_SCREEN_ROUTE}:id`,
    exact: true,
    component: wrapDetailRoute(requireLogin(EventDetailScreen)),
  },
]

export const detailRoutes = []

export const allRoutes = listRoutes.concat(detailRoutes)
