import React, { Component } from 'react'
import { Platform, Image, Text, View, StyleSheet } from 'react-native'
import { Snackbar, withTheme, Button } from 'react-native-paper'
import PT from 'prop-types'

import Logo from '../../img/logo.png'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import KeyboardAvoidingScrollView from '../components/KeyboardAvoidingScrollView'
import keyboardHOC from '../components/keyboardHOC'
import changePasswordHOC from 'react-auth/lib/hocs/changePasswordHOC'
import PinkView from './PinkView'
import base64 from 'base-64'
import idx from 'idx'

const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    name: 'email',
    label: 'E-mailadres',
    type: 'email',

    fieldProps: { ...fieldProps, disabled: true, autoFocus: true },
  },
  {
    name: 'password',
    label: 'Wachtwoord',
    type: 'password',
    fieldProps,
  },
]

class LoginScreen extends Component {
  state = {
    password: '',
  }

  componentDidMount() {
    const encodedSecret = idx(this.props, _ => _.match.params.secret)

    if (!!encodedSecret) {
      const decodedSecret = base64.decode(encodedSecret)

      const re = new RegExp('(.*)-(.[0-9]*)-(.*)')
      const matches = re.exec(decodedSecret)

      const email = idx(matches, _ => _[1])
      const secret = idx(matches, _ => _[3])

      this.props.onChangeField('email', email)

      this.props.onChangeField('secret', secret)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.completed && !this.props.completed) {
      this.props.onLogin({
        login: this.props.values.email,
        password: this.props.values.password,
      })
    }
  }

  _submit = e => {
    if (e) {
      e.preventDefault()
    }

    this.props.onSubmit()
  }

  _change = (name, value) => {
    this.props.onChangeField(name, value)
  }

  render() {
    const { sending, error, keyboardHeight } = this.props
    return [
      <KeyboardAvoidingScrollView key="">
        <FormContainer key="form-container" onSubmit={this.props.onSubmit}>
          <View style={styles.loginContainer}>
            <Image
              style={styles.logo}
              source={process.env.REACT_APP_STATIC_ASSETS_ENDPOINT ? `${process.env.REACT_APP_STATIC_ASSETS_ENDPOINT}/media/template/logo.png` : Logo}
            />

            <View style={styles.resize}>
              <View style={styles.loginView}>
                <Text
                  style={{
                    padding: 10,
                    paddingBottom: 50,
                    fontSize: 24,
                    alignSelf: 'center',
                  }}
                >
                  Kies een nieuw wachtwoord
                </Text>
                <Fields
                  fields={fields}
                  values={this.props.values || {}}
                  onChange={this._change}
                  onSubmit={this.props.onSubmit}
                />

                <Button
                  mode={'contained'}
                  // icon="send"
                  onPress={this.props.onSubmit}
                  type="submit"
                  loading={sending}
                  // color={theme.colors.primary}
                  uppercase={false}
                  contentStyle={styles.buttonContentStyle}
                >
                  Opslaan
                </Button>
              </View>
            </View>
          </View>
          <PinkView/>
        </FormContainer>
      </KeyboardAvoidingScrollView>,

      <Snackbar
        key="snackbar"
        visible={!!error}
        onDismiss={this.props.dismiss}
        style={[
          styles.message,
          {
            marginBottom: keyboardHeight + 16,
          },
        ]}
      >
        {'Fout bij het inloggen'}
      </Snackbar>,
    ]
  }
}

LoginScreen.propTypes = {}
LoginScreen.defaultProps = {
  onSubmit: PT.func.isRequired,
  onChangeField: PT.func.isRequired,
  sending: PT.bool.isRequired,
  errors: PT.arrayOf(
    PT.shape({
      message: PT.string.isRequired,
    }),
  ),
}

const styles = StyleSheet.create({
  buttonContentStyle: {
    height: 56,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  logoContainer: {
    padding: 20,
    paddingTop: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    marginTop: 50,
    marginBottom: 50,
    height: 70,
    width: 200,
    resizeMode: 'contain',
  },
  loginContainer: {
    flex: Platform.OS === 'web' ? '1 1 auto' : 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#fff',
  },

  resize: {
    maxWidth: 500,
    paddingLeft: 6,
    paddingRight: 6,
    width: '100%',
    flex: 1,
  },
  errorMessage: {
    backgroundColor: 'red',
  },
})

export default keyboardHOC(withTheme(changePasswordHOC(LoginScreen)))
